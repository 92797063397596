import {calculateAccountUSDValue, getTokens, prettyPrint} from "../../common/utils";
import {Column} from "primereact/column";
import {RenderCurvePoolCell, RenderTokenCell} from "../common";
import {DataTable} from "primereact/datatable";
import * as React from "react";
import {useState} from "react";
import {createTheme} from "@mui/material/styles";
import _ from "lodash";
import {Button} from "primereact/button";

const theme = createTheme({
    typography: {
        subtitle1: {
            fontSize: 12,
        },
        body1: {
            fontWeight: "bold",
            fontSize: 14,
            color: "black"
        },

    },
});

export function AccountPortfolio({accountTokens, params, onClickToken, onClickChart}) {
    const [expandedRows, setExpandedRows] = useState(null)
    let headerStyle = {background: "none", fontFamily: "Inter, sans-serif", "color": "#0e0e52"}
    let bodyStyle = {fontFamily: "Roboto", fontWeight: "600", color: "black"}
    const iconStyle = {background: "white", color: "rgb(0, 114, 245)", border: "none"}
    return (
        <div>
            <DataTable
                size={"small"}
                sortField="totalValueInUSD"
                sortOrder={-1}
                editMode="row"

                // expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                paginator
                rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                value={getTokens(accountTokens)}
                dataKey="hash" tableStyle={{minWidth: '70rem'}} className={"allTransactionsTable"}>

                <Column field={"token_name"}
                        header={"Asset"}
                        headerStyle={headerStyle}
                        bodyStyle={bodyStyle}
                        body={(params) => {
                            if (params.protocol == undefined) {
                                return RenderTokenCell(params, onClickToken, theme)

                            } else if (params.protocol == "Curve") {
                                return RenderCurvePoolCell(params, onClickToken, theme)
                            }
                        }}
                        filter
                        filterPlaceholder="Search by name"
                        filterMenuStyle={{width: '14rem', fontSize: "10px"}}


                ></Column>
                <Column field={"totalValueInUSD"} header={"USD value"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {
                    return prettyPrint(Number(params.totalValueInUSD)) + "$"
                }}></Column>
                <Column field={"amount_of_tokens"} header={"Amount"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {
                    return prettyPrint(Number(params.amount_of_tokens)) + " " + params.token_symbol
                }}></Column>
                <Column field={"currentPrice"} header={"Price"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true}
                        body={(params) => {
                            return prettyPrint(Number(params.currentPrice)) + "$"
                        }}></Column>
                <Column field={"totalValueInUSD"} header={"Portfolio %"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {
                    return prettyPrint(Number(params.totalValueInUSD / calculateAccountUSDValue(accountTokens)) * 100) + "%"
                }}></Column>

                <Column field={"totalValueInUSD"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        body={(params) => {
                    return (<Button icon="pi pi-chart-pie" style={iconStyle} className="p-mr-2" onClick={() => {

                            onClickChart(params.token_address)
                    }}/>)
                }}></Column>

                {/*<Column key={"timeStamp"}  field={"timeStamp"} header={"Date"} headerStyle={headerStyle} body={(params)=>{*/}
                {/*    let d = new Date(params.timeStamp * 1000).getTime()*/}
                {/*    return timestampToHumanReadable(d)}*/}
                {/*}*/}
                {/*        bodyStyle={bodyStyle}*/}
                {/*        sortable={true}*/}
                {/*        sortOrder={-1}*/}
                {/*></Column>*/}
                {/*<Column key={"operation"} field={"operation"} header={"Operation"} body={RenderCellOperation} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>*/}
                {/*<Column key={"tokensOut"} field={"tokensOut"} header={"Tokens Out"} body={(params)=>{*/}
                {/*    return RenderTokensInCell(params, onClickToken, "tokensOut")*/}

                {/*}} headerStyle={headerStyle}></Column>*/}
                {/*<Column key={"tokensIn"} field={"tokensIn"} header={"Tokens In"} body={(params)=>{*/}
                {/*    return RenderTokensInCell(params, onClickToken, "tokensIn")*/}
                {/*}} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>*/}
                {/*<Column key={"pnl"} field={"pnl"} bodyStyle={bodyStyle} header={"PNL"} body={(params)=>{*/}
                {/*    return RenderProfitLossCell(params, onPNLExplainedClick)}} headerStyle={headerStyle}></Column>*/}
                {/*<Column key={"pnl"} field={"pnl"} header={"PNL"} body={RenderProfitLossCell} headerStyle={headerStyle}></Column>*/}


            </DataTable>
        </div>


    )
}