import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SoftInput from "../SoftInput";
import {FormControl, FormHelperText} from "@mui/material";

export function SoftInputSupportUnderlinedText({upperLabel, errorText, informationalText, inputType, placeHolder, ...rest}){
    return (
        <FormControl style={{display:"block"}} >
        <SoftBox>
            <SoftBox ml={0.5}>
                {upperLabel && <SoftTypography component="label" variant="caption" fontWeight="bold" >
                    {upperLabel}
                </SoftTypography>}
            </SoftBox>
            <SoftInput type={inputType} placeholder={placeHolder} error={errorText != " "} {...rest}/>
            <FormHelperText style={{color:"red"}}>{errorText}</FormHelperText>


        </SoftBox>
        </FormControl>
    )
}