import {getChainLogo, getTransactionLink, prettyPrint, prettyPrintAddress} from "../../common/utils";
import Link from "@mui/material/Link";
import * as React from "react";
import List from "@mui/material/List";
import {AvatarGroup, Chip, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {HelpOutline} from "@mui/icons-material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Badge} from "@nextui-org/react";
// import {Chip} from "@nextui-org/chip";

import Avatar from "@mui/material/Avatar";

export const RenderTxHahCell = (params)=>{
    let link = getTransactionLink(params.chain, params.hash)

    return (

        <Link href={link} isExternal={true} target="_blank" >
            {/*<LaunchOutlined sx={{ verticalAlign: 'middle', marginRight: '4px' }} />*/}
            <img alt="" src={getChainLogo(params.chain)} style={{ verticalAlign: 'middle', marginRight: '4px', maxWidth:"20px", maxHeight:"20px" }} />
            {prettyPrintAddress(params.hash)}
        </Link>)
}

export const RenderProfitLossCell = (value, customStyle = {}) => {
    let textColor = value > 0 ? { color:"green"}: {color:"red"}
    let styleText = Object.assign({}, {fontSize:"14px", fontFamily: "Roboto", fontWeight:"700", lineHeight:"1"}, customStyle, textColor)
    return (<>
            {value && <List>
                {/*<ListItem>*/}
                {/*    /!*<ListItemText primary={"Cost Basis: " + prettyPrint(params.pnl.costBasis) + "$"} primaryTypographyProps={{style:styleText}}>*!/*/}

                {/*    </ListItemText>*/}
                {/*    <ListItemIcon onClick={()=>{*/}
                {/*        // onPNLExplainedClick(params)*/}
                {/*    }}>*/}
                {/*        <HelpOutline/>*/}
                {/*    </ListItemIcon>*/}
                {/*</ListItem>*/}

                <ListItem>
                    <ListItemText primary={<span>{prettyPrint(value) + "$"}</span>} primaryTypographyProps={{style:styleText}} >
                    </ListItemText>
                </ListItem>


            </List>}
        </>
    )
}

export const RenderTokenCell = (params ,  onClickToken, theme) => {

    const defaultTheme = createTheme({
        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {
                fontWeight: "bold",
                fontSize:14,
                color:"black"
            },

        },
    });

    theme = theme || defaultTheme

    // let name = "tokensIn"
    // sort by the once with the most financial value
    let token_name = params.token_symbol || params.token_name || params.token_address
    let identifier = token_name ? token_name.slice(0,3) : "undefined"


    return (<>
            <ThemeProvider theme={theme}>
                <List>
                    <ListItem key={1} style={{maxHeight:"30px"}}>
                        <ListItemAvatar style={{cursor:"pointer", maxWidth:"30px", marginRight:"20px", minWidth:"0px"}} onClick={()=>{

                        }}>
                            {params.logoCMC && <Badge
                                disableOutline
                                style={{background:"none"}}
                                content={<Avatar
                                    // bordered
                                    // squared/
                                    color="primary"
                                    size="sm"
                                    style={{width:15, height:15, background:"none"}}
                                    src={getChainLogo(params.chain)}
                                />}

                            >
                                <Avatar
                                    // bordered
                                    // squared
                                    color="primary"
                                    size="lg"
                                    src={params.logoCMC}
                                    onClick={()=>{
                                        onClickToken({"symbol": params.token_symbol, "token_address": params.token_address})
                                    }}
                                />
                            </Badge>

                            }
                            {params.logoCMC == undefined &&
                                <Badge
                                    disableOutline
                                    style={{background:"none"}}
                                    content={<Avatar
                                        // bordered
                                        // squared/
                                        color="primary"
                                        size="sm"
                                        style={{width:15, height:15, background:"none"}}
                                        src={getChainLogo(params.chain)}
                                    />}

                                >
                                    <Avatar size={"lg"} style={{fontSize:"10px"}} onClick={()=>{
                                        onClickToken({"symbol": params.token_symbol, "token_address": params.token_address})
                                    }}>
                                        {
                                            identifier
                                        }
                                    </Avatar>
                                </Badge>

                            }


                        </ListItemAvatar>
                        <ListItemText key={1}
                                      primaryTypographyProps={{ style: { fontSize:"14px"} }}
                                      secondaryTypographyProps={{style:{fontSize: "10px"}}}
                                      primary={<div>{params.token_symbol} {params.protocol ? <Chip label={params.protocol} size={"small"} style={{fontSize:"10px"}}></Chip>:<></>}</div>}
                                      secondary={params.chain}
                            // secondary=
                            //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                            //     <ListItem>Per Basis: 100$</ListItem>
                            //   </List>}

                        >
                            {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                        </ListItemText>
                    </ListItem>

                </List>
            </ThemeProvider>

        </>
    )
};


export const RenderCurvePoolCell = (params ,  onClickToken, theme) => {

    const defaultTheme = createTheme({
        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {
                fontWeight: "bold",
                fontSize:14,
                color:"black"
            },

        },
    });

    theme = theme || defaultTheme

    // let name = "tokensIn"
    // sort by the once with the most financial value

    return (<>
            <ThemeProvider theme={theme}>
                <List>
                    <ListItem key={1} style={{maxHeight:"30px"}}>
                        <ListItemAvatar style={{cursor:"pointer", marginRight:"20px", minWidth:"0px"}} onClick={()=>{

                        }}>
                            {params.logoCMC && <Badge
                                disableOutline
                                style={{background:"none"}}
                                content={<Avatar
                                    // bordered
                                    // squared/
                                    color="primary"
                                    size="sm"
                                    style={{width:15, height:15, background:"none"}}
                                    src={getChainLogo(params.chain)}
                                />}

                            >
                                <Avatar
                                    color="primary"
                                    size="lg"
                                    src={params.logoCMC}
                                    onClick={()=>{
                                        onClickToken({"symbol": params.token_symbol, "token_address": params.token_address})
                                    }}
                                />

                            </Badge>

                            }
                            {params.logoCMC == undefined &&
                                <Badge
                                    disableOutline
                                    style={{background:"none"}}
                                    content={<Avatar
                                        // bordered
                                        // squared/
                                        color="primary"
                                        size="sm"
                                        style={{width:15, height:15, background:"none"}}
                                        src={getChainLogo(params.chain)}
                                    />}

                                >
                                    <AvatarGroup total={params.currenciesImages.length}>
                                        {params.currenciesImages.map((item, index) => (
                                            <Avatar size={"sm"} style={{fontSize:"10px"}} onClick={()=>{
                                                onClickToken({"symbol": params.token_symbol, "token_address": params.token_address})
                                            }} src={item}>
                                            </Avatar>
                                        ))}

                                    </AvatarGroup>
                                </Badge>

                            }


                        </ListItemAvatar>
                        <ListItemText key={1}
                                      primaryTypographyProps={{ style: { fontSize:"14px"} }}
                                      secondaryTypographyProps={{style:{fontSize: "10px"}}}
                                      primary={<div>{params.token_symbol}  {params.protocol ? <Chip label={params.protocol} size={"small"} style={{fontSize:"10px"}}></Chip>:<></>}</div>}
                                      secondary={params.chain}
                            // secondary=
                            //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                            //     <ListItem>Per Basis: 100$</ListItem>
                            //   </List>}

                        >
                            {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                        </ListItemText>
                    </ListItem>

                </List>
            </ThemeProvider>

        </>
    )
};



export const RenderCollectionNFT = (
                                nftImage,
                                 collectionName,
                                 tokenId,
                                 chain,
                                 onClickToken, theme) => {

    const defaultTheme = createTheme({
        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {
                fontWeight: "bold",
                fontSize:14,
                color:"black"
            },

        },
    });

    theme = theme || defaultTheme

    // let name = "tokensIn"
    // sort by the once with the most financial value


    return (<>
            <ThemeProvider theme={theme}>
                <List>
                    <ListItem key={1} style={{maxHeight:"30px"}}>
                        <ListItemAvatar style={{cursor:"pointer", maxWidth:"30px", marginRight:"20px", minWidth:"0px"}} onClick={()=>{

                        }}>
                            {nftImage && <Badge
                                disableOutline
                                style={{background:"none"}}
                                content={<Avatar
                                    // bordered
                                    // squared/
                                    color="primary"
                                    size="sm"
                                    style={{width:15, height:15, background:"none"}}
                                    src={getChainLogo(chain)}
                                />}

                            >
                                <Avatar
                                    // bordered
                                    // squared
                                    color="primary"
                                    size="lg"

                                    src={nftImage}
                                    onClick={()=>{
                                        onClickToken()
                                    }}
                                />
                            </Badge>

                            }
                            {nftImage == undefined &&
                                <Badge
                                    disableOutline
                                    style={{background:"none"}}
                                    content={<Avatar
                                        // bordered
                                        // squared/
                                        color="primary"
                                        size="sm"
                                        style={{width:15, height:15, background:"none"}}
                                        src={getChainLogo(chain)}
                                    />}

                                >
                                    <Avatar size={"lg"} style={{fontSize:"10px"}} onClick={()=>{
                                        onClickToken()
                                    }}>
                                        {"?"}
                                    </Avatar>
                                </Badge>

                            }


                        </ListItemAvatar>
                        <ListItemText key={1}
                                      primaryTypographyProps={{ style: { fontSize:"14px"} }}
                                      secondaryTypographyProps={{style:{fontSize: "10px"}}}
                                      primary={collectionName}
                                      secondary={`id:${tokenId}`}
                        >
                        </ListItemText>
                    </ListItem>

                </List>
            </ThemeProvider>

        </>
    )
};



export const RenderUniV3LPNFT = (
                            nftImage,
                              tokenId,
                              token0Info,
                              token1Info,
                              chain,
                              feeTier,
                              onClickToken, theme) => {

    const defaultTheme = createTheme({
        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {
                fontWeight: "bold",
                fontSize:14,
                color:"black"
            },

        },
    });

    theme = theme || defaultTheme

    // let name = "tokensIn"
    // sort by the once with the most financial value


    return (<>
            <ThemeProvider theme={theme}>
                <List>
                    <ListItem key={1} style={{maxHeight:"30px"}}>
                        <ListItemAvatar style={{cursor:"pointer", maxWidth:"30px", marginRight:"20px", minWidth:"0px"}} onClick={()=>{

                        }}>
                            {nftImage && <Badge
                                disableOutline
                                style={{background:"none"}}
                                content={<Avatar
                                    // bordered
                                    // squared/
                                    color="primary"
                                    size="sm"
                                    style={{width:15, height:15, background:"none"}}
                                    src={getChainLogo(chain)}
                                />}

                            >
                                <Avatar
                                    // bordered
                                    // squared
                                    color="primary"
                                    size="lg"

                                    src={nftImage}
                                    onClick={()=>{
                                        onClickToken()
                                    }}
                                />
                            </Badge>

                            }
                            {nftImage == undefined &&
                                <Badge
                                    disableOutline
                                    style={{background:"none"}}
                                    content={<Avatar
                                        // bordered
                                        // squared/
                                        color="primary"
                                        size="sm"
                                        style={{width:15, height:15, background:"none"}}
                                        src={getChainLogo(chain)}
                                    />}

                                >
                                    <Avatar size={"lg"} style={{fontSize:"10px"}} onClick={()=>{
                                        onClickToken()
                                    }}>
                                        {"NAME."}
                                    </Avatar>
                                </Badge>

                            }


                        </ListItemAvatar>
                        <ListItemText key={1}
                                      primaryTypographyProps={{ style: { fontSize:"14px"} }}
                                      secondaryTypographyProps={{style:{fontSize: "10px"}}}
                                      primary={`${token0Info.symbol}/${token1Info.symbol} ${feeTier/10000 || 0}%`}
                                      secondary={tokenId != undefined ? `id:${tokenId}`: ""}
                        >
                        </ListItemText>
                    </ListItem>

                </List>
            </ThemeProvider>

        </>
    )
};





export const RenderSimpleTextField  = (value, customStyle) =>{
    let styleText = Object.assign({}, {fontSize:"14px", fontFamily: "Roboto", fontWeight:"700", lineHeight:"1"}, customStyle)

    return (
        <span style={styleText}>
        {value}
    </span>)
}

export const RenderSimpleNumberField  = (value, customStyle={}, afterSign="$") =>{
    // let styleText = Object.assign({}, {fontSize:"14px", fontFamily: "Roboto", fontWeight:"700", lineHeight:"1"}, customStyle)
    return (
        <span style={customStyle}>
        {prettyPrint(value) + afterSign}
    </span>)
}
export const RenderSimpleFieldWithImage  = (primaryText, secondaryText, image, customStyle={}, afterSign="$") =>{
    // let styleText = Object.assign({}, {fontSize:"14px", fontFamily: "Roboto", fontWeight:"700", lineHeight:"1"}, customStyle)
    const defaultTheme = createTheme({
        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {
                fontWeight: "bold",
                fontSize:14,
                color:"black"
            },

        },
    });

    let theme = customStyle.theme || defaultTheme

    // let name = "tokensIn"
    // sort by the once with the most financial value


    return (<>
            <ThemeProvider theme={theme}>
                <List>
                    <ListItem key={1} style={{maxHeight:"30px"}}>
                        <ListItemAvatar style={{cursor:"pointer", maxWidth:"30px", marginRight:"20px", minWidth:"0px"}} onClick={()=>{

                        }}>
                            {
                                <Avatar
                                    // bordered
                                    // squared
                                    color="primary"
                                    size="lg"

                                    src={image}

                                />

                            }
                            {/*{image == undefined &&*/}
                            {/*    <Badge*/}
                            {/*        disableOutline*/}
                            {/*        style={{background:"none"}}*/}
                            {/*        content={<Avatar*/}
                            {/*            // bordered*/}
                            {/*            // squared/*/}
                            {/*            color="primary"*/}
                            {/*            size="sm"*/}
                            {/*            style={{width:15, height:15, background:"none"}}*/}
                            {/*            // src={getChainLogo(chain)}*/}
                            {/*        />}*/}

                            {/*    >*/}
                            {/*        <Avatar size={"lg"} style={{fontSize:"10px"}} onClick={()=>{*/}
                            {/*            onClickToken()*/}
                            {/*        }}>*/}
                            {/*            {"NAME."}*/}
                            {/*        </Avatar>*/}
                            {/*    </Badge>*/}

                            {/*}*/}


                        </ListItemAvatar>
                        <ListItemText key={1}
                                      primaryTypographyProps={{ style: { fontSize:"14px"} }}
                                      secondaryTypographyProps={{style:{fontSize: "10px"}}}
                                      primary={primaryText}
                                      secondary={secondaryText}
                        >
                        </ListItemText>
                    </ListItem>

                </List>
            </ThemeProvider>

        </>
    )

}