// calculate cost basis
import {Dialog} from "primereact/dialog";
import Grid from "@mui/material/Grid";
import SoftBox from "../SoftBox";
import MiniStatisticsCard from "../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import {
    getAssetByAddressFromPrimaryAssets,
    getOperationType,
    getPrimaryAssets, getTransactionLink,
    isTransactionIncludesToken, isTransactionIncludesTokenCompareByAddress,
    prettyPrint, prettyPrintAddress,
    sortTransactionsByTimestamp,
    timestampToHumanReadable
} from "../../common/utils";
import GradientLineChart from "../../examples/Charts/LineCharts/GradientLineChart";
import SoftTypography from "../SoftTypography";
import Card from "@mui/material/Card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React from "react";
import Link from "@mui/material/Link";
import {Chip} from "primereact/chip";
import {ListItem, ListItemText} from "@mui/material";
import Typography from "@mui/material/Typography";
import {RenderProfitLossCell} from "../common";
import Header from "../../layouts/profile/components/Header";
import {TokenInfoHeader} from "../TokenInfoHeader";

// In order to show the FIFO calculation we only look at the sell transactions between [startDate, endDate]
export function FIFOCalculator({
                                   txs,
                                   pnlExplainedData,
                                   dialogVisible,
                                   setDialogVisible,
                                   tokenHoldingData,
                                   startDate,
                                   endDate
                               }) {
    startDate = pnlExplainedData.startDate || 0
    endDate = pnlExplainedData.endDate || 16890698900000000000
    let tokenAddress = pnlExplainedData.token_address
    let tokenSymbol = pnlExplainedData.symbol
    let tokenTxs = txs.filter(x => isTransactionIncludesTokenCompareByAddress(tokenAddress, x))
    let txSorted = sortTransactionsByTimestamp(tokenTxs, "asc")
    const headerStyle = {"background": "none", fontSize: "14px"}
    const bodyStyle = {"color": "black", fontSize: "13px", fontWeight: "700"}


    let totalPurchasesTxs = txSorted.filter(tx => {
        let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(tx, tokenAddress.toLowerCase())
        return tokenIn?.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()
    })

    // get only the sells in between [startDate, endDate]
    let totalSoldTxs = txSorted.filter(tx => {
        let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(tx, tokenAddress.toLowerCase())
        return tokenOut?.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase() && tx.tokensIn.length >= 1
    })


    totalSoldTxs = totalSoldTxs.filter(tx => tx.timeStamp > startDate && tx.timeStamp < endDate)

    return (
        <>
            <Dialog header="P&L Calculator"
                    visible={dialogVisible}
                    style={{width: '50vw', zIndex: "3000"}}
                    maximizable baseZIndex={3000}
                    modal contentStyle={{height: '700px'}}
                    onHide={() => setDialogVisible(false)}>
                <Grid item xs={12} lg={12}>
                    {/*<TokenInfoHeader/>*/}
                    {/*<Header></Header>*/}
                    <SoftBox py={3}>
                        <SoftBox mb={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <MiniStatisticsCard
                                        title={{text: "Total Transactions"}}
                                        count={txSorted.length}
                                        // percentage={{ color: "success", text: "+55%" }}
                                        icon={{color: "info", component: "paid"}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <MiniStatisticsCard
                                        title={{text: "Current Balance"}}
                                        count={prettyPrint(Number(tokenHoldingData?.amount_of_tokens || 0)) + " " + tokenSymbol}
                                        percentage={{
                                            color: "success",
                                            text: `${prettyPrint(tokenHoldingData?.amountInUSD || 0)}$`
                                        }}
                                        icon={{color: "info", component: "paid"}}
                                    />
                                </Grid>



                            </Grid>
                        </SoftBox>
                    </SoftBox>

                </Grid>
                <Grid item xs={12} lg={12} style={{marginTop: "30px"}}>
                    <Card>
                        <DataTable header={<span>Historical Trading Activity</span>} value={[...totalPurchasesTxs, ...totalSoldTxs]}
                                   paginator
                                   rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                                   sortField={"timeStamp"}
                                   sortOrder={1}
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown">

                            <Column field="hash"
                                     body={(row) => {
                                         return <a target="_blank" href={getTransactionLink(row.chain, row.hash)}><i className="pi pi-external-link"/></a>
                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>


                            <Column field="timeStamp"
                                    sortable={true} header="Date" body={(row) => {
                                return timestampToHumanReadable(row.timeStamp * 1000)
                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>

                            {/*<Column field="hash"*/}
                            {/*        header="Transaction Hash" body={(row) => {*/}
                            {/*    return (*/}
                            {/*        <Link href={getTransactionLink(row.chain, row.hash)} isExternal={true}*/}
                            {/*              target="_blank">*/}
                            {/*            {prettyPrintAddress(row.hash)}*/}
                            {/*        </Link>*/}
                            {/*    )*/}

                            {/*}} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>*/}

                            <Column field="type"
                                    header="Type" body={(row) => {
                                return (
                                    getOperationType(row)
                                )

                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>
                            <Column field="tokenInAmount"
                                    header="Units Transferred" body={(row) => {

                                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress.toLowerCase())
                                let text
                                let color = "green"
                                let token
                                if (tokenIn != undefined && tokenIn.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                    text = tokenIn != undefined ? "+" + prettyPrint(tokenIn.amount) + " " + tokenIn.tokenInfo.symbol : ""
                                    token = tokenIn

                                }
                                else if (tokenOut != undefined && tokenOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()){
                                    text = tokenOut != undefined ? "-" + prettyPrint(tokenOut.amount) + " " + tokenOut.tokenInfo.symbol : ""
                                    color = "red"
                                    token = tokenOut
                                }
                                return <div style={{display:"flex", flexDirection:"column"}}><div>
                                    <span style={{color:color}}>{text}</span></div>
                                    <div>
                                <span style={{fontSize:"10px"}}>{"Balance After: " + prettyPrint(token.balanceAfter) + " "+ token.tokenInfo.symbol}</span>
                                    </div>
                                </div>

                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>
                            {/*<Column field="hash"*/}
                            {/*        header="Balance After" body={(row) => {*/}

                            {/*    let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress.toLowerCase())*/}
                            {/*    if (tokenIn != undefined && tokenIn.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {*/}
                            {/*        return tokenIn != undefined ? prettyPrint(tokenIn.balanceAfter || 0) +" " + tokenIn.tokenInfo.symbol: ""*/}

                            {/*    }*/}
                            {/*    else if (tokenOut != undefined && tokenOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()){*/}
                            {/*        return tokenOut != undefined ? prettyPrint(tokenOut.balanceAfter || 0) +  " " + tokenOut.tokenInfo.symbol: ""*/}
                            {/*    }*/}

                            {/*}} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>*/}
                            <Column field="tokenInAmount"
                                    header="Price Per Unit" body={(row) => {

                                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress.toLowerCase())
                                if (tokenIn != undefined && tokenIn.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                    let tokenInPriceAtTheTime = tokenIn != undefined ? tokenIn.price : 0
                                    let pricePerUnit = row.pnlCostBasis ? row.pnlCostBasis.pricePerUnit : tokenInPriceAtTheTime
                                    return tokenIn != undefined ? prettyPrint(pricePerUnit) + "$" : ""

                                }
                                else if (tokenOut != undefined && tokenOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()){
                                    let pricePerUnit = tokenOut.price
                                    return tokenOut != undefined ? prettyPrint(tokenOut.price) + "$": ""
                                }


                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>

                            <Column field="tokenInAmount"
                                    header="USD Value" body={(row) => {
                                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress.toLowerCase())
                                if (tokenIn != undefined && tokenIn.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                    let tokenInPriceAtTheTime = tokenIn != undefined ? tokenIn.price : 0
                                    let pricePerUnit = row.pnlCostBasis ? row.pnlCostBasis.pricePerUnit : tokenInPriceAtTheTime
                                    return tokenIn != undefined ? prettyPrint(pricePerUnit * tokenIn.amount) + "$" : ""

                                }
                                else if (tokenOut != undefined && tokenOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()){
                                    let pricePerUnit = tokenOut.price
                                    return tokenOut != undefined ? prettyPrint(pricePerUnit * tokenOut.amount) + "$": ""
                                }


                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>

                            <Column field="timeStamp"
                                    header="PNL" body={(row) => {
                                        let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress.toLowerCase())
                                        if (tokenOut != undefined && tokenOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                            return RenderProfitLossCell(row?.pnl?.pnl)
                                        }
                                        else {

                                        }

                                // let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress.toLowerCase())
                                // let tokenInPriceAtTheTime = tokenIn != undefined ? tokenIn.price : 0
                                // let pricePerUnit = row.pnlCostBasis ? row.pnlCostBasis.pricePerUnit : tokenInPriceAtTheTime
                                //
                                // return tokenIn != undefined ? prettyPrint(pricePerUnit * tokenIn.amount) + "$" : ""
                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>


                        </DataTable>

                    </Card>

                </Grid>


                {/*<Grid item xs={12} lg={12} style={{marginTop: "30px"}}>*/}
                {/*    <Card>*/}
                {/*        <DataTable header={<span>Historical Sell Overview</span>} value={totalSoldTxs} paginator*/}
                {/*                   sortField={"timeStamp"}*/}
                {/*                   sortOrder={1}*/}
                {/*                   rows={5} rowsPerPageOptions={[5, 10, 25, 50]}*/}
                {/*                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown">*/}
                {/*            <Column field="timeStamp"*/}
                {/*                    sortable={true} header="Date" body={(row) => {*/}
                {/*                return timestampToHumanReadable(row.timeStamp * 1000)*/}
                {/*            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>*/}
                {/*            <Column field="hash"*/}
                {/*                    header="Transaction Hash" body={(row) => {*/}
                {/*                return (*/}
                {/*                    <Link href={getTransactionLink(row.chain, row.hash)} isExternal={true}*/}
                {/*                          target="_blank">*/}
                {/*                        {prettyPrintAddress(row.hash)}*/}
                {/*                    </Link>*/}
                {/*                )*/}

                {/*            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>*/}

                {/*            <Column field="type"*/}
                {/*                    header="Type" body={(row) => {*/}
                {/*                return (*/}
                {/*                    getOperationType(row)*/}
                {/*                )*/}

                {/*            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>*/}

                {/*            <Column field="tokenInAmount"*/}
                {/*                    header="Units Transferred" body={(row) => {*/}
                {/*                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress.toLowerCase())*/}
                {/*                return tokenOut != undefined ? prettyPrint(tokenOut.amount) + " " + tokenOut.tokenInfo.symbol : ""*/}
                {/*            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>*/}
                {/*            <Column field="tokenInAmount"*/}
                {/*                    header="USD Value Received" body={(row) => {*/}
                {/*                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress.toLowerCase())*/}
                {/*                return tokenOut != undefined ? prettyPrint(tokenOut.usdValueAmount) + "$" : ""*/}

                {/*            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>*/}
                {/*            <Column field="timeStamp"*/}
                {/*                    header="Total Cost Basis" body={(row) => {*/}

                {/*                return prettyPrint(row?.pnl?.costBasis) + "$"*/}
                {/*            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>*/}

                {/*            <Column field="timeStamp"*/}
                {/*                    header="P&L" body={(row) => {*/}
                {/*                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress.toLowerCase())*/}
                {/*                return RenderProfitLossCell(row?.pnl?.pnl)*/}

                {/*            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>*/}


                {/*        </DataTable>*/}

                {/*    </Card>*/}

                {/*</Grid>*/}
            </Dialog>
        </>)

}