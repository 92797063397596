import {atom, useRecoilState} from "recoil";
import {prettyPrintAddress} from "../common/utils";

const localStorageEffect = key => ({setSelf, onSet}) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        let accounts = JSON.parse(savedValue)
        let data = {}
        for (const account of accounts){
            data[account.toLowerCase()] = {
                "txList": [],
                "portfolio": {"tokens": []},
                "label": prettyPrintAddress(account.toLowerCase()),
                "address": account.toLowerCase(),
                "isLoading": false,
                "lastSyncedTime": 0,
                "lastSyncedPortfolioTime": 0
        }


        setSelf(data);

        }

    }

    onSet((newValue, _, isReset) => {

        isReset
            ? localStorage.removeItem(key)
            : localStorage.setItem(key, JSON.stringify(Object.keys(newValue)));
    });
};



export const accountsState = atom({
    key: 'accountsState',
    default: {},

    effects:[
        localStorageEffect('accounts'),
    ]
});
