/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import {useRecoilState} from "recoil";
import {accountsState} from "../../../data/accountData";
import {isValidAddress, prettyPrintAddress} from "../../../common/utils";
import {SoftInputSupportUnderlinedText} from "../../../components/SoftInputSupportUnderlineText";
import {Toast} from "primereact/toast";

function SignUp() {
  const [agreement, setAgremment] = useState(true);
  const [accounts, setAccounts] = useRecoilState(accountsState);
  const [accountAddress, setAccountAddress] = useState("")
  const [accountLabel, setAccountLabel] = useState("")
  const [addressInputError, setAddressInputError] = useState( " ")
  const navigate = useNavigate();

  const handleSetAgremment = () => setAgremment(!agreement);

  return (


    <BasicLayout
      title="Welcome to Defilo"
      description="Your Place for managing all your web3 financials."
      image={curved6}
      navBar={false}
    >
      <Card style={{boxShadow:"0 9.62406px 57.7444px rgb(75 148 205 / 30%)"}}>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="bold" fontFamily={"Poppins"}>
            Enter a wallet to track
          </SoftTypography>
        </SoftBox>
        {/*<SoftBox mb={2}>*/}
        {/*  <Socials />*/}
        {/*</SoftBox>*/}
        {/*<Separator />*/}
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">

            <SoftBox >

              <SoftInputSupportUnderlinedText errorText={" "} placeHolder={"Label"} informationalText={" "}
                                              onChange={(e)=>{
                                                setAccountLabel(e.target.value)
                                              }}
              ></SoftInputSupportUnderlinedText>
            </SoftBox>
            <SoftBox>
              <SoftInputSupportUnderlinedText  errorText={addressInputError} placeHolder={"Blockchain Address"} informationalText={" "}
                                              onChange={(e)=>{
                                                if (isValidAddress(e.target.value))
                                                {
                                                  setAddressInputError(" ")
                                                  setAccountAddress(e.target.value)

                                                }
                                                else {
                                                  setAddressInputError(" Address is not a blockchain address")
                                                }

                                              }}
              ></SoftInputSupportUnderlinedText>
            </SoftBox>



            {/*<SoftBox mb={2}>*/}
            {/*  <SoftInput placeholder="Name" />*/}
            {/*</SoftBox>*/}
            {/*<SoftBox mb={2}>*/}
            {/*  <SoftInput placeholder="Address" />*/}
            {/*</SoftBox>*/}
            {/*<SoftBox mb={2}>*/}
            {/*  <SoftInput type="password" placeholder="Password" />*/}
            {/*</SoftBox>*/}
            {/*<SoftBox display="flex" alignItems="center">*/}
            {/*  <Checkbox checked={agreement} onChange={handleSetAgremment} />*/}
            {/*  <SoftTypography*/}
            {/*    variant="button"*/}
            {/*    fontWeight="regular"*/}
            {/*    onClick={handleSetAgremment}*/}
            {/*    sx={{ cursor: "poiner", userSelect: "none" }}*/}
            {/*  >*/}
            {/*    &nbsp;&nbsp;I agree the&nbsp;*/}
            {/*  </SoftTypography>*/}
            {/*  <SoftTypography*/}
            {/*    component="a"*/}
            {/*    href="#"*/}
            {/*    variant="button"*/}
            {/*    fontWeight="bold"*/}
            {/*    textGradient*/}
            {/*  >*/}
            {/*    Terms and Conditions*/}
            {/*  </SoftTypography>*/}
            {/*</SoftBox>*/}
            <SoftBox mt={4} mb={1}>

              <SoftButton variant="gradient" color="dark" fullWidth fontFamily={"Rubik"} onClick={()=>{
                if (!isValidAddress(accountAddress)) {
                  setAddressInputError("Invalid Blockchain address")
                }
                // add the account
                else {
                  let newAccounts = Object.assign({}, accounts, {[accountAddress.toLowerCase()]:{
                      label: accountLabel || prettyPrintAddress(accountAddress),
                      address: accountAddress.toLowerCase(),
                      lastSyncedTime: 0,
                      txList: [],
                      isLoading: false
                    }})
                  setAccounts(newAccounts)
                  navigate(`/accounting/${accountAddress.toLowerCase()}/explorer`)

                }

              }}>
                Search Address
              </SoftButton>
            </SoftBox>
            {/*<SoftBox mt={3} textAlign="center">*/}
            {/*  <SoftTypography variant="button" color="text" fontWeight="regular">*/}
            {/*    Already have an account?&nbsp;*/}
            {/*    <SoftTypography*/}
            {/*      component={Link}*/}
            {/*      to="/authentication/sign-in"*/}
            {/*      variant="button"*/}
            {/*      color="dark"*/}
            {/*      fontWeight="bold"*/}
            {/*      textGradient*/}
            {/*    >*/}
            {/*      Sign in*/}
            {/*    </SoftTypography>*/}
            {/*  </SoftTypography>*/}
            {/*</SoftBox>*/}
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
