import IconButton from "@mui/material/IconButton";
import {ArrowCircleDown, ArrowCircleUpRounded, CodeOutlined, Error, MultipleStopOutlined} from "@mui/icons-material";
import * as React from "react";
import _, {intersection} from "lodash";
import ethLogo from "assets/images/crypto-logos/eth@2x.png";
import bnbLogo from "assets/images/crypto-logos/bnb@2x.png";
import polygonLogo from "assets/images/crypto-logos/polygonLogo.png"
import baseLogo from "assets/images/crypto-logos/baseLogo.png"
import {RenderProfitLossCell} from "../components/common";

export const isValidAddress = (address) => {

    const re = "^0x[a-fA-F0-9]{40}$"
    const bitcoinRegex = /^(?:[13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|bc1[a-z0-9]{39,59})$/
    return (address.match(re) && address.match(re).length == 1)
    // || (address.match(bitcoinRegex) && address.match(bitcoinRegex).length == 1)

}


export const prettyPrintAddress = (txHash) => {
    if (txHash) {
        return txHash.substring(0, 4) + "..." + txHash.substring(txHash.length - 4)
    } else {
        return ""
    }

}

export function timestampToHumanReadable(timestamp) {
    const date = new Date(timestamp);

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat('en', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).format(date);

    return formattedDate
}

function prettyPrint(x) {

    x = Number(x);
    let mul = x < 0 ? -1 : 1
    x = Math.abs(x)

    let result;
    if (x > 1) {
        result = Number(x.toFixed(3))
    } else {
        result = Number(x.toPrecision(3))
    }

    result = result.toString()
    if (x < 1 && result.includes("e")) {
        result = Number(result).toFixed(result.toString().split("-")[1]);
        result = result.toString()
    }
    if (Number(result) < 1) {
        return result * mul
    } else {
        return (Number(result) * mul).toLocaleString()
    }

    return result * mul
}

function compactNumber(x) {
    return new Intl.NumberFormat('en-GB', {
        notation: "compact",
        compactDisplay: "short"
    }).format(x);
}

const getPNLText = (value) => {
    let prefix = value > 0 ? "" : "-"
    return prefix + compactNumber(Math.abs(value))
}

const parseMethod = (method) => {

    let index = method ? method.indexOf("(") : -1
    if (index != -1) {
        return method.slice(0, index)
    }
    return method

}

export function removeElementByIndex(arr, index) {
    let copy = [...arr]
    if (index >= 0 && index < arr.length) {
        copy.splice(index, copy.length == 1 ? 2 : 1);
        return copy
    }
    return copy;
}

export function getTransactionLink(chain, tx) {
    if (chain == "bsc") {
        return `https://bscscan.com/tx/${tx}`
    }
    if (chain == "eth") {
        return `https://etherscan.io/tx/${tx}`
    }
    if (chain == "polygon") {
        return `https://polygonscan.com/tx/${tx}`
    }
    if (chain == "base") {
        return `https://basescan.org/tx/${tx}`
    }

}

export function getAccountLink(chain, account) {
    if (chain == "bsc") {
        return `https://bscscan.com/address/${account}`
    }
    if (chain == "eth") {
        return `https://etherscan.io/address/${account}`
    }
    if (chain == "polygon") {
        return `https://polygonscan.com/address/${account}`
    }
    if (chain == "base") {
        return `https://basescan.org/address/${account}`
    }

}


export function getTxIcon(tx) {
    let icon
    if (tx.operation == "Trade" || tx.operation.includes("Add Liquidity") || tx.operation.includes("Remove Liquidity")) {
        icon = <MultipleStopOutlined sx={{color: "blue"}}/>
    } else if (tx.operation.includes("Send")) {
        icon = <ArrowCircleUpRounded sx={{color: "red"}} fill={true}/>
    } else if (tx.operation.includes("Receive")) {
        icon = <ArrowCircleDown sx={{color: "green"}}/>
    }
    // else contract call.
    else {
        icon = <CodeOutlined sx={{color: "gray"}}/>
    }
    return icon
}


export const getTokensInTx = (tx) => {
    let tokensInNames = tx.tokensIn.map(x => x.tokenInfo.symbol)
    let tokensOutNames = tx.tokensOut.map(x => x.tokenInfo.symbol)
    let allTokens = tokensInNames.concat(tokensOutNames)
    return allTokens
}

export const isTransactionIncludesToken = (tokenSymbol, tx) => {
    let allTokens = getTokensInTx(tx).map(x => x?.toLowerCase())

    if (intersection([tokenSymbol?.toLowerCase()], allTokens).length >= 1) {
        return true
    } else {
        return false
    }

}

export const isTransactionIncludesTokenCompareByAddress = (tokenAddress, tx) => {
    let tokensIn = tx.tokensIn.map(x => x.tokenInfo.addr)
    let tokensOut = tx.tokensOut.map(x => x.tokenInfo.addr)
    let allTokens = tokensIn.concat(tokensOut)
    allTokens = allTokens.map(x => x.toLowerCase())

    if (intersection([tokenAddress?.toLowerCase()], allTokens).length >= 1) {
        return true
    } else {
        return false
    }


}

export const sortTransactionsByTimestamp = (txs, orderDirection = "asc") => {
    // dec order
    let txOrdered = txs.sort((a, b) => {
        if (a.timeStamp < b.timeStamp) {
            return 1
        } else if (a.timeStamp > b.timeStamp) {
            return -1
        }
        return 0
    })

    if (orderDirection == "asc") {
        txOrdered = txOrdered.reverse()
    }

    return txOrdered

}

export const getAssetsReceivedInTx = (tx) => {
    let assetsIn = tx.tokensIn.map(x => x.tokenInfo.symbol)
    return assetsIn
}

export const getAssetsOutInTx = (tx) => {
    let assetsOut = tx.tokensOut.map(x => x.tokenInfo.symbol)
    return assetsOut
}

export const getPrimaryAssets = (tx) => {
    let sortedTokensIn = [...tx.tokensIn].sort((a, b) => b.usdValueAmount - a.usdValueAmount)
    let sortedTokensOut = [...tx.tokensOut].sort((a, b) => b.usdValueAmount - a.usdValueAmount)
    let tokenInPrimary
    let tokenOutPrimary

    if (tx.tokenInPrimary.length > 1) {
        tokenInPrimary = tx.tokenInPrimary
    }
    if (tx.tokenOutPrimary.length > 1) {
        tokenOutPrimary = tx.tokenOutPrimary
    }

    if (tx.tokenInPrimary.length == 1) {
        tokenInPrimary = tx.tokenInPrimary[0]
    }

    if (tx.tokenInPrimary.length == 0) {
        tokenInPrimary = undefined
    }

    if (tx.tokenOutPrimary.length == 1) {
        tokenOutPrimary = tx.tokenOutPrimary[0]
    }

    if (tx.tokenOutPrimary.length == 0) {
        tokenOutPrimary = undefined
    }


    return [tokenInPrimary, tokenOutPrimary]
}


export const getAssetByAddressFromPrimaryAssets = (row, tokenAddress) => {
    let [tokensIn, tokensOut] = getPrimaryAssets(row)
    let tokenOut
    let tokenIn

    if (tokensOut) {
        if (tokensOut.length > 1) {
            tokenOut = tokensOut.filter(x => x.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase())[0]
        } else {
            if (tokensOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                tokenOut = tokensOut
            }

        }
    }
    if (tokensIn) {
        if (tokensIn.length > 1) {
            tokenIn = tokensIn.filter(x => x.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase())[0]
        } else {
            if (tokensIn.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                tokenIn = tokensIn
            }

        }
    }

    return [tokenIn, tokenOut]
}


export const getChainLogo = (chain) => {
    if (chain == "eth") {
        return ethLogo
    } else if (chain == "bsc") {
        return bnbLogo
    } else if (chain == "polygon") {
        return polygonLogo
    }
    else if (chain == "base") {
        return baseLogo
    }

}


export const getListOfAccounts = (accounts) => {
    let accountsList = []

    for (const account of Object.keys(accounts)) {
        accountsList.push(accounts[account.toLowerCase()])
    }

    return accountsList
}


export const getAccountPortfolioSynced = (accounts) => {
    let count = 0
    for (const account of Object.keys(accounts)){
        if (accounts[account].lastSyncedPortfolioTime > 0) {
            count +=1
        }
    }
    return count
}
export const getAccountPortfolioValue = (portfolio) => {
    let tokenPortfolioValue = calculateAccountUSDValue(portfolio?.tokens || [])
    return tokenPortfolioValue

}

export const getTransferInData = (tokenTxs, tokenSymbol) => {
    let amountIn = 0
    let amountInUsdVal = 0
    // check only for transactions where the tokens out is the token and no token in (because it's not a swap)
    for (const tx of tokenTxs) {
        if (tx.tokensOut.length == 0 && getTokensInTx(tx).includes(tokenSymbol)) {
            for (const transferIn of tx.tokensIn) {
                if (transferIn.tokenInfo.symbol == tokenSymbol) {
                    amountIn += transferIn.amount
                    amountInUsdVal += transferIn.usdValueAmount
                }
            }
        }
    }
    return [amountIn, amountInUsdVal]
}


export const getTransferOutData = (tokenTxs, tokenSymbol) => {
    let amountIn = 0
    let amountInUsdVal = 0
    // check only for transactions where the tokens out is the token and no token in (because it's not a swap)
    for (const tx of tokenTxs) {
        if (tx.tokensIn.length == 0 && getTokensInTx(tx).includes(tokenSymbol)) {
            for (const transferIn of tx.tokensOut) {
                if (transferIn.tokenInfo.symbol == tokenSymbol) {
                    amountIn += transferIn.amount
                    amountInUsdVal += transferIn.usdValueAmount
                }
            }
        }
    }
    return [amountIn, amountInUsdVal]
}

export const getTokens = (tokens) => {
    return tokens.filter(x => (x.hpStatus == 0 || x.logoCMC != undefined) && x.totalValueInUSD > 10)

}
export const calculateAccountUSDValue = (tokens) => {
    let usdVal = 0
    for (const token of tokens) {
        if (token.hpStatus == 0) {
            usdVal += Number(token.totalValueInUSD)

        }
    }
    return usdVal

}


export const shouldGetAccountPortfolioDataFromBackend = (account) => {
    if (account.lastSyncedPortfolioTime == undefined) {
        return true
    }
    else if (account.lastSyncedPortfolioTime==0) {
        return true
    }
    return false
}
export const mergeTokenData = (accounts) => {
    let tokensData = []
    for (const account of Object.keys(accounts)) {
        tokensData = tokensData.concat(accounts[account]?.portfolio?.tokens || [])
    }
    let allTokens = {}
    for (const token of tokensData) {
        let tokenAddr = token.token_address.toLowerCase()
        if (tokenAddr == "") {
            if (allTokens[token.token_name]) {
                allTokens[token.token_name].amount_of_tokens += Number(token.amount_of_tokens)
                allTokens[token.token_name].totalValueInUSD += Number(token.totalValueInUSD)
            } else {
                allTokens[token.token_name] = {...token}
                allTokens[token.token_name].amount_of_tokens = Number(token.amount_of_tokens)
                allTokens[token.token_name].totalValueInUSD = Number(token.totalValueInUSD)
            }
        } else if (allTokens[tokenAddr]) {
            allTokens[tokenAddr].amount_of_tokens += Number(token.amount_of_tokens)
            allTokens[tokenAddr].totalValueInUSD += Number(token.totalValueInUSD)
        } else {
            allTokens[tokenAddr] = {...token}
            allTokens[tokenAddr].amount_of_tokens = Number(token.amount_of_tokens)
            allTokens[tokenAddr].totalValueInUSD = Number(token.totalValueInUSD)
        }
    }
    let result = []
    for (const key of Object.keys(allTokens)) {
        result.push(allTokens[key])
    }
    return result
}


export const getAllAccountsUSDBalance = (accounts) => {
    let allTokens = mergeTokenData(accounts)
    return calculateAccountUSDValue(allTokens)
}

export const getAssetDistributionChartData = (tokens) => {
    let chartData = []
    if (tokens) {
        chartData.push(["Token Name", "USD Value"])
    }
    for (const token of tokens) {
        chartData.push([token.token_symbol, token.totalValueInUSD])
    }

    return chartData

}

export function calculatePNLPerSell(purchases, sells) {
    sells.forEach((sell, sellIndex) => {
        let remainingQuantity = sell.quantity;
        let sellCostBasis = 0;
        let sellPnL = 0;


        for (let [purchaseIndex, purchase] of purchases.entries()) {
            const {quantity, price, blockTimestamp} = purchase;
            if (blockTimestamp > sell.blockTimestamp && remainingQuantity > 0) {
                console.error("failed to caculate PNL!")
                break;
            }
            if (remainingQuantity > 0) {
                const soldQuantity = Math.min(remainingQuantity, quantity);
                const costBasis = soldQuantity * price;
                const saleValue = soldQuantity * sell.price;
                const pnl = saleValue - costBasis;

                remainingQuantity -= soldQuantity;
                sellCostBasis += costBasis;
                sellPnL += pnl;

                // Update purchases array with cost basis and profit/loss
                purchases[purchaseIndex].quantity -= soldQuantity;
                purchases[purchaseIndex].pnl = pnl;

            }

        }
        // Update sells array with cost basis and profit/loss
        sells[sellIndex].costBasis = sellCostBasis;
        sells[sellIndex].pnl = sellPnL;
        let quantityAfter = 0
        // check how much left in the inventory after this trade
        for (let [purchaseIndex, purchase] of purchases.entries()) {
            const {quantity, price, blockTimestamp} = purchase;
            if (blockTimestamp > sell.blockTimestamp) {
                break
            }
            quantityAfter += quantity
        }
        sells[sellIndex].quantityAfter = quantityAfter;
    });

    return sells
}

export function getInventoryLeftOfToken(purchases, sells) {
    sells.forEach((sell, sellIndex) => {
        let remainingQuantity = sell.quantity;
        let sellCostBasis = 0;
        let sellPnL = 0;
        let result = []


        for (let [purchaseIndex, purchase] of purchases.entries()) {
            const {quantity, price, blockTimestamp} = purchase;
            if (blockTimestamp > sell.blockTimestamp && remainingQuantity > 0) {
                console.error("failed to caculate PNL!")
                break;
            }
            if (remainingQuantity > 0) {
                const soldQuantity = Math.min(remainingQuantity, quantity);
                const costBasis = soldQuantity * price;
                const saleValue = soldQuantity * sell.price;
                const pnl = saleValue - costBasis;

                remainingQuantity -= soldQuantity;
                sellCostBasis += costBasis;
                sellPnL += pnl;

                // Update purchases array with cost basis and profit/loss
                purchases[purchaseIndex].quantity -= soldQuantity;
                purchases[purchaseIndex].pnl = pnl;

            }

        }
        // Update sells array with cost basis and profit/loss
        sells[sellIndex].costBasis = sellCostBasis;
        sells[sellIndex].pnl = sellPnL;
        let quantityAfter = 0
        // check how much left in the inventory after this trade
        for (let [purchaseIndex, purchase] of purchases.entries()) {
            const {quantity, price, blockTimestamp} = purchase;
            if (blockTimestamp > sell.blockTimestamp) {
                break
            }
            quantityAfter += quantity
        }
        sells[sellIndex].quantityAfter = quantityAfter;
    });

    let res = []
    for (const purchase of purchases) {
        const {quantity, price, blockTimestamp} = purchase;
        if (quantity > 0) {
            res.push(purchases)
        }
    }
    return res
}

export const getAllTokensSellAndBuyTxs = (txList, tokenAddr) => {

    // for each token in the list we preserve the purchase price, and quantity. if it's an airdrop we will consider the price as 0.
    let tokenTxs = txList.filter(x => x.tokenInInfo?.addr?.toLowerCase() == tokenAddr.toLowerCase() || x.tokenOutInfo?.addr?.toLowerCase() == tokenAddr.toLowerCase()
        || x.tokensIn.map(x => x.tokenInfo?.addr?.toLowerCase()).includes(tokenAddr.toLowerCase()) || x.tokensOut.map(x => x.tokenInfo.addr.toLowerCase()).includes(tokenAddr.toLowerCase()));

    let purchases = [];
    let sales = [];
    let airdropTxs = [];
    // order by timestamp
    tokenTxs = tokenTxs.sort((a, b) => {
        if (a.timeStamp < b.timeStamp) {
            return -1
        } else if (a.timeStamp > b.timeStamp) {
            return 1
        }
        return 0
    });

    for (const row of tokenTxs) {
        if (row.operation == "Trade") {
            // transferIn
            if (row.tokenInInfo.addr.toLowerCase() == tokenAddr.toLowerCase()) {
                let priceAfterSlippage = Number(row.tokenOutUSDVal / row.tokenInAmount) == 0 ? row.tokenInPrice : Number(row.tokenOutUSDVal / row.tokenInAmount);
                purchases.push({
                    "blockTimestamp": row.timeStamp,
                    "quantity": row.tokenInAmount,
                    "price": row.tokenInPrice,
                    "costBasis": row.tokenInPrice * row.tokenInAmount,
                    "costBasisSuggested": row.tokenInPrice * row.tokenInAmount,
                    "hash": row.hash,
                    "priceWithSlippage": priceAfterSlippage
                })
            } else if (row.tokenOutInfo.addr.toLowerCase() == tokenAddr.toLowerCase()) {
                let priceAfterSlippage = Number(row.tokenInUSDVal / row.tokenOutAmount) == 0 ? row.tokenOutPrice : Number(row.tokenInUSDVal / row.tokenOutAmount);
                // in-case there is a slippage add the amount of tokens out.
                sales.push({
                    "blockTimestamp": row.timeStamp,
                    "quantity": row.tokenOutAmount,
                    "price": row.tokenOutPrice,
                    "hash": row.hash,
                    "priceWithSlippage": priceAfterSlippage
                })
            }
        }
        // if it's a token transfer in and no token transferred out in exchange. consider it as airdrop, the price is the price the asset is worth.
        else if (row.tokensIn.length >= 1 && row.tokensOut.length == 0) {
            for (const tokIn of row.tokensIn) {
                if (tokIn.tokenInfo.addr == tokenAddr) {
                    // if it's an airdrop and the token is likely to be withdrawn from CEXs (for example USDC / USDT / ETH / BUSD) and shouldn't be a considered as airdrop from preasale tha the cost basis for that is 0..
                    // the price will be the current price
                    let price = 0
                    let costBasis = 0

                    purchases.push({
                        "blockTimestamp": row.timeStamp,
                        "quantity": tokIn.amount,
                        "costBasis": costBasis,
                        "costBasisSuggested": tokIn.price * tokIn.amount,
                        "price": price,
                        "hash": row.hash,
                        "priceWithSlippage": price
                    });

                    airdropTxs.push({
                        "blockTimestamp": row.timeStamp,
                        "quantity": tokIn.amount,
                        "costBasis": costBasis,
                        "costBasisSuggested": tokIn.price * tokIn.amount,
                        "price": price,
                        "hash": row.hash,
                        "priceWithSlippage": price,
                        "tokenPriceAtTimeReceived": tokIn.price || 0
                    })
                }
            }
        }


    }

    return [sales, purchases]


    // let tokenTxs = getAllTokenTxs(txList, tokenAddr)
    // let purchases = []
    // let sales = []
    // // order by timestamp
    // tokenTxs = tokenTxs.sort((a, b) => {
    //     if (a.timeStamp < b.timeStamp) {
    //         return -1
    //     } else if (a.timeStamp > b.timeStamp) {
    //         return 1
    //     }
    //     return 0
    // })
    //
    // for (const row of tokenTxs) {
    //     if (row.operation == "Trade") {
    //         // transferIn
    //         if (row.tokenInInfo.addr.toLowerCase() == tokenAddr.toLowerCase()) {
    //             purchases.push({
    //                 "blockTimestamp": row.timeStamp,
    //                 "quantity": row.tokenInAmount,
    //                 "price": row.tokenInPrice,
    //                 "hash": row.hash
    //             })
    //         } else if (row.tokenOutInfo.addr.toLowerCase() == tokenAddr.toLowerCase()) {
    //             sales.push({
    //                 "blockTimestamp": row.timeStamp,
    //                 "quantity": row.tokenOutAmount,
    //                 "price": row.tokenOutPrice,
    //                 "hash": row.hash
    //             })
    //         }
    //     }
    //     // if it's a token transfer in and no token transferred out in exchange. consider it as airdrop, the price is the price the asset is worth.
    //     else if (row.tokensIn.length >= 1 && row.tokensOut.length == 0) {
    //         for (const tokIn of row.tokensIn) {
    //             if (tokIn.tokenInfo.addr == tokenAddr) {
    //                 purchases.push({
    //                     "blockTimestamp": row.timeStamp,
    //                     "quantity": tokIn.amount,
    //                     "price": tokIn.price || 0,
    //                     "hash": row.hash
    //                 })
    //             }
    //         }
    //     }
    //
    // }
    //
    // return [sales, purchases]
    //
    //
}
export const getAllTokenTxs = (txList, tokenAddr) => {
    // for each token in the list we preserve the purchase price, and quantity. if it's an airdrop we will consider the price as 0.
    let tokenTxs = txList.filter(x => x.tokenInInfo?.addr?.toLowerCase() == tokenAddr.toLowerCase() || x.tokenOutInfo?.addr?.toLowerCase() == tokenAddr.toLowerCase()
        || x.tokensIn.map(x => x.tokenInfo?.addr?.toLowerCase()).includes(tokenAddr.toLowerCase()) || x.tokensOut.map(x => x.tokenInfo.addr.toLowerCase()).includes(tokenAddr.toLowerCase()))

    tokenTxs = tokenTxs.sort((a, b) => {
        if (a.timeStamp < b.timeStamp) {
            return -1
        } else if (a.timeStamp > b.timeStamp) {
            return 1
        }
        return 0
    })
    return tokenTxs
}


export const calculatePotentialPNLForToken = (txList, tokenAddr, portfolioData) => {
    let [allTokenSellTxs, allTokensBuyTxs] = getAllTokensSellAndBuyTxs(txList, tokenAddr)
    let tokenData = portfolioData.tokens.filter(x => x.token_address.toLowerCase() == tokenAddr.toLowerCase())[0]
    let mockSellTx = {
        "blockTimestamp": 100000000000000000000000000000,
        "quantity": Number(tokenData.amount_of_tokens),
        "price": Number(tokenData.totalValueInUSD) / Number(tokenData.amount_of_tokens),
        "hash": "mockTx"
    }
    let allSells = allTokenSellTxs.concat(mockSellTx)
    let sells = calculatePNLPerSell(allTokensBuyTxs, allSells)
    return sells[sells.length - 1]
}

export const getYearReports = (txList, startTimestamp, endTimestamp, portfolioData) => {
    let tokenPL = {}
    // get all the profit and loss and sells during this year.
    for (const tx of txList) {
        // if there is a pnl calculation there, the output token is the one with the P&L results.
        if (tx.pnl && tx.timeStamp < endTimestamp && tx.timeStamp > startTimestamp) {
            let [tokenIn, tokenOut] = getPrimaryAssets(tx)
            // token out is the token we calculate the profit & loss for.
            if (tokenOut) {
                let identifier = tokenOut.tokenInfo.addr.toLowerCase()
                let token_symbol = tokenOut.tokenInfo.symbol.toLowerCase()
                let chain = tx.chain
                let logoCMC = tokenOut.tokenInfo.logoCMC
                if (tokenPL[identifier]) {
                    tokenPL[identifier].pnl += Number(tx.pnl.pnl)
                    tokenPL[identifier].costBasis += Number(tx.pnl.costBasis)

                } else {
                    tokenPL[identifier] = {}
                    tokenPL[identifier].logoCMC = logoCMC
                    tokenPL[identifier].chain = chain
                    tokenPL[identifier].token_address = identifier
                    tokenPL[identifier].token_symbol = token_symbol
                    tokenPL[identifier].pnl = Number(tx.pnl.pnl)
                    tokenPL[identifier].costBasis = Number(tx.pnl.costBasis)
                }
            }
        }

    }
    let res = []

    // if the current year is the same as requested year we calculate the potential profit & loss
    let currentYear = new Date().getFullYear()

    // treat as all the tokens are being sold now and calculate the cost basis.
    if (new Date(startTimestamp * 1000).getFullYear() == currentYear) {
        for (const token of getTokens(portfolioData?.tokens || [])) {
            let sellData = calculatePotentialPNLForToken(txList, token.token_address.toLowerCase(), portfolioData)
            let identifier = token.token_address.toLowerCase()

            if (tokenPL[identifier]) {
                // the previous PNL is the realized gains because it doesn't include the last sell which is selling all the current supply.
                tokenPL[identifier].realizedGains = tokenPL[identifier].pnl
                tokenPL[identifier].costBasis += sellData.costBasis
                tokenPL[identifier].pnl += sellData.pnl
                tokenPL[identifier].unrealizedGains = tokenPL[identifier].pnl - tokenPL[identifier].realizedGains
            }
            // if the user didn't sell the token at all
            else {
                tokenPL[identifier] = {
                    "costBasis": sellData.costBasis,
                    "pnl": sellData.pnl,
                    "token_symbol": token.token_symbol,
                    "token_address": token.token_address,
                    "unrealizedGains": sellData.pnl,
                    "realizedGains": 0,
                    "chain": token.chain,
                    "logoCMC": token.logoCMC
                }
            }
        }

    }

    for (const t of Object.keys(tokenPL)) {
        res.push(tokenPL[t])
    }

    return res

}

// data should be in a list format
export function downloadCSVFile(data, fileName) {
    let csvContent = "data:text/csv;charset=utf-8,"
        + data.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}`);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".}
}

export function getPNLCSV(account, year, yfr) {
    let rows = [
        ["Token Name", "Token Address", "Token Url", "Chain", "costBasis", "pnl"],
    ];

    let tokenPNLData = yfr.map(x => [x["token_symbol"], x["token_address"], getAccountLink(x["chain"], x["token_address"]), x["chain"], '"' + prettyPrint(x["costBasis"]) + '"', '"' + prettyPrint(x["pnl"]) + '"'])
    let csvData = [...rows, ...tokenPNLData]
    downloadCSVFile(csvData, `${account}-${year}-report-summary`)

}


export function parseTransactionToHumanReadable(tx) {
    let d = new Date(tx.timeStamp * 1000).getTime()
    // list of the primary token in and out, they in lp for example there will be two.
    let [tokensInPrimary, tokensOutPrimary] = [tx.tokenInPrimary || [], tx.tokenOutPrimary || []]
    let tokensInReadable = ""
    let tokensOutReadable = ""
    let totalInflow = 0
    let totalOutFlow = 0
    for (let tokenIn of tokensInPrimary) {
        tokensInReadable += `${prettyPrint(tokenIn.amount)} ${tokenIn.tokenInfo.symbol}\n`
        totalInflow += tokenIn.usdValueAmount
    }
    for (let tokenOut of tokensOutPrimary) {
        tokensOutReadable += `${prettyPrint(tokenOut.amount)} ${tokenOut.tokenInfo.symbol}\n`
        totalOutFlow += tokenOut.usdValueAmount
    }
    let gasPriceEther = Number(tx.gasPrice || 0) / 1e18
    let gasFeesUsd = Number(tx.historicalNativeTokenPrice || 0) * gasPriceEther * Number(tx.gasUsed || 0)
    let gasFeesEther = gasPriceEther * Number(tx.gasUsed || 0)

    return {
        "tokensIn": tokensInReadable,
        "tokensOut": tokensOutReadable,
        "inflowUsd": prettyPrint(totalInflow) + "$",
        "outflowUsd": prettyPrint(totalOutFlow) + "$",
        "gasFeesEther": prettyPrint(gasFeesEther) + " ETH",
        "gasFeesUsd": prettyPrint(gasFeesUsd) + "$",
        "historicalETHPrice": prettyPrint(tx.historicalNativeTokenPrice) + "$" || 0
    }

}

export function getAllTransactionData(account, txList) {
    let rows = [
        ["Tx Link", "Chain", "Date", "From", "To", "Operation", "Inflow Tokens", "Inflow (USD)", "Outflow Tokens", "Outflow USD Value", "Gas Fees", "Historical ETH Price"],
    ];

    let result = txList.map(x => {
            let parsedTx = parseTransactionToHumanReadable(x)
            return [getTransactionLink(x.chain, x.hash), x.chain, `"${timestampToHumanReadable(x.timeStamp * 1000)}"`, x.from, x.to, x.operation, `"${parsedTx.tokensIn}"`, `"${parsedTx.inflowUsd}"`, `"${parsedTx.tokensOut}"`, `"${parsedTx.outflowUsd}"`, `"${parsedTx.gasFeesEther} (${parsedTx.gasFeesUsd})"`, `"${parsedTx.historicalETHPrice}"`]
        }
    )

    result = [...rows, ...result]

    downloadCSVFile(result, `${account}-transactions`)
}

export function getYearPaidOutGasFees(
    txList,
    currentAccount,
    startTimestamp,
    endTimestamp) {
    // all the transactions that tx.origin is currentAccount
    let txsFromAccount = txList.filter(tx => tx.txOrigin?.toLowerCase() == currentAccount.toLowerCase())
    let totalGasFees = 0
    let totalGasFeesEther = 0
    for (const tx of txsFromAccount) {
        if (tx.timeStamp < endTimestamp && tx.timeStamp > startTimestamp) {
            let gasPriceEther = Number(tx.gasPrice || 0) / 1e18
            totalGasFees += Number(tx.historicalNativeTokenPrice || 0) * gasPriceEther * Number(tx.gasUsed || 0)
            totalGasFeesEther += gasPriceEther * Number(tx.gasUsed || 0)
        }
    }

    return [totalGasFees, totalGasFeesEther]

}


export function getNativeTokenNameByChain(chain) {
    if (chain == "eth") {
        return "ETH"
    } else if (chain == "bsc") {
        return "BNB"

    } else if (chain == "polygon") {
        return "MATIC"

    }
}

export function getOperationType(tx) {
    if (tx.tokensIn?.length > 0 && tx.tokensOut?.length > 0) {
        return "Swap"
    } else if (tx.tokensIn?.length > 0 && tx.tokensOut?.length == 0) {
        return "Receive Token"
    } else if (tx.tokensIn.length > 0 && tx.tokensOut?.length > 0) {
        return "Send Token"
    }

    return "-"


}


export function getAssetDistributionCategoryBased(tokens, uniV2LP, nfts) {
    let uniV3LPs = nfts.filter(x => x.type == "Uni-V3-LP")
    let collectionNFTs = nfts.filter(x => x.type != "Uni-V3-LP")
    let valueInTokens = 0
    let valueInNFTs = 0
    let valueInUniV2 = 0
    let valueInUniV3 = 0
    for (const token of tokens) {
        valueInTokens += token.totalValueInUSD
    }

    for (const nft of collectionNFTs) {
        valueInNFTs += nft.customData?.floorUsdPrice || 0
    }

    for (const uniV2 of uniV2LP) {
        valueInUniV2 += uniV2.usdValueAmount
    }

    for (const uniV3 of uniV3LPs) {
        valueInUniV3 += uniV3.customData?.usdValueAmount || 0
    }

    return {
        "labels": ["Tokens", "LP", "NFTs"],
        "value": [valueInTokens, valueInUniV2 + valueInUniV3, valueInNFTs]
    }


}

export function getAssetDistributionAssetBased(tokens, uniV2LP, nfts) {
    let uniV3LPs = nfts.filter(x => x.type == "Uni-V3-LP")
    let collectionNFTs = nfts.filter(x => x.type != "Uni-V3-LP")

    let tokensToCheck = getTokens(tokens)
    let allAssets = []

    for (const token of tokensToCheck) {
        let assetData = {
            "name": token.token_name,
            "symbol": token.token_symbol,
            "chain": token.chain,
            "usdValue": token.totalValueInUSD,
            "addr": token.token_address
        }
        allAssets.push(assetData)
    }

    for (const nft of collectionNFTs) {
        let assetData = {
            "name": nft.name, "symbol": nft.symbol, "chain": nft.chain, "usdValue": nft.customData?.floorUsdPrice || 0,
            "addr": nft.customData?.collectionAddress
        }
        allAssets.push(assetData)

    }

    for (const uniV2 of uniV2LP) {
        let name = `${uniV2.token0Info.symbol}/${uniV2.token1Info.symbol}`
        let assetData = {
            "name": name, "symbol": name, "chain": uniV2.chain, "usdValue": uniV2.usdValueAmount || 0,
            "addr": uniV2.token_address
        }
        allAssets.push(assetData)
    }

    for (const uniV3 of uniV3LPs) {
        let name = `${uniV3?.customData?.token0?.symbol}/${uniV3?.customData?.token1?.symbol || ""} ${uniV3.customData.feeTier / 10000 || 0}%`
        let assetData = {
            "name": name, "symbol": name, "chain": uniV3.chain, "usdValue": uniV3.customData?.usdValueAmount || 0,
            "addr": uniV3.tokenAddress
        }
        allAssets.push(assetData)
    }
    // get top 10
    let orderd = _.orderBy(allAssets, 'usdValue', 'desc'); // Use Lodash to sort array by 'name'
    let cleanedList = orderd.filter(x => x.usdValue != 0 && x.symbol != null)

    return {
        "labels": cleanedList.map(x => x.symbol).slice(0, 10),
        "value": cleanedList.map(x => x.usdValue).slice(0, 10)
    }


}

export function getAllAccountUSDValue(tokens, uniV2LP, nfts) {
    let uniV3LPs = nfts.filter(x => x.type == "Uni-V3-LP")
    let collectionNFTs = nfts.filter(x => x.type != "Uni-V3-LP")

    let tokensToCheck = getTokens(tokens)
    let allAssets = []

    for (const token of tokensToCheck) {
        let assetData = {
            "name": token.token_name,
            "symbol": token.token_symbol,
            "chain": token.chain,
            "usdValue": token.totalValueInUSD,
            "addr": token.token_address
        }
        allAssets.push(assetData)
    }

    for (const nft of collectionNFTs) {
        let assetData = {
            "name": nft.name, "symbol": nft.symbol, "chain": nft.chain, "usdValue": nft.customData?.floorUsdPrice || 0,
            "addr": nft.customData?.collectionAddress
        }
        allAssets.push(assetData)

    }

    for (const uniV2 of uniV2LP) {
        let name = `${uniV2.token0Info.symbol}/${uniV2.token1Info.symbol}`
        let assetData = {
            "name": name, "symbol": name, "chain": uniV2.chain, "usdValue": uniV2.usdValueAmount || 0,
            "addr": uniV2.token_address
        }
        allAssets.push(assetData)
    }

    for (const uniV3 of uniV3LPs) {
        let name = `${uniV3?.customData?.token0?.symbol}/${uniV3?.customData?.token1?.symbol || ""} ${uniV3.customData.feeTier / 10000 || 0}%`
        let assetData = {
            "name": name, "symbol": name, "chain": uniV3.chain, "usdValue": uniV3.customData?.usdValueAmount || 0,
            "addr": uniV3.tokenAddress
        }
        allAssets.push(assetData)
    }

    let totalVal = _.sumBy(allAssets, "usdValue")

    return totalVal


}

export function getRemoveADDLQTokens(params) {
    let lpTokenIn = _.filter(params.tokensIn, {"isLPToken": true})
    let lpTokenOut = _.filter(params.tokensOut, {"isLPToken": true})

    if (lpTokenIn.length == 1) {
        let tokensOutUnique = _.uniqBy(params.tokensOut, "tokenInfo.addr")
        // get the once with the most usd value
        let sortedTokensIn = _.sortBy(tokensOutUnique, "usdValueAmount")
        sortedTokensIn = sortedTokensIn.reverse()
        let token0 = sortedTokensIn[0]
        let token1 = sortedTokensIn[1]

        if (token0 && token1) {
            return [token0, token1, 0]
        }
    }

    if (lpTokenOut.length == 1) {
        let tokensOutUnique = _.uniqBy(params.tokensIn, "tokenInfo.addr")
        // get the once with the most usd value
        let sortedTokensIn = _.sortBy(tokensOutUnique, "usdValueAmount")
        sortedTokensIn = sortedTokensIn.reverse()
        let token0 = sortedTokensIn[0]
        let token1 = sortedTokensIn[1]

        if (token0 && token1) {
            return [token0, token1, 1]
        }

    }
    return [undefined, undefined]


}


export function calculateTransactionInflowAndOutflowUSDValue(tx) {
    let totalUsdValue = 0
    for (let tokenIn of tx.tokensIn || []) {
        totalUsdValue += tokenIn.usdValueAmount
    }

    for (let tokenOut of tx.tokensOut || []) {
        totalUsdValue += tokenOut.usdValueAmount
    }

    return totalUsdValue
}


function getActiveAccountFromPath(path) {
    const urlString = path;

// Define a regex pattern to match the address
    const regexPattern = /\/accounting\/([^/]+)/;

// Use the regex pattern to extract the address
    const match = urlString.match(regexPattern);

    if (match) {
        const address = match[1]; // Group 1 captures the address
        return address
    } else {
        return "";
    }
}


export {prettyPrint, compactNumber, getPNLText, parseMethod, getActiveAccountFromPath}