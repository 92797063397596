/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setFixedNavbar,
  setSidenavColor,
} from "context";
import {Grid} from "@nextui-org/react";
import {Chip} from "@mui/material";
import {prettyPrintAddress, removeElementByIndex} from "../../common/utils";
import { Dialog } from 'primereact/dialog';
import { Modal, Input, Row, Checkbox, Button, Text } from "@nextui-org/react";

function AddNewDepositAddress({visible, closeHandler}) {
  const [address, setAddress] = useState("")
  const [chain, setChain] = useState("")
  const [label, setLabel] = useState("")

  return (
      <Modal
          closeButton
          aria-labelledby="modal-title"
          open={visible}
          onClose={()=>{
              closeHandler({
                "address": "",
                "chain": "",
                "label": ""
              })
              setAddress("")
              setChain("")
              setLabel("")

          }}
      >
        <Modal.Header>
          <Text b id="modal-title" size={18}>
            Add new deposit address

          </Text>
        </Modal.Header>
        <Modal.Body>
          <Input
              onChange={(e)=>{
                setAddress(e.target.value)
              }}
              clearable
              bordered
              fullWidth
              color="primary"
              size="lg"
              placeholder="Address"
              // contentLeft={<Mail fill="currentColor" />}
          />
          <Input
              onChange={(e)=>{
                setChain(e.target.value)
              }}
              clearable
              bordered
              fullWidth
              color="primary"
              size="lg"
              placeholder="Chain"
              // contentLeft={<Password fill="currentColor" />}
          />
          <Input
              onChange={(e)=>{
                setLabel(e.target.value)
              }}
              clearable
              bordered
              fullWidth
              color="primary"
              size="lg"
              placeholder="Label"
              // contentLeft={<Password fill="currentColor" />}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={()=>{
            {
              closeHandler({
                "address": "",
                "chain": "",
                "label": ""
              })
              setAddress("")
              setLabel("")
              setChain("")
            }
          }}>
            Close
          </Button>
          <Button auto onPress={()=>{
            closeHandler({
              "address": address,
              "chain": chain,
              "label": label
            })
            setAddress("")
            setLabel("")
            setChain("")
          }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
  )
}


function Configurator() {
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, transparentSidenav, fixedNavbar, sidenavColor } = controller;
  const [disabled, setDisabled] = useState(false);
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleTransparentSidenav = () => setTransparentSidenav(dispatch, true);
  const handleWhiteSidenav = () => setTransparentSidenav(dispatch, false);
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  const [depositAddressDialogVisable, setAddDepositAddressDialogVisable] = useState(false)

  const [depositAddresses, setDepositAddresses] = useState([])
  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    boxShadows: { buttonBoxShadow },
  }) => ({
    height: pxToRem(42),
    boxShadow: buttonBoxShadow.main,

    "&:hover, &:focus": {
      opacity: 1,
    },
  });
  useEffect(()=>{
  }, [depositAddresses])

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} width={"600px"}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox>
          <SoftTypography variant="h5">Configuration</SoftTypography>
          <SoftTypography variant="body2" color="text">
            Configure your accounts
          </SoftTypography>
        </SoftBox>

        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </SoftBox>
      <AddNewDepositAddress visible={depositAddressDialogVisable} closeHandler={(acc)=>{
        if (acc?.address != "") {
          setDepositAddresses([...depositAddresses, acc])
        }
        setAddDepositAddressDialogVisable(false)
      }} ></AddNewDepositAddress>
      <Divider />

      <SoftBox pt={1.25} pb={3} px={3}>
        {/*<SoftBox>*/}
        {/*  /!*<SoftTypography variant="h6">Sidenav Colors</SoftTypography>*!/*/}

        {/*  /!*<SoftBox mb={0.5}>*!/*/}
        {/*  /!*  {sidenavColors.map((color) => (*!/*/}
        {/*  /!*    <IconButton*!/*/}
        {/*  /!*      key={color}*!/*/}
        {/*  /!*      sx={({ borders: { borderWidth }, palette: { white, dark }, transitions }) => ({*!/*/}
        {/*  /!*        width: "24px",*!/*/}
        {/*  /!*        height: "24px",*!/*/}
        {/*  /!*        padding: 0,*!/*/}
        {/*  /!*        border: `${borderWidth[1]} solid ${white.main}`,*!/*/}
        {/*  /!*        borderColor: sidenavColor === color && dark.main,*!/*/}
        {/*  /!*        transition: transitions.create("border-color", {*!/*/}
        {/*  /!*          easing: transitions.easing.sharp,*!/*/}
        {/*  /!*          duration: transitions.duration.shorter,*!/*/}
        {/*  /!*        }),*!/*/}
        {/*  /!*        backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>*!/*/}
        {/*  /!*          linearGradient(gradients[color].main, gradients[color].state),*!/*/}

        {/*  /!*        "&:not(:last-child)": {*!/*/}
        {/*  /!*          mr: 1,*!/*/}
        {/*  /!*        },*!/*/}

        {/*  /!*        "&:hover, &:focus, &:active": {*!/*/}
        {/*  /!*          borderColor: dark.main,*!/*/}
        {/*  /!*        },*!/*/}
        {/*  /!*      })}*!/*/}
        {/*  /!*      onClick={() => setSidenavColor(dispatch, color)}*!/*/}
        {/*  /!*    />*!/*/}
        {/*  /!*  ))}*!/*/}
        {/*  /!*</SoftBox>*!/*/}
        {/*</SoftBox>*/}
        {/*<SoftBox mt={3} lineHeight={1}>*/}
        {/*  <SoftTypography variant="h6">Add wallet</SoftTypography>*/}
        {/*  <SoftTypography variant="button" color="text" fontWeight="regular">*/}
        {/*    all your wallets*/}
        {/*  </SoftTypography>*/}
        {/*  <Grid.Container pt={1} px={3}>*/}
        {/*    <Grid>*/}
        {/*      <Chip  label={prettyPrintAddress("0xdsadsadasdsadsadasdsad") + " (Binance)"} variant="outlined" onDelete={()=>{*/}
        {/*        console.log("xxx")*/}
        {/*      }}></Chip>*/}
        {/*    </Grid>*/}
        {/*    <Grid>*/}
        {/*      <Chip  label={prettyPrintAddress("0xdsadsadasdsadsadasdsad") + " (Binance)"} variant="outlined" onDelete={()=>{*/}
        {/*        console.log("xxx")*/}
        {/*      }}></Chip>*/}
        {/*    </Grid>*/}
        {/*    <Grid>*/}
        {/*      <Chip  label={prettyPrintAddress("0xdsadsadasdsadsadasdsad") + " (Binance)"} variant="outlined" onDelete={()=>{*/}
        {/*        console.log("xxx")*/}
        {/*      }}></Chip>*/}
        {/*    </Grid>*/}
        {/*    <Grid>*/}
        {/*      <Chip  label={prettyPrintAddress("0xdsadsadasdsadsadasdsad") + " (Binance)"} variant="outlined" onDelete={()=>{*/}
        {/*        console.log("xxx")*/}
        {/*      }}></Chip>*/}
        {/*    </Grid>*/}
        {/*    <Grid>*/}
        {/*      <Chip  label={prettyPrintAddress("0xdsadsadasdsadsadasdsad") + " (Binance)"} variant="outlined" onDelete={()=>{*/}
        {/*        console.log("xxx")*/}
        {/*      }}></Chip>*/}
        {/*    </Grid>*/}
        {/*    <Grid>*/}
        {/*      <Chip  label={prettyPrintAddress("0xdsadsadasdsadsadasdsad") + " (Binance)"} variant="outlined" onDelete={()=>{*/}
        {/*        console.log("xxx")*/}
        {/*      }}></Chip>*/}
        {/*    </Grid>*/}
        {/*    <Grid>*/}
        {/*      <Chip  label={prettyPrintAddress("0xdsadsadasdsadsadasdsad") + " (Binance)"} variant="outlined" onDelete={()=>{*/}
        {/*        console.log("xxx")*/}
        {/*      }}></Chip>*/}
        {/*    </Grid>*/}
        {/*  </Grid.Container>*/}

        {/*  <SoftBox*/}
        {/*      sx={{*/}
        {/*        display: "flex",*/}
        {/*        mt: 2,*/}
        {/*      }}*/}
        {/*  >*/}
        {/*    <SoftButton*/}
        {/*        color="info"*/}
        {/*        variant={transparentSidenav ? "gradient" : "outlined"}*/}
        {/*        onClick={handleTransparentSidenav}*/}
        {/*        disabled={disabled}*/}
        {/*        fullWidth*/}
        {/*        sx={{*/}
        {/*          mr: 1,*/}
        {/*          ...sidenavTypeButtonsStyles,*/}
        {/*        }}*/}
        {/*    >*/}
        {/*      Add New Wallet*/}
        {/*    </SoftButton>*/}
        {/*  </SoftBox>*/}
        {/*</SoftBox>*/}

        <SoftBox mt={3} lineHeight={1}>
          <SoftTypography variant="h6">Add Deposit Addresses</SoftTypography>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Add all the centralized exchanges deposit addresses
          </SoftTypography>
          <Grid.Container pt={1} px={3}>
              {depositAddresses.map((item, index)=>(
                <Grid key={index}>
                    <Chip label={prettyPrintAddress(item.address) + " " + (item.label? `(${item.label})`: "")} variant="outlined" onDelete={()=>{
                      let newDepositAddr = depositAddresses.filter(x=>x.address.toLowerCase() != item.address.toLowerCase())
                      setDepositAddresses(newDepositAddr)
                    }}></Chip>
                  </Grid>

              ))}
          </Grid.Container>

          <SoftBox
            sx={{
              display: "flex",
              mt: 2,
            }}
          >
            <SoftButton
              color="info"
              variant={transparentSidenav ? "gradient" : "outlined"}
              onClick={()=>{
                setAddDepositAddressDialogVisable(true)
              }}
              disabled={disabled}
              fullWidth
              sx={{
                mr: 1,
                ...sidenavTypeButtonsStyles,
              }}
            >
              Add New Deposit Address
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={3} mb={2} lineHeight={1}>
          <SoftTypography variant="h6">Show Cost Basis</SoftTypography>

          <Switch checked={fixedNavbar} onChange={()=>{

          }} />
        </SoftBox>

        <Divider />

        <SoftBox mt={3} mb={2}>
          <SoftBox mb={2}>
            <SoftButton
              component={Link}
              href="https://www.creative-tim.com/product/soft-ui-dashboard-react"
              target="_blank"
              rel="noreferrer"
              color="dark"
              variant="gradient"
              fullWidth
            >
              Download CSV
            </SoftButton>
          </SoftBox>
          <SoftButton
            component={Link}
            href="https://www.creative-tim.com/learning-lab/react/quick-start/soft-ui-dashboard/"
            target="_blank"
            rel="noreferrer"
            color="dark"
            variant="outlined"
            fullWidth
          >
            view documentation
          </SoftButton>
        </SoftBox>
        <SoftBox display="flex" justifyContent="center">
          {/*<a*/}
          {/*  className="github-button"*/}
          {/*  href="https://github.com/creativetimofficial/soft-ui-dashboard-react"*/}
          {/*  data-icon="octicon-star"*/}
          {/*  data-size="large"*/}
          {/*  data-show-count="true"*/}
          {/*  aria-label="Star creativetimofficial/soft-ui-dashboard-react on GitHub"*/}
          {/*>*/}
          {/*  Star*/}
          {/*</a>*/}
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftBox mb={0.5}>
            <SoftTypography variant="h6">Contact Us</SoftTypography>
          </SoftBox>

          <SoftBox display="flex" justifyContent="center">
            <SoftBox mr={1.5}>
              <SoftButton
                component={Link}
                href="//twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20React%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23react%23mui&url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard-react"
                target="_blank"
                rel="noreferrer"
                color="dark"
              >
                <TwitterIcon />
                &nbsp; Tweet
              </SoftButton>
            </SoftBox>
            <SoftButton
              component={Link}
              href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/soft-ui-dashboard-react"
              target="_blank"
              rel="noreferrer"
              color="dark"
            >
              <FacebookIcon />
              &nbsp; Share
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
