/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect, useRef} from "react";

// react-router components
import {useLocation, Link, useNavigate} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "./styles";

// Soft UI Dashboard React context
import {
    useSoftUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import {ACCOUNT_TO_DATA} from "../../../data/accountingData";
import {getAccountPortfolioValue, getActiveAccountFromPath, getTxIcon, prettyPrintAddress} from "../../../common/utils";
import {ArrowCircleDown, ArrowCircleUpRounded, CodeOutlined, Error, MultipleStopOutlined} from "@mui/icons-material";
import * as React from "react";
import {Avatar} from "primereact/avatar";
import {Menu as MenuPrimeReact} from "primereact/menu"
import {Toast} from 'primereact/toast';
import "./styles.css"
import {useRecoilState} from "recoil";
import {accountsState} from "../../../data/accountData";
import {Input} from "@nextui-org/react";
import {AutoComplete} from "primereact/autocomplete";

function Account(accountAddress, name) {
    return (
        <button onClick={(e) => {
            menuRight.current.toggle(e)
        }}
                className='w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround'>
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
                    className="mr-2" shape="circle"/>
            <div className="flex flex-column align">
                <span className="font-bold">{accountAddress}</span>
                <span className="text-sm">{name}</span>
            </div>
        </button>
    )

}

function AccountSelection({connectedAccounts}) {
    // const toast = useRef()
    const menuRight = useRef(null);
    const location = useLocation();
    const {pathname} = location;
    let accounts = connectedAccounts || [{"address": "0xsdsddsad", "label": "Adi"}, {
        "address": "0xsdsddsad",
        "label": "Adi"
    }]
    const currentAccount = getActiveAccountFromPath(pathname)

    let items = [
        {label: 'Add Account', icon: 'pi pi-fw pi-user-plus'},
        // {label: 'Overview', icon: 'pi pi-fw pi-user-plus'},
        // { label: 'Settings', icon: 'pi pi-fw pi-cog' },
        {separator: true},
        // {
        //     // command: () => { toast.current.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 }); },
        //     template: (item, options) => {
        //         return (
        //             <Link to={"https://gooogle.co"} isExternal={true} target="_blank">
        //             <a href={"/accounting/"} onClick={(e) => {
        //                 console.log("on click")
        //
        //                 menuRight.current.toggle(e)
        //             }}
        //                     className='w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround'>
        //                 <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
        //                         className="mr-2" shape="circle"/>
        //                 <div className="flex flex-column align">
        //                     <span className="font-bold">Amy Elsner</span>
        //                     <span className="text-sm">Agent</span>
        //                 </div>
        //             </a>
        //             </Link>
        //         )
        //     }
        // },

    ];

    for (const key of Object.keys(accounts)) {
        items.push(
            {
                template: (item, options) => {
                    return (
                        <Link to={`/accounting/${key.toLowerCase()}/explorer`}>
                            <button onClick={(e) => {
                                menuRight.current.toggle(e)
                            }}
                                    className='w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround'>
                                <Avatar icon={"pi pi-user"}
                                        className="mr-2" shape="circle"/>
                                <div className="flex flex-column align">
                                    <span className="font-bold">{prettyPrintAddress(accounts[key].address)}</span>
                                    <span className="text-sm">{accounts[key].label}</span>
                                </div>
                            </button>
                        </Link>
                    )
                }
            }
        )
    }


    return (
        <div className="card flex justify-content-center" style={{width: "10em"}}>
            {/*<Toast ref={toast} />*/}
            <MenuPrimeReact model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right"
                            className={"accountSelectionMenu"}/>
            {/*<MenuPrimeReact model={items} className={"popup_menu_right"}/>*/}
            <button onClick={(e) => {
                menuRight.current.toggle(e)
            }} className='w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround'>
                <Avatar icon={"pi pi-fw pi-user"} className="mr-2"
                        shape="circle"/>
                <div className="flex flex-column align">
                    <span className="font-bold">{prettyPrintAddress(currentAccount)}</span>
                    <span className="text-sm">{accounts[currentAccount.toLowerCase()]?.label || ""}</span>
                </div>
                <Icon>expand_more</Icon>
            </button>


        </div>
    )
}


function AccountNavBar({absolute, light, isMini}) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);
    const [accounts, setAccounts] = useRecoilState(accountsState);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    const [value, setValue] = useState('');
    const [items, setItems] = useState(accounts);
    const navigate = useNavigate();

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    useEffect(()=>{
        setItems(accounts)
    }, [accounts])

    const parseTransaction = (tx, accounts) => {
        return {
            "title": [tx.operation, "From: " + prettyPrintAddress(tx.from), "To: " + prettyPrintAddress(tx.to)],
            "date": "13 minutes ago",
            "icon": getTxIcon(tx)
        }
    }

    const search = (event) => {
        let filteredAccounts = Object.values(accounts).filter(x=>x.address.includes(event.query))
        setItems(filteredAccounts);
    }


    const itemTemplate = (item) => {
        return (
            <div className="flex align-items-center">
                <div>{item.label + "-" + getAccountPortfolioValue(item.portfolio)}</div>
            </div>
        );
    };


    // Render the notifications menu
    const renderMenu = (transactions, accounts) => {


        const txOrdered = transactions.sort((a, b) => {
            if (a.timeStamp < b.timeStamp) {
                return 1
            } else if (a.timeStamp > b.timeStamp) {
                return -1
            }
            return 0
        })

        const notificationTx = txOrdered.slice(0, 5)

        return (<Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{mt: 2}}
        >

            {notificationTx.map((item, index) => {
                let parsedNotification = parseTransaction(item, [])
                return (<NotificationItem
                    image={parsedNotification["icon"]}
                    title={parsedNotification["title"]}
                    date={parsedNotification["date"]}
                    onClick={handleCloseMenu}
                />)
            })}
            {/*<NotificationItem*/}
            {/*    image={<img src={team2} alt="person"/>}*/}
            {/*    title={["Receive ETH", "0xf4...23bd", "0xf4...23bd"]}*/}
            {/*    date="13 minutes ago"*/}
            {/*    onClick={handleCloseMenu}*/}
            {/*/>*/}
            {/*<NotificationItem*/}
            {/*    image={<img src={team2} alt="person"/>}*/}
            {/*    title={["Send ETH", "0xf4...23bd", "0xf4...23bd"]}*/}
            {/*    date="13 minutes ago"*/}
            {/*    onClick={handleCloseMenu}*/}
            {/*/>*/}
            {/*<NotificationItem*/}
            {/*    image={<img src={team2} alt="person"/>}*/}
            {/*    title={["Trade", "From: 0xf4...23bd", "To: 0xf4...23bd"]}*/}
            {/*    date="13 minutes ago"*/}
            {/*    onClick={handleCloseMenu}*/}
            {/*/>*/}
            {/*<NotificationItem*/}
            {/*    image={<img src={team2} alt="person"/>}*/}
            {/*    title={["Trade", "0xf4...23bd"]}*/}
            {/*    date="13 minutes ago"*/}
            {/*    onClick={handleCloseMenu}*/}
            {/*/>*/}
            {/*<NotificationItem*/}
            {/*  image={<img src={logoSpotify} alt="person" />}*/}
            {/*  title={["New album", "by Travis Scott"]}*/}
            {/*  date="1 day"*/}
            {/*  onClick={handleCloseMenu}*/}
            {/*/>*/}
            {/*<NotificationItem*/}
            {/*  color="secondary"*/}
            {/*  image={*/}
            {/*    <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>*/}
            {/*      payment*/}
            {/*    </Icon>*/}
            {/*  }*/}
            {/*  title={["", "Payment successfully completed"]}*/}
            {/*  date="2 days"*/}
            {/*  onClick={handleCloseMenu}*/}
            {/*/>*/}

            {/*<NotificationItem*/}
            {/*    image={<img src={team2} alt="person" />}*/}
            {/*    title={["New message", "from Laur"]}*/}
            {/*    date="13 minutes ago"*/}
            {/*    onClick={handleCloseMenu}*/}
            {/*/>*/}
            {/*<NotificationItem*/}
            {/*    image={<img src={team2} alt="person" />}*/}
            {/*    title={["New message", "from Laur"]}*/}
            {/*    date="13 minutes ago"*/}
            {/*    onClick={handleCloseMenu}*/}
            {/*/>*/}
            {/*<NotificationItem*/}
            {/*    image={<img src={team2} alt="person" />}*/}
            {/*    title={["New message", "from Laur"]}*/}
            {/*    date="13 minutes ago"*/}
            {/*    onClick={handleCloseMenu}*/}
            {/*/>*/}
            {/*<NotificationItem*/}
            {/*    image={<img src={team2} alt="person" />}*/}
            {/*    title={["New message", "from Laur"]}*/}
            {/*    date="13 minutes ago"*/}
            {/*    onClick={handleCloseMenu}*/}
            {/*/>*/}
        </Menu>)
    };
    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            style={{zIndex: "1000"}}
            sx={(theme) => navbar(theme, {transparentNavbar, absolute, light})}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <SoftBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
                    <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>
                </SoftBox>

                {isMini ? null : (

                    <SoftBox sx={(theme) => navbarRow(theme, {isMini})}>
                        {/*<SoftBox pr={1}>*/}
                        {/*    <AccountSelection connectedAccounts={accounts}></AccountSelection>*/}
                        {/*</SoftBox>*/}
                        <SoftBox>
                            <AutoComplete
                                itemTemplate={itemTemplate}
                                field="label"
                                placeholder="Search Address.." className={"searchAccount"} value={value} completeMethod={search}
                                          suggestions={Object.values(items)} onChange={(e) => {
                                              setValue(e.value)}

                            }
                                onSelect={(e) => {
                                    setValue("")
                                    navigate(`/accounting/${e.value.address.toLowerCase()}/explorer`)
                                }}

                            >

                            </AutoComplete>
                        </SoftBox>

                        {/*<SoftBox pr={1}>*/}
                        {/*  <SoftInput*/}
                        {/*    placeholder="Search Transaction..."*/}
                        {/*    icon={{ component: "search", direction: "left" }}*/}
                        {/*  />*/}
                        {/*</SoftBox>*/}

                        <SoftBox color={light ? "white" : "inherit"}>
                            <Link to="/authentication/sign-in">
                                {/*<IconButton sx={navbarIconButton} size="small">*/}
                                {/*  <Icon*/}
                                {/*    sx={({ palette: { dark, white } }) => ({*/}
                                {/*      color: light ? white.main : dark.main,*/}
                                {/*    })}*/}
                                {/*  >*/}
                                {/*    account_circle*/}
                                {/*  </Icon>*/}
                                {/*  <SoftTypography*/}
                                {/*    variant="button"*/}
                                {/*    fontWeight="medium"*/}
                                {/*    color={light ? "white" : "dark"}*/}
                                {/*  >*/}
                                {/*    Sign in*/}
                                {/*  </SoftTypography>*/}
                                {/*</IconButton>*/}
                            </Link>
                            <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>

                            <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarIconButton}
                                onClick={handleConfiguratorOpen}
                            >
                                <Icon>settings</Icon>
                            </IconButton>
                            <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenMenu}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                            </IconButton>
                            {renderMenu(ACCOUNT_TO_DATA["0xf468c2bf527904a06020e4f4c6e3b7781f8023bd"].concat(ACCOUNT_TO_DATA["0x05e793ce0c6027323ac150f6d45c2344d28b6019"]))}
                        </SoftBox>
                    </SoftBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
AccountNavBar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
AccountNavBar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default AccountNavBar;
