import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {RenderCollectionNFT, RenderUniV3LPNFT} from "../common";
import {getNativeTokenNameByChain, prettyPrint} from "../../common/utils";
import * as React from "react";
// import 'path'
import * as path from 'path-browserify'


// let data from the server:
// {
//     "tokenId": "2183",
//     "tokenAddress": "0x8049bc3b43e9a16293c85d5c189ef73fc3513209",
//     "contractType": "ERC721",
//     "mintedBlockNumber": "16848874",
//     "tokenUri": "https://ipfs.moralis.io:2053/ipfs/Qmei6esdZ7xpeuWnjBbmcuq7qyvnrn2wPz48Yi2rMbJrc8/2183.json",
//     "name": "tehBag Genesis",
//     "symbol": "BAGS",
//     "metadata": {
//     "name": "2183",
//         "image": "ipfs://QmNXDdChtVZ7aH8XAmapkKmVq9aArBtiPPasUvK7oafAWA/2183.jpg",
//         "external_link": null
// },
//     "amount": "1",
//     "possibleSpam": false,
//     "customData": {
//     "collectionAddress": "0x8049bc3b43e9a16293c85d5c189ef73fc3513209",
//         "name": "tehBag Genesis",
//         "floorUsdPrice": 23.75609,
//         "floorNativePrice": 0.0123,
//         "socials": {
//         "discord": null,
//             "website": "http://tehbag.com",
//             "twitterUsername": "teh_bag",
//             "openseaVerificationStatus": "approved"
//     }
// },
//     "type": "Collection",
//     "chain": "eth"
// }
export function NFTPosition({nftData}){
    let headerStyle = {background:"none", fontFamily:"Inter, sans-serif", "color": "#0e0e52"}
    let bodyStyle = {fontFamily: "Roboto", fontWeight:"600", color:"black"}
    let nftPositions = [...nftData].filter(x=>x.type == "Collection" && x.customData != undefined && x.customData.floorPrice != -1 && x.customData.floorNativePrice != -1)
    return (
        <DataTable
            size={"small"}
            sortField="customData.floorNativePrice"
            sortOrder={-1}
            editMode="row"

            // expandedRows={expandedRows}
            // onRowToggle={(e) => setExpandedRows(e.data)}
            paginator
            rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            value={nftPositions}
            header={<h1 style={{fontSize: "1.5rem", fontFamily:"Poppins", marginTop:"15px"}}>NFTs</h1>}
            dataKey="hash" className={"allTransactionsTable"}>

            <Column field={"tokenId"}
                     header={"Asset"}
                     headerStyle={headerStyle}
                     bodyStyle={bodyStyle}

                     body={(params)=>{

                         let baseIPFSUrl = "https://ipfs.io/ipfs"
                         let imgUrl = undefined
                         if (params.metadata.image) {
                             let name = params.metadata.image.split("//")[1]
                             imgUrl = baseIPFSUrl + "/" + name
                         }

                         return RenderCollectionNFT(imgUrl, params.name, params.tokenId, params.chain, ()=>{
                         },)}}
                     filter
                     filterMenuStyle={{ width: '14rem', fontSize:"10px" }}


            ></Column>

            <Column  field={"customData.floorNativePrice"}
                     header={"Usd Price"}
                     headerStyle={headerStyle}
                     bodyStyle={bodyStyle}
                     body={(params)=>{
                         return prettyPrint(params.customData.floorNativePrice) + " " + getNativeTokenNameByChain(params.chain) +  " (" + prettyPrint(params.customData.floorUsdPrice) + "$" + ")"
                     }}
                     sortable={true}

            ></Column>


            {/*<Column  field={"totalValueInUSD"} header={"USD value"} headerStyle={headerStyle} bodyStyle={bodyStyle} sortable={true} body={(params)=>{*/}
            {/*    return prettyPrint(Number(params.totalValueInUSD)) + "$"*/}
            {/*}} ></Column>*/}
            {/*<Column  field={"amount_of_tokens"} header={"Amount"} headerStyle={headerStyle} bodyStyle={bodyStyle} sortable={true} body={(params)=>{*/}
            {/*    return prettyPrint(Number(params.amount_of_tokens)) + " " + params.token_symbol*/}
            {/*}} ></Column>*/}
            {/*<Column  field={"currentPrice"} header={"Price"} headerStyle={headerStyle} bodyStyle={bodyStyle} sortable={true} body={(params)=>{*/}
            {/*    return prettyPrint(Number(params.currentPrice)) + "$"*/}
            {/*}} ></Column>*/}
            {/*<Column  field={"totalValueInUSD"} header={"Portfolio %"} headerStyle={headerStyle} bodyStyle={bodyStyle} sortable={true} body={(params)=>{*/}
            {/*    return  prettyPrint(Number(params.totalValueInUSD / calculateAccountUSDValue(accountPortfolio?.tokens || [])) * 100) + "%"*/}
            {/*}} ></Column>*/}



        </DataTable>)
}