import BasicLayout from "../../layouts/authentication/components/BasicLayout";
import curved6 from "../../assets/images/curved-images/curved14.jpg";

export default function ErrorPage(){
    return (
        <BasicLayout
            title="Error 404"
            description="Can't find the page you are looking for."
            image={curved6}
            navBar={false}
        >



        </BasicLayout>
    )

}