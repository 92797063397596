import Chart from "react-apexcharts";

const PieChart = (props) => {
  return (
    <Chart
      options={props.options}
      type="pie"
      width="400"
      height="400"
      series={props.series}
    />
  );
};

export default PieChart;
