/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import AccountNavBar from "examples/Navbars/AccountNavBar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
// import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import Accounts from "./components/Accounts";
import {AddNewAccount} from "./components/AddAccountDialog";
import {mockLabeledAccounts} from "./components/Accounts/data";
import {useEffect, useState} from "react";
// import OrdersOverview from "layouts/dashboard/components/OrderOverview";
import {useRecoilState} from "recoil";
import {accountsState} from "../../data/accountData";
import {
    calculateAccountUSDValue,
    getAllAccountsUSDBalance,
    getListOfAccounts,
    mergeTokenData,
    prettyPrint, prettyPrintAddress
} from "../../common/utils";
import {AccountPortfolio} from "../../components/Portfolio";
import {getAccountPortfolioFromBackend} from "../../services/api";
import {useParams} from "react-router-dom";
// import OverviewSideBar from "../../components/SideBar/OverviewSideBar";

function Dashboard({isAccount}) {
    const {size} = typography;
    const {chart, items} = reportsBarChartData;
    // const [accounts, setAccounts] = useState(mockLabeledAccounts())

    const [addAccountDialogOpen, setAddAccountDialogOpen] = useState(false)
    const [accounts, setAccounts] = useRecoilState(accountsState);


    useEffect(() => {
        for (const account of Object.keys(accounts)) {
            if (accounts[account.toLowerCase()]?.lastSyncedTime == 0) {
                getAccountPortfolioFromBackend(account.toLowerCase()).then(data =>{
                    let newAccountData = {
                        [account.toLowerCase()]: {
                            "txList": [],
                            "portfolio": data.portfolioData,
                            "nfts": data.nftData,
                            "uniV2LPTokens": data.uniV2LP,
                            "stakingPositions": data.stakingPositionData,
                            "label": prettyPrintAddress(account.toLowerCase()),
                            "address": account.toLowerCase(),
                            "isLoading": false,
                            "lastSyncedTime": 0

                        }
                    }
                    // update accounts..
                    setAccounts(prevData => Object.assign({}, prevData, newAccountData))
                })
            }
        }

    }, [])

    useEffect(()=>{
    }, [accounts])



    return (
        <DashboardLayout>
            {/*<OverviewSideBar/>*/}
            <AddNewAccount visible={addAccountDialogOpen} closeHandler={(acc) => {
                if (acc == undefined) {
                    setAddAccountDialogOpen(false)
                } else {
                    let newAccounts = Object.assign({}, accounts, {
                        [acc.account.toLowerCase()]: {
                            label: acc.label || prettyPrintAddress(acc.account),
                            account: acc.account
                        }
                    })
                    setAccounts(newAccounts)
                }
                setAddAccountDialogOpen(false)
            }}></AddNewAccount>

            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} xl={6}>
                            <MiniStatisticsCard
                                title={{text: "Total Networth"}}
                                count={prettyPrint(getAllAccountsUSDBalance(accounts))}
                                percentage={{color: "success", text: "+10%"}}
                                icon={{color: "info", component: "paid"}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={6}>
                            <MiniStatisticsCard
                                title={{text: "Tracked Accounts"}}
                                count={Object.keys(accounts).length}
                                // percentage={{ color: "success", text: "+3%" }}
                                icon={{color: "info", component: "account"}}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                {!isAccount && <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <AccountPortfolio accountTokens={mergeTokenData(accounts)} onClickChart={(tokenAddress) => {
                                setToken(tokenAddress)
                                setIsChartOpen(true)
                            }}>
                            </AccountPortfolio>
                        </Grid>
                    </Grid>
                </SoftBox>}

            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default Dashboard;
