/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect, useMemo} from "react";

// react-router components
import {Routes, Route, Navigate, useLocation, useNavigate, HashRouter} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard React routes
import routes from "routes";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
// import 'primeicons/primeicons.css';                                 // icons
import 'primeflex/primeflex.css';                                   // css utility
import {combineReducers, createStore} from 'redux';
import "./index.css"
import '@fortawesome/fontawesome-svg-core/styles.css'

// Soft UI Dashboard React contexts
import {useSoftUIController, setMiniSidenav, setOpenConfigurator} from "context";

// Images
import brand from "assets/images/defilo_beta_4.svg";
import AccountSideBar from "./components/AccountSideBar";
import Shop from "./examples/Icons/Shop";
import Dashboard from "./layouts/dashboard";
import {getActiveAccountFromPath, prettyPrintAddress} from "./common/utils";
import {Table} from "@mui/material";
import Tables from "./layouts/tables";
import Office from "./examples/Icons/Office";
import {NextUIProvider} from "@nextui-org/react";
import {MOCK_DATA_2, MOCK_DATA_A16Z} from "./data/accountingData";
import {AddNewAccount} from "./layouts/dashboard/components/AddAccountDialog";
import {RecoilRoot, useRecoilState} from "recoil";
import {accountsState} from "./data/accountData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faImage, faReceipt} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import Smartlook from 'smartlook-client'
import AllAccountView from "./components/AllAccountsView";
import AllAccountPortfolioView from "./components/AllAccountPortfolioView";
// import OverviewSideBar from "./components/SideBar/OverviewSideBar";

export default function App() {
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, direction, layout, openConfigurator, sidenavColor} = controller;

    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);
    const [accounts, setAccounts] = useRecoilState(accountsState);
    const {pathname} = useLocation();
    const [addAccountDialogOpen, setAddAccountDialogOpen] = useState(false)
    const navigate = useNavigate();

    let currentAccount = getActiveAccountFromPath(pathname)
    useEffect(() => {
        if (process.env.REACT_APP_ENV == "PROD") {
            Smartlook.init('2351f5f921c6fc359e3ae8e41aebc866db464e13')
            Smartlook.record({
                emails: true,
                forms: true,
                numbers: true,
            })
        }


    }, [])

    useEffect(() => {
        Smartlook.navigation(pathname)
    }, [currentAccount])

    // Cache for the rtl
    useMemo(() => {
        const cacheRtl = createCache({
            key: "rtl",
            stylisPlugins: [rtlPlugin],
        });

        setRtlCache(cacheRtl);
    }, []);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);



    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getSideBarRoutes = () => {
        let routes = []
        if (pathname == "/accounts" || pathname == "/dashboard" || pathname == "/portfolio") {
            routes.push({
                type: "collapse",
                name: "Accounts",
                key: "Accounts",
                route: `/accounts`,
                icon: <i className="pi pi-users"/>,
                // params: "/:addr/:activeTab",
                component: <AllAccountView />,
                noCollapse: true,

            })
            routes.push({
                type: "collapse",
                name: "Assets",
                key: "Assets",
                route: `/portfolio`,
                icon: <FontAwesomeIcon icon={faChartLine}/>,
                // params: "/:addr/:activeTab",
                component: <AllAccountPortfolioView/>,
                noCollapse: true,

            })
        }
        else {
            routes = getAccountRoutes()
        }
        return routes
    }

    const getAccountRoutes = () => {
        // let routes = [{ type: "title", title: "Accounts", key: "account-pages" }]
        let routes = []
        routes.push({
            type: "collapse",
            name: "Accounts",
            key: "Accounts Overview",
            route: `/accounts`,
            icon: <i className="pi pi-users"/>,
            params: "/:addr/:activeTab",
            component: <Tables/>,
            noCollapse: true,
        })

        routes.push({
            type: "collapse",
            name: "Portfolio",
            key: "Portfolios",
            route: `/accounts`,
            icon: <FontAwesomeIcon icon={faChartLine}/>,
            params: "/:addr/:activeTab",
            component: <Tables/>,
            noCollapse: true,
        })


        routes.push({
            type: "divider",
            name: "Accounts",
            key: 'Divider1',
            route: `/accounts`,
            icon: <i className="pi pi-arrows-h"/>,
            params: "/:addr/:activeTab",
            component: <Tables/>,
            noCollapse: true,
        })

        routes.push({
            type: "collapse",
            name: "All Transactions",
            key: "All Transaction",
            route: `/accounting/${currentAccount.toLowerCase()}/explorer`,
            icon: <i className="pi pi-arrows-h"/>,
            params: "/:addr/:activeTab",
            component: <Tables/>,
            noCollapse: true,

        })
        routes.push({
            type: "collapse",
            name: "Portfolio",
            key: "Portfolio",
            route: `/accounting/${currentAccount.toLowerCase()}/portfolio`,
            icon: <FontAwesomeIcon icon={faChartLine}/>,
            params: "/:addr/:activeTab",
            component: <Tables/>,
            noCollapse: true,
        })

        routes.push({
            type: "collapse",
            name: "LP Positions",
            key: "LP Positions",
            route: `/accounting/${currentAccount.toLowerCase()}/lp`,
            icon: <i className="pi pi-wallet"/>,
            params: "/:addr/:activeTab",
            component: <Tables/>,
            noCollapse: true,
        })

        routes.push({
            type: "collapse",
            name: "Staking",
            key: "Staking",
            route: `/accounting/${currentAccount.toLowerCase()}/staking`,
            icon: <i className="pi pi-wallet"/>,
            params: "/:addr/:activeTab",
            component: <Tables/>,
            noCollapse: true,
        })

        routes.push({
            type: "collapse",
            name: "NFTs",
            key: "NFTs",
            route: `/accounting/${currentAccount.toLowerCase()}/nfts`,
            icon: <FontAwesomeIcon icon={faImage}/>,
            params: "/:addr/:activeTab",
            component: <Tables/>,
            noCollapse: true,
        })

        routes.push({
            type: "collapse",
            name: "Financial Reports",
            key: "Financial Reports",
            route: `/accounting/${currentAccount.toLowerCase()}/reports`,
            icon: <FontAwesomeIcon icon={faReceipt}/>,
            params: "/:addr/:activeTab",
            component: <Tables/>,
            noCollapse: true,
        })

        // routes.push({
        //     type: "collapse",
        //     name: "Account Statement",
        //     key: "Account Statement",
        //     route: `/accounting/${currentAccount.toLowerCase()}/accountStatement`,
        //     icon: <FontAwesomeIcon icon={faReceipt}/>,
        //     params: "/:addr/:activeTab",
        //     component: <Tables/>,
        //     noCollapse: true,
        // })


        // for (const account of Object.keys(accounts)){
        //   routes.push({
        //     type: "collapse",
        //     name: prettyPrintAddress(account),
        //     key: account.toLowerCase(),
        //     route: `/accounting/${account}/explorer`,
        //     icon: <i className="pi pi-wallet"/>,
        //     component: <Tables/>,
        //     params:"/:addr/:activeTab",
        //     noCollapse: true,
        //   })
        // }
        return routes
    }
    const getRoutes = (allRoutes) =>

        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route && route.params != undefined) {
                return <Route exact path={route.route + `/${route.params}`}
                              element={route.component}
                              key={route.key}/>;
            } else if (route.route) {
                return <Route exact path={route.route} element={route.component}
                              key={route.key}/>;
            }

            return null;
        });

    const configsButton = (
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{cursor: "pointer"}}
            onClick={handleConfiguratorOpen}
        >
            <Icon fontSize="default" color="inherit">
                settings
            </Icon>
        </SoftBox>
    );

    return direction === "rtl" ? (

        <NextUIProvider>
            <RecoilRoot>
                <CacheProvider value={rtlCache}>
                    <ThemeProvider theme={themeRTL}>
                        <CssBaseline/>
                        {/*<HashRouter>*/}
                        <Routes>
                            {getRoutes(routes)}
                            <Route path="*" element={<Navigate to="/authentication/sign-up"/>}/>
                        </Routes>
                        {/*</HashRouter>*/}
                    </ThemeProvider>
                </CacheProvider>
            </RecoilRoot>
        </NextUIProvider>
    ) : (
        <NextUIProvider>
            <RecoilRoot>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <AddNewAccount visible={addAccountDialogOpen} closeHandler={
                        (account) => {
                            if (account == undefined) {
                                setAddAccountDialogOpen(false)
                            } else {
                                let newAccounts = Object.assign({}, accounts, {
                                    [account.account.toLowerCase()]: {
                                        label: account.account || prettyPrintAddress(account.account),
                                        address: account.account.toLowerCase(),
                                        lastSyncedTime: 0,
                                        txList: [],
                                        isLoading: false
                                    }
                                })
                                setAccounts(newAccounts)
                                navigate(`/accounting/${account.account.toLowerCase()}/explorer`)
                            }
                            setAddAccountDialogOpen(false)
                        }}></AddNewAccount>
                    {/*<HashRouter>*/}
                    <Routes>
                        {getRoutes(routes)}
                        <Route path="*" element={<Navigate
                            to={Object.keys(accounts).length > 0 ? `/accounts` : "/authentication/sign-up"}/>}/>
                    </Routes>
                    {/*</HashRouter>*/}
                    {layout === "dashboard" && (
                        <>
                            <AccountSideBar
                                color={sidenavColor}
                                brand={brand}
                                brandName="Defilo"
                                isOpen={true}
                                routes={getSideBarRoutes()}
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                                addAccountDialogOpen={addAccountDialogOpen}
                                setAddAccountDialogOpen={setAddAccountDialogOpen}
                            />
                            <Configurator/>
                            {configsButton}
                        </>
                    )}


                    {/*{layout === "vr" && <Configurator />}*/}

                </ThemeProvider>
            </RecoilRoot>
        </NextUIProvider>
    );
}
