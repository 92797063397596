/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, HashRouter} from "react-router-dom";
import App from "App";

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";
import {RecoilRoot} from "recoil";
import helmet from "helmet";
import {Helmet} from "react-helmet";
import "./index.css"

const TITLE = "hello"
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
      {/*<Helmet>*/}
      {/*    <title>{ TITLE }</title>*/}
      {/*</Helmet>*/}
    <SoftUIControllerProvider>
        <RecoilRoot><App />
        </RecoilRoot>
    </SoftUIControllerProvider>
  </BrowserRouter>
);
