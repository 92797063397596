/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import AccountNavBar from "examples/Navbars/AccountNavBar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
// import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import {useEffect, useState} from "react";
// import OrdersOverview from "layouts/dashboard/components/OrderOverview";
import {useRecoilState} from "recoil";
import {accountsState} from "../../data/accountData";
import {
    calculateAccountUSDValue, getAccountPortfolioSynced,
    getAllAccountsUSDBalance,
    getListOfAccounts,
    mergeTokenData,
    prettyPrint, prettyPrintAddress, shouldGetAccountPortfolioDataFromBackend
} from "../../common/utils";
import {getAccountPortfolioFromBackend} from "../../services/api";
import Accounts from "../../layouts/dashboard/components/Accounts";
import {AddNewAccount} from "../../layouts/dashboard/components/AddAccountDialog";

function AllAccountView() {
    const {size} = typography;
    const {chart, items} = reportsBarChartData;
    // const [accounts, setAccounts] = useState(mockLabeledAccounts())

    const [addAccountDialogOpen, setAddAccountDialogOpen] = useState(false)
    const [accounts, setAccounts] = useRecoilState(accountsState);

    const getAccountsPortfolioData = () => {
        for (const account of Object.keys(accounts)) {

            if (shouldGetAccountPortfolioDataFromBackend(accounts[account.toLowerCase()])) {
                let currentAccountData = {...accounts[account.toLowerCase()]}
                currentAccountData.isLoadingPortfolio = true
                currentAccountData.lastSyncedPortfolioTime = 0

                setAccounts(prevData => Object.assign({}, prevData, {[account.toLowerCase()]: currentAccountData}))

                getAccountPortfolioFromBackend(account.toLowerCase()).then(data => {
                    let previousData = accounts[account.toLowerCase()]
                    let newAccountData = {
                        [account.toLowerCase()]: {
                            "txList": previousData.txList || [],
                            "portfolio": data.portfolioData,
                            "nfts": data.nftData,
                            "uniV2LPTokens": data.uniV2LP,
                            "stakingPositions": data.stakingPositionData,
                            "label": prettyPrintAddress(account.toLowerCase()),
                            "address": account.toLowerCase(),
                            "isLoading": false,
                            "lastSyncedTime": previousData.lastSyncedTime || 0,
                            "lastSyncedPortfolioTime": new Date().getTime(),
                        }
                    }
                    // update accounts..
                    setAccounts(prevData => Object.assign({}, prevData, newAccountData))
                })
            }
        }

    }


    useEffect(() => {
        getAccountsPortfolioData()
    }, [])

    // useEffect(() => {
    //     getAccountsPortfolioData()
    // }, [accounts])


    return (
        <DashboardLayout>
            {/*<OverviewSideBar/>*/}
            <AddNewAccount visible={addAccountDialogOpen} closeHandler={(acc) => {
                if (acc == undefined) {
                    setAddAccountDialogOpen(false)
                } else {
                    let newAccounts = Object.assign({}, accounts, {
                        [acc.account.toLowerCase()]: {
                            label: acc.label || prettyPrintAddress(acc.account),
                            account: acc.account
                        }
                    })
                    setAccounts(newAccounts)
                }
                setAddAccountDialogOpen(false)
            }}></AddNewAccount>

            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} xl={6}>
                            <MiniStatisticsCard
                                title={{text: "Total Networth"}}
                                count={prettyPrint(getAllAccountsUSDBalance(accounts))}
                                percentage={{color: "success", text: "+10%"}}
                                icon={{color: "info", component: "paid"}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={6}>
                            <MiniStatisticsCard
                                title={{text: "Tracked Accounts"}}
                                count={ getAccountPortfolioSynced(accounts) + "/" +Object.keys(accounts).length}
                                // percentage={{ color: "success", text: "+3%" }}
                                icon={{color: "info", component: "account"}}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                {/*{!isAccount && <SoftBox mb={3}>*/}
                {/*    <Grid container spacing={3}>*/}
                {/*        <Grid item xs={12} lg={12}>*/}
                {/*            <AccountPortfolio accountTokens={mergeTokenData(accounts)}>*/}
                {/*            </AccountPortfolio>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</SoftBox>}*/}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Accounts accounts={accounts} setAccounts={setAccounts}
                                  setAddAccountDialogOpen={setAddAccountDialogOpen} addAccountHandler={(account) => {
                            if (account != undefined) {
                                setAccounts([...account, account])
                            }
                            setAddAccountDialogOpen(false)
                        }}/>
                    </Grid>
                    {/*  <Grid item xs={12} md={6} lg={6}>*/}
                    {/*    <Accounts accounts={accounts} setAddAccountDialogOpen={setAddAccountDialogOpen}  />*/}
                    {/*  </Grid>*/}
                </Grid>
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default AllAccountView;
