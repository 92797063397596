/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from "react";
import {useEffect, useRef} from "react";

// react-router-dom components
import {NavLink, useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

import {Menu} from 'primereact/menu';
import {Avatar} from 'primereact/avatar';
// Soft UI Dashboard React context
import {setMiniSidenav, useSoftUIController} from "context";
import {getActiveAccountFromPath} from "../../common/utils";
import AccountSelectionMenu from "../AccountSelectionMenu";
import {useRecoilState} from "recoil";
import {accountsState} from "../../data/accountData";

function TemplateDemo() {
    // const toast = useRef()
    const menuRight = useRef(null);

    let items = [
        // { label: 'Profile', icon: 'pi pi-fw pi-user' },
        // { label: 'Settings', icon: 'pi pi-fw pi-cog' },
        { separator: true},
        {
            // command: () => { toast.current.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 }); },
            template: (item, options) => {
                return (
                    <button onClick={(e) => menuRight.current.toggle(e)} >
                        <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"  shape="circle" />
                        <div className="flex flex-column align">
                            <span className="font-bold">Accounts</span>
                            {/*<span className="text-sm">Agent</span>*/}
                        </div>
                    </button>
                )
            }}
    ];

    return (
        <div className="card flex justify-content-center">
            <Menu model={items} />
            {/*<Toast ref={toast} />*/}
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />

        </div>
    )
}

function SidenavAccounts({ color, brand, brandName, routes, isOpen, addAccountDialogOpen, setAddAccountDialogOpen, ...rest}) {
    const [controller, dispatch] = useSoftUIController();

    const { miniSidenav, transparentSidenav } = controller;
    const location = useLocation();
    const { pathname } = location;

    const collapseName = getActiveAccountFromPath(pathname)
    const [accounts, setAccounts] = useRecoilState(accountsState);
    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
        }

        /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
        let returnValue;
        if (type === "collapse") {
            returnValue = href ? (
                <Link
                    href={href}
                    key={key}
                    target="_blank"
                    rel="noreferrer"
                    sx={{ textDecoration: "none", fontFamily:"Poppins", fontWeight:"650" }}
                >
                    <SidenavCollapse
                        color={color}
                        name={name}
                        icon={icon}
                        active={key.toLowerCase() === collapseName?.toLowerCase()}
                        noCollapse={noCollapse}
                    />
                </Link>
            ) : (
                <NavLink to={route} key={key}>
                    <SidenavCollapse
                        color={color}
                        key={key}
                        name={name}
                        icon={icon}
                        active={key.toLowerCase() === collapseName?.toLowerCase()}
                        noCollapse={noCollapse}
                    />
                </NavLink>
            );
        } else if (type === "title") {
            returnValue = (
                <SoftTypography
                    key={key}
                    display="block"
                    variant="h5"
                    fontWeight="bold"
                    color={"rgb(14, 14, 82)"}
                    // textTransform="uppercase"
                    // opacity={0.6}
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                >
                    {title}
                </SoftTypography>
            );
        } else if (type === "divider") {
            returnValue = <Divider key={key} />;
        }

        return returnValue;
    });


    return (
        <>
        {isOpen ? (<SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
            <SoftBox pt={3} pb={1} px={4} textAlign="center">
                <SoftBox
                    display={{ xs: "block", xl: "none" }}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{ cursor: "pointer" }}
                >
                    <SoftTypography variant="h6" color="secondary">
                        <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                    </SoftTypography>
                </SoftBox>
                <SoftBox component={NavLink} to="/accounts" display="flex" alignItems="center">
                    {brand && <SoftBox component="img" src={brand} alt="Soft UI Logo" width="10rem" />}
                    <SoftBox
                        width={!brandName && "100%"}
                        sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                    >
                        {/*<SoftTypography component="h6" variant="button" fontWeight="medium">*/}
                        {/*    /!*{brandName}*!/*/}
                        {/*</SoftTypography>*/}
                    </SoftBox>
                </SoftBox>
            </SoftBox>
            
            {/*<SoftBox pt={3} pb={1}  textAlign="center">*/}
            {/*    */}
            {/*    <AccountSelectionMenu connectedAccounts={accounts} openAddAccountDialog={()=>{*/}
            {/*        setAddAccountDialogOpen(true)*/}
            {/*    }}></AccountSelectionMenu>*/}
            {/*</SoftBox>*/}
            <Divider />
            <List>{renderRoutes}</List>
            {/*<SoftBox mt={2}>*/}
            {/*    <SoftButton*/}
            {/*        component="a"*/}
            {/*        href="https://creative-tim.com/product/soft-ui-dashboard-pro-react"*/}
            {/*        target="_blank"*/}
            {/*        rel="noreferrer"*/}
            {/*        variant="gradient"*/}
            {/*        color={color}*/}
            {/*        fullWidth*/}
            {/*    >*/}
            {/*        Add Account*/}
            {/*    </SoftButton>*/}
            {/*</SoftBox>*/}
            <SoftBox pt={2} my={2} mx={2} mt="auto">
                {/*<SidenavCard />*/}
                <SoftBox mt={2}>
                    <SoftButton
                        component="a"
                        variant="gradient"
                        color={color}
                        onClick={()=>{
                            setAddAccountDialogOpen(true)
                        }}
                        fullWidth
                    >
                        Add Account
                    </SoftButton>
                    {/*<TemplateDemo></TemplateDemo>*/}
                </SoftBox>

            </SoftBox>
            <Divider>
                <SoftBox mt={2}>
                    {/*<SoftButton*/}
                    {/*    component="a"*/}
                    {/*    variant="gradient"*/}
                    {/*    color={color}*/}
                    {/*    onClick={()=>{*/}
                    {/*        setAddAccountDialogOpen(true)*/}
                    {/*    }}*/}
                    {/*    fullWidth*/}
                    {/*>*/}
                    {/*    Add Account*/}
                    {/*</SoftButton>*/}
                    {/*<TemplateDemo></TemplateDemo>*/}
                </SoftBox>
            </Divider>
        </SidenavRoot>): <></>}
        </>
    );
}

// Setting default values for the props of Sidenav
SidenavAccounts.defaultProps = {
    color: "info",
    brand: "",
};

// Typechecking props for the Sidenav
SidenavAccounts.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    isOpen: PropTypes.bool
};

export default SidenavAccounts;
