/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import {parseMethod, prettyPrint, prettyPrintAddress, timestampToHumanReadable} from "../../../common/utils";
import {MOCK_DATA_2} from "../../../data/accountingData.js";
import 'primeicons/primeicons.css';
import "primereact/resources/primereact.min.css";
import IconButton from "@mui/material/IconButton";
import {ArrowCircleDown, ArrowCircleUpRounded, CodeOutlined, Error, MultipleStopOutlined} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {ThemeProvider} from "@mui/material/styles"
import {makeStyles} from "@mui/styles";
import {ListItem, ListItemText, useMediaQuery} from "@mui/material";
import List from "@mui/material/List";
import {createTheme} from "@mui/material/styles";
import Link from "@mui/material/Link";
import {useRef, useState} from "react";
import {TieredMenu} from "primereact/tieredmenu";
import {Button} from "primereact/button";


function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}


const data = MOCK_DATA_2
const account = "0xf468c2bf527904a06020e4f4c6e3b7781f8023bd"

const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: "bold",
      fontSize:14,
      color:"black"
    },

  },
});


function RenderCellOperation(params) {
  const useStyles = makeStyles({
  customDataGrid: {
    "& .MuiDataGrid-colHeaderWrapper": {
      display: 'flex',
    },
    "& .MuiDataGrid-colHeaderCell": {
      flex: 1,
    },
    "&": {
      border: "none !important"
    },
    "& .MuiDataGrid-row:hover": {
      // backgroundColor:"rgb(250, 252, 255) !important"
    },
    "& .MuiDataGrid-row:select": {
      backgroundColor:"white"
    },

    maxWidth:"100vw"
  },
  centerTable: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    maxWidth:'80vh'
  },
  rowStyle:{
    padding:'.75em',
    backgroundColor:"#F9FCFF !important",
    minHeight:"100px !important",
    minWidth: "100vw",
    color:"blue",

    fontFamily:"Ariel !important",
    "& .MuiDataGrid-colCellTitle": {
      width: '100%',
    },
  },
  customRow: {
    // backgroundColor:"#FFFFFF !important",
    minHeight: "100px !important",
    borderBottom: "1px solid rgb(231, 235, 240) !important"
  },
  columnHeaderTitle:{
    // background:"red",
    color:"#617389",
    fontSize:"10px",
    fontWeight:"700"
  },
  primaryText: {
    fontSize:"14px !important",
    color: 'black !important',
    fontWeight:'bold !important'

  },
    subText: {
      fontSize:"10px !important",
      color: 'gray !important',

    },
  selectBoxText: {
    fontSize: "14px",

    color: "black !important",
    fontWeight: "bold"

  },
  operationText: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  nativeInput:{
    border:"0 !important"
  },
  select: {
    // background: "red",
    color:"red",
    fontSize:"10px",
    '&.MuiOutlinedInput': {
      borderColor:"blue !important"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red !important',
    },
    minWidth:"20em"
    // '&:before': {
    //     borderColor: 'white !important',
    // },
    // '&:after': {
    //     borderColor: 'white !important',
    // },
    // '&:not(.Mui-disabled):hover::before': {
    //     borderColor: 'white !important',
    // },
    // backgroundColor: 'lightblue', // Change the background color to your desired color
    // border: '2px solid red', // Change the border style to your desired style
    // borderRadius: 4, // Change the border radius to your desired value
    // padding: '8px 16px',
    // border:"0px !important",
    // borderColor: 'red !important', // Change the border color to your desired color
    // borderWidth: "2 !important" , // Change the border width to your desired value
    // borderRadius: 4, // Change the border radius to your desired value
    // padding: '8px 16px', // Adjust the padding as needed
  },
  settingsChip: {
    // /*    display: 'flex',
    //     flexDirection: 'row',
    //     alignItems: "flex-start",
    //     justifyContent: 'flex-start',
    //     width:"100% !important"*/
  },
});

const classes = useStyles();
  let isSwap = false
  let isTransferIn = false
  let isUnknownFunctionCall = false
  let isTransferETHOut = false

  let tokenIn
  let tokenOut
  let isFailed = params.row.operation == "Failed"

  // if the user called "transfer" function
  let isRegularTokenOutTransfer = false
  let isRegularTokenInTransfer = false

  if (!isFailed){
    isSwap = params.row.operation == "Trade"
    isTransferETHOut = params.row.operation == "Send ETH"
    isTransferIn = params.row.operation == "Receive ETH"
    isRegularTokenOutTransfer = params.row.operation == "Send Token"
    isRegularTokenInTransfer = params.row.operation == "Receive Token"
    isUnknownFunctionCall = params.row.operation == "Contract Call"

  }


  if (isSwap){
    [tokenOut, tokenIn] = [params.row.tokenOutInfo, params.row.tokenInInfo]
  }


  return (
      <div style={{"display": "flex", "alignItems": 'center'}}>
        <IconButton>
          {isTransferIn || isRegularTokenInTransfer ? <ArrowCircleDown sx={{color:"green"}}/>: <></>}
          {isTransferETHOut || isRegularTokenOutTransfer ? < ArrowCircleUpRounded sx={{color:"red"}} fill={true}/>: <></>}
          {isSwap ? <MultipleStopOutlined sx={{color:"blue"}}/>: <></>}
          {isUnknownFunctionCall ? <CodeOutlined sx={{color:"gray"}}/>: <></>}
          {isFailed ? <Error sx={{color:"red"}}/>: <></>}

        </IconButton>
        <Box>
          {isTransferIn ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"} className={classes.primaryText}>Receive ETH</Typography>: <></>}
          {isTransferIn ? <Typography variant="caption"className={classes.subText} >
            {isTransferIn? "From " + prettyPrintAddress(params.row.from) : ""}
          </Typography>:<></>}
          {isRegularTokenInTransfer ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"} className={classes.primaryText}>Receive Token</Typography>: <></>}
          {isRegularTokenInTransfer ? <Typography variant="caption" className={classes.subText} >
            {isTransferIn? "From " + prettyPrintAddress(params.row.from) : ""}
          </Typography>:<></>}

          {isRegularTokenOutTransfer ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"} className={classes.primaryText}>Send Token</Typography>: <></>}
          {isRegularTokenOutTransfer ? <Typography variant="caption" className={classes.subText}>
            {isRegularTokenOutTransfer? "To " + prettyPrintAddress(params.row.from) : ""}
          </Typography>:<></>}

          {isFailed ? <Typography className={classes.primaryText}>Failed</Typography>: <></>}

          {isTransferETHOut ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"} className={classes.primaryText}>Send ETH</Typography>: <></>}
          {isTransferETHOut ? <Typography variant="caption" className={classes.subText}>
            {isTransferETHOut? "To " + prettyPrintAddress(params.row.from) : ""}
          </Typography>:<></>}

          {isSwap ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"} className={classes.primaryText}>Trade</Typography>: <></>}
          {isSwap ? <Typography variant="caption" className={classes.subText} >
            {isSwap? "Swap" + " " + tokenOut?.symbol + " " + "for" + " "  + tokenIn?.symbol : ""}
          </Typography>:<></>}

          {isUnknownFunctionCall ? <Typography variant="body1" fontWeight="bold" color={"black"} className={classes.primaryText}>{parseMethod(params.row.method)}</Typography>: <></>}
          {isUnknownFunctionCall ? <Typography variant="caption" className={classes.subText} >
            {isUnknownFunctionCall? "Call" + " " + prettyPrintAddress(params.row.to): ""}
          </Typography>:<></>}
        </Box>

      </div>

  );
};

const RenderMoreActions = (params) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const menu = useRef(null);
  const items = [
    {
      label:'Hide Transaction',
      icon:'pi pi-eye-slash',

    },
    {

      label:'Change Type',
      icon:'pi pi-eye-slash',
      items:[
        {
          label:'Trade',
          icon:'pi pi-arrow-right-arrow-left',
          // items:[
          //     {
          //         label:'Bookmark',
          //         icon:'pi pi-fw pi-bookmark'
          //     },
          //     {
          //         label:'Video',
          //         icon:'pi pi-fw pi-video'
          //     },
          //
          // ]
        },
        {
          label:'Send Token',
          icon:'pi pi-arrow-up'
        },
        {
          label:'Receive Token',
          icon:'pi pi-arrow-down'
        },
        // {
        //     separator:true
        // },
        // {
        //     label:'Export',
        //     icon:'pi pi-fw pi-external-link'
        // }
      ]
    },
    {
      label:'Note',
      icon:'pi pi-fw pi-pencil',
      // items:[
      //     {
      //         label:'Left',
      //         icon:'pi pi-fw pi-align-left'
      //     },
      //     {
      //         label:'Right',
      //         icon:'pi pi-fw pi-align-right'
      //     },
      //     {
      //         label:'Center',
      //         icon:'pi pi-fw pi-align-center'
      //     },
      //     {
      //         label:'Justify',
      //         icon:'pi pi-fw pi-align-justify'
      //     },
      //
      // ]
    },
    {
      label:'Set Cost Basis',
      icon:'pi pi-dollar',
      // items:[
      //     {
      //         label:'Left',
      //         icon:'pi pi-fw pi-align-left'
      //     },
      //     {
      //         label:'Right',
      //         icon:'pi pi-fw pi-align-right'
      //     },
      //     {
      //         label:'Center',
      //         icon:'pi pi-fw pi-align-center'
      //     },
      //     {
      //         label:'Justify',
      //         icon:'pi pi-fw pi-align-justify'
      //     },
      //
      // ]
    },
    {
      label:'P&L Explained',
      icon:'pi pi-question-circle',
      // items:[
      //     {
      //         label:'Left',
      //         icon:'pi pi-fw pi-align-left'
      //     },
      //     {
      //         label:'Right',
      //         icon:'pi pi-fw pi-align-right'
      //     },
      //     {
      //         label:'Center',
      //         icon:'pi pi-fw pi-align-center'
      //     },
      //     {
      //         label:'Justify',
      //         icon:'pi pi-fw pi-align-justify'
      //     },
      //
      // ]
    },
    {
      separator:true
    },
    {
      label:'Transaction',
      icon:'pi pi-external-link blue-600',
      // items:[
      //     {
      //         label:'Left',
      //         icon:'pi pi-fw pi-align-left'
      //     },
      //     {
      //         label:'Right',
      //         icon:'pi pi-fw pi-align-right'
      //     },
      //     {
      //         label:'Center',
      //         icon:'pi pi-fw pi-align-center'
      //     },
      //     {
      //         label:'Justify',
      //         icon:'pi pi-fw pi-align-justify'
      //     },
      //
      // ]
    },
    // {
    //     label:'Users',
    //     icon:'pi pi-fw pi-user',
    //     items:[
    //         {
    //             label:'New',
    //             icon:'pi pi-fw pi-user-plus',
    //
    //         },
    //         {
    //             label:'Delete',
    //             icon:'pi pi-fw pi-user-minus',
    //
    //         },
    //         {
    //             label:'Search',
    //             icon:'pi pi-fw pi-users',
    //             items:[
    //                 {
    //                     label:'Filter',
    //                     icon:'pi pi-fw pi-filter',
    //                     items:[
    //                         {
    //                             label:'Print',
    //                             icon:'pi pi-fw pi-print'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     icon:'pi pi-fw pi-bars',
    //                     label:'List'
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     label:'Events',
    //     icon:'pi pi-fw pi-calendar',
    //     items:[
    //         {
    //             label:'Edit',
    //             icon:'pi pi-fw pi-pencil',
    //             items:[
    //                 {
    //                     label:'Save',
    //                     icon:'pi pi-fw pi-calendar-plus'
    //                 },
    //                 {
    //                     label:'Delete',
    //                     icon:'pi pi-fw pi-calendar-minus'
    //                 }
    //             ]
    //         },
    //         {
    //             label:'Archive',
    //             icon:'pi pi-fw pi-calendar-times',
    //             items:[
    //                 {
    //                     label:'Remove',
    //                     icon:'pi pi-fw pi-calendar-minus'
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
      separator:true
    },


  ];

  return (<Box onMouseEnter={handleMouseEnter}
               onMouseLeave={handleMouseLeave}
               style={{width:"100%", height:"100%", display:"flex", alignItems:"center"}}
      >


        {/*<Tooltip title="Edit">*/}
        <div>

          <TieredMenu model={items} popup ref={menu}  style={{boxShadow:"rgba(194, 203, 216, 0.4) 0px 24px 56px !important", borderRadius:"5px", minWidth:"250px", fontSize:"10px", fontWeight:"700", color:"black"}}/>


          <Button  tooltip={"Edit"} tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} rounded text icon="pi pi-ellipsis-h" onClick={(event) => menu.current.toggle(event)}/>


        </div>
        {/*</Tooltip>*/}
      </Box>
  )
};

const RenderTokensInCell = (params) => {
  let name = params.field
  // sort by the once with the most financial value
  let sortedTokensIn = params.value.sort((a,b)=>b.usdValueAmount - a.usdValueAmount)

  return (<>
        <ThemeProvider theme={theme}>
        <List>
          {sortedTokensIn.length < 2 && sortedTokensIn.map((item, index) => (
              <ListItemText key={index}
                            primaryTypographyProps={{ style: { color: name === "tokensIn" ? "green" : "red", fontSize:"16px"} }}
                            primary={`${name === "tokensIn" ? "+" : "-"} ${prettyPrint(item.amount)} ${item.tokenInfo.symbol}`}
                            secondary={`${prettyPrint(item.usdValueAmount)}$`}
                  // secondary=
                  //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                  //     <ListItem>Per Basis: 100$</ListItem>
                  //   </List>}

              >
                {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
              </ListItemText>

          ))

          }


          {sortedTokensIn.length >=2 && (
              <ListItemText
                  primaryTypographyProps={{ style: { color: name === "tokensIn" ? "green" : "red" } }}
                  primary={`${name === "tokensIn" ? "+" : "-"} ${sortedTokensIn.length} tokens`}
                  // secondary={`${prettyPrint(item.usdValueAmount)}$`}
                  // secondary=
                  //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                  //     <ListItem>Per Basis: 100$</ListItem>
                  //   </List>}

              >
                {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
              </ListItemText>

          )}


        </List>
        </ThemeProvider>

      </>
  )
};

const RenderProfitLossCell = (params) => {
  return (<>
        {params.row.pnl && <List>
          <ListItem>
            <ListItemText primary={"Cost Basis:" + params.row.pnl.costBasis} primaryTypographyProps={{style:{fontSize:"10px", color:"red !important"}}}>

            </ListItemText>
          </ListItem>
          <ListItem>
            Profit & Loss: {params.row.pnl.pnl}
          </ListItem>


        </List>}
      </>
  )
};

const RenderValueCell = (params) => {
  let colorVal = params.row.from.toLowerCase() == account.toLowerCase() ? "red": "green"
  colorVal = params.value == 0 ? "gray": colorVal

    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Typography variant="body1" style={{color: colorVal, letterSpacing:"0.4px", fontWeight:"700"}}>{params.row.from.toLowerCase() == account.toLowerCase()?"-":"+"}{prettyPrint(params.value)} BNB</Typography>
          <Typography variant="caption">≈{prettyPrint(params.row.usdValue)}$</Typography>
        </Box>
      </ThemeProvider>
      // <Typography variant="body1" component="span" style={{color: colorVal, letterSpacing:"0.4px", fontWeight:"700", fontSize:"14px"}}>
      //   { params.value != 0 ? `${isTransferIn? "+": "-"} ${prettyPrint(params.value)} BNB (${prettyPrint(params.row.usdValue)}$)`: "0 BNB"}
      //
      // </Typography>

)

};


const authorsTableData = {
  columns: [
    {"name": "Date", "align": "left"},
    { name: "hash", align: "left" },
    { name: "Operation", align: "left" },
    { name: "Value", align: "center" },
    { name: "TokensIn", align: "center" },
    { name: "TokensOut", align: "center" },
    { name: "PNL", align: "center" },
    { name: "Note", align: "center" },
    { name: "Actions", align: "center" },

  ],

  generateRows: (rowVal) => {
    return rowVal.map(x=>{
    return {
      Date: timestampToHumanReadable(new Date(x.timeStamp * 1000).getTime()),
      hash:  <Link href={`https://bscscan.com/tx/${x.hash}`} isExternal={true}>
        {prettyPrintAddress(x.hash)}
      </Link>,
          // eslint-disable-next-line react/no-unknown-property
        Operation: <RenderCellOperation params={{"row":x}}/>,
        Value: <RenderValueCell params={{"row":x, "value": x.value}}/>,
        TokensIn: <RenderTokensInCell params={{"row":x, "value": x.tokensIn}} name={"tokensIn"}/>,
        TokensOut: <RenderTokensInCell params={{"row":x, "value": x.tokensOut}} name={"tokensOut"}/>,
        Actions: <RenderMoreActions></RenderMoreActions>

   }})},
  rowVal:MOCK_DATA_2,

  getTokensOptions: ()=>{
      let tokensOptions = []
      let tokensReviewd = {}
      for (const tx of MOCK_DATA_2){
        let tokensInTx = tx.tokensIn.concat(tx.tokensOut)
        for (let token of tokensInTx){
          let tokenInfo = token.tokenInfo
          if (tokensReviewd[tokenInfo.addr.toLowerCase()] == undefined){
            tokensReviewd[tokenInfo.addr.toLowerCase()] = true
            tokensOptions.push({
              "name": tokenInfo.symbol,
              "addr": tokenInfo.addr
            })

          }
        }
      }

      return tokensOptions

    }



};

const RenderTxHahCell = (params)=>{
  return (<Link href={`https://bscscan.com/tx/${params.value}`} isExternal={true}>
    {prettyPrintAddress(params.value)}
  </Link>)
}

export function transactionTableData(initialData) {
  return {
    columns: [
      // {field: "checkbox", "headerName": "", minWidth: 5},
      { field: 'hash', headerName: 'Transaction Hash', renderCell:RenderTxHahCell, minWidth: 180},
      { field: 'operation', headerName: 'Operation', renderCell: RenderCellOperation, minWidth: 200},
      { field: 'timeStamp', minWidth: 160, headerName: 'Date', valueFormatter: (params) => {
          let d = new Date(params.value * 1000).getTime()
          return timestampToHumanReadable(d)
        },
      },
      // { field: 'method', headerName: 'Method',width:200},
      { field: 'value', headerName: 'Value',  renderCell: RenderValueCell, minWidth: 150},
      { field: 'tokensOut', headerName: 'Tokens Out',  renderCell: RenderTokensInCell, minWidth: 150},
      { field: 'tokensIn', headerName: 'Tokens In',  renderCell: RenderTokensInCell, minWidth: 150},
      { field: 'pnl', headerName: 'Profit & Loss', renderCell:RenderProfitLossCell},
      { field: '', headerName: '', renderCell:RenderMoreActions, sortable:false},

    ],
    initialTableData: initialData
  }
}


export default authorsTableData;
