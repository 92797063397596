import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {getNativeTokenNameByChain, prettyPrint} from "../../common/utils";
import * as React from "react";
import {RenderSimpleFieldWithImage} from "../common";

export function StakingPositions({stakingPositions}) {
    let headerStyle = {background: "none", fontFamily: "Inter, sans-serif", "color": "#0e0e52"}
    let bodyStyle = {fontFamily: "Roboto", fontWeight: "600", color: "black"}
    return (
        <DataTable
            size={"small"}
            sortOrder={-1}
            editMode="row"
            paginator
            rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            value={stakingPositions}
            header={<h1 style={{fontSize: "1.5rem", fontFamily:"Poppins", marginTop:"15px"}}>Staking Positions</h1>}
             className={"allTransactionsTable"}>
            <Column field={"protocolName"}
                    header={"Protocol"}
                    headerStyle={headerStyle}
                    bodyStyle={bodyStyle}
                    body={(params)=>{
                        return RenderSimpleFieldWithImage(params.protocolName, "", params.protocolImage)
                    }}
            ></Column>

            <Column field={"stakeBalance"}
                    header={"Staked Balance"}
                    headerStyle={headerStyle}
                    bodyStyle={bodyStyle}
                    body={(params) => {
                        return prettyPrint(params.stakedBalance) +  " " +params.stakedTokenSymbol + " = " + "(" + prettyPrint(params.stakedBalanceUsdValue) + "$" + ")"
                    }}
            ></Column>
            <Column field={"pendingRewards"}
                    header={"Profit & Loss"}
                    headerStyle={headerStyle}
                    bodyStyle={bodyStyle}
                    body={(params) => {
                        return "+" + prettyPrint(params.rewardAmount) + " " +  params.rewardTokenSymbol + " = " + "(" + prettyPrint(params.rewardAmountUsdValue) + "$" + ")"
                    }}
            ></Column>



        </DataTable>)
}