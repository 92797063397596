/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useRef, useState} from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard Materail-UI example components
import Table from "examples/Tables/Table";

// Data
import data, {mockLabeledAccounts} from "layouts/dashboard/components/Accounts/data";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Tag} from "primereact/tag";
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {Badge, Input, Modal, Text} from "@nextui-org/react";
import {
    calculateAccountUSDValue, getAccountLink,
    getAccountPortfolioValue, getChainLogo,
    getListOfAccounts,
    prettyPrint, prettyPrintAddress
} from "../../../../common/utils";
import {Toast} from "primereact/toast";
import Typography from "@mui/material/Typography";
import {ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import List from "@mui/material/List";
import {WalletOutlined} from "@mui/icons-material";
import {Avatar} from "primereact/avatar";
import * as React from "react";
import _ from "lodash";
import moment from "moment";
import {getAccountPortfolioFromBackend} from "../../../../services/api";

import {SUPPORTED_CHAINS} from "../../../../config";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";
import {AutoComplete} from "primereact/autocomplete";

function Accounts({accounts, setAccounts, setAddAccountDialogOpen, addAccountHandler, EditAccountHandler}) {
    // add portfolio data to accounts
    const getAccountsDataForTable = (accounts) => {
        return accounts.map(obj => ({...obj, netWorth: getAccountPortfolioValue(obj.portfolio)}));

    }

    const {columns, rows} = data();
    const [menu, setMenu] = useState(null);
    const [filteredAccounts, setFilteredAccounts] = useState(getListOfAccounts(accounts))
    const [searchAccountInput, setSearchAccountInput] = useState("")

    const navigate = useNavigate();
    const openMenu = ({currentTarget}) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);


    useEffect(() => {
        setFilteredAccounts(getListOfAccounts(accounts))

    }, [accounts])

    const accountHeaderStyle = {"background": "none"}
    const bodyStyle = {"fontSize": "12px"}
    const renderMenu = (
        <Menu
            id="simple-menu"
            anchorEl={menu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={closeMenu}>Action</MenuItem>
            <MenuItem onClick={closeMenu}>Another action</MenuItem>
            <MenuItem onClick={closeMenu}>Something else</MenuItem>
        </Menu>
    );

    const textEditor = (options, style) => {
        let style_ = Object.assign({height: "30px", minWidth: "28em", fontSize: "12px"}, style)
        return <InputText style={style_} type="text" value={options.value}
                          onChange={(e) => options.editorCallback(e.target.value)}/>;
    };

    const actionButtons = (account) => {
        const iconStyle = {background: "white", color: "rgb(0, 114, 245)", border: "none"}
        let isSynced = account.lastSyncedPortfolioTime && account.lastSyncedPortfolioTime > 0 ? true : false
        let icon = isSynced ? "pi pi-check-circle" : "pi pi-sync"
        icon = account.isLoadingPortfolio ? "pi pi-spin pi-sync" : icon
        let isSyncedIconStyle = {...iconStyle}
        if (isSynced) {
            isSyncedIconStyle.color = "green"
        }

        return (<div>
            <Button icon="pi pi-trash" style={iconStyle} className="p-mr-2" onClick={() => {
                let newAccounts = _.pickBy(accounts, (value, key) => key != account.address.toLowerCase())
                setAccounts(newAccounts)
            }}/>
            {/*<Badge content={<i class={"pi pi-check"}></i>} color={isSynced ? "success": "danger"}>*/}
            <Button icon={icon} style={isSyncedIconStyle} className="p-mr-2" onClick={() => {
                let newAccountData = {...account}
                newAccountData.lastSyncedPortfolioTime = 0
                newAccountData.isLoadingPortfolio = true
                setAccounts(prevData => Object.assign({}, prevData, {[account.address.toLowerCase()]: newAccountData}))

                getAccountPortfolioFromBackend(account.address.toLowerCase()).then(data => {
                    let newAccountData = {...account}

                    newAccountData.portfolio = data.portfolioData
                    newAccountData.nfts = data.nftData
                    newAccountData.uniV2LPTokens = data.uniV2LP
                    newAccountData.stakingPositions = data.stakingPositionData
                    newAccountData.lastSyncedPortfolioTime = new Date().getTime()
                    newAccountData.isLoadingPortfolio = false

                    setAccounts(prevData => Object.assign({}, prevData, {[account.address.toLowerCase()]: newAccountData}))
                })


            }}/>
            {/*</Badge>*/}
            {/*<Badge content={<Button icon="pi pi-sync" style={iconStyle} className="p-mr-2" onClick={() => console.log(account.address)}/>}>*/}


            {/*</Badge>*/}
        </div>)
    }


    const accountStatusEditor = (options) => {
        return (
            <Dropdown
                value={"Yes"}
                options={["No", "Yes"]}
                // onChange={(e) => options.editorCallback(e.value == "Yes"? true: false)}
                placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <Tag value={option}></Tag>;
                }}
            />
        );
    };

    const onRowEditComplete = (e) => {
        let {newData} = e;
        let newAccounts = Object.assign({}, accounts);
        let accountData = Object.assign({}, accounts[newData.address.toLowerCase()])
        accountData.label = newData.label;
        newAccounts[newData.address.toLowerCase()] = accountData
        setAccounts(newAccounts);
    };

    const onRowSelect = (event) => {
        navigate(`/accounting/${event.data.address.toLowerCase()}/explorer`)
    };

    return (
        <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftBox>

                    <h1 style={{fontSize: "1.5rem", "fontFamily": "Poppins"}}>All Accounts</h1>
                    <SoftBox display="flex" alignItems="center" lineHeight={0}>

                    </SoftBox>
                </SoftBox>
                {/*<SoftBox color="text" px={2}>*/}
                {/*    <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>*/}
                {/*        more_vert*/}
                {/*    </Icon>*/}
                {/*</SoftBox>*/}
                {/*{renderMenu}*/}
            </SoftBox>
            <SoftBox
                sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                            borderBottom: ({borders: {borderWidth, borderColor}}) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                        },
                    },
                }}
            >

                <Toolbar style={{height: "fit-content", background: "none", border: "none"}} end={() => {
                    return (
                        <div className="flex flex-wrap gap-1">
                            <Button label="Add Account" icon="pi pi-user-plus" text raised size={"small"}
                                    style={{height: "40px", color: "#0072f5"}} onClick={() => {
                                setAddAccountDialogOpen(true)
                            }}/>


                        </div>)
                }} start={() => {
                    return (
                        <div className="flex flex-wrap gap-1">
                            <span className="p-input-icon-left"><i className="pi pi-search"/>
                            <InputText
                                value={searchAccountInput}
                                placeholder="Search Address.."
                                className={"searchAccount"}
                                onChange={(e) => {
                                    let accounts_ = Object.values(accounts).filter(x => x.address.includes(e.target.value))
                                    setFilteredAccounts(accounts_);
                                    setSearchAccountInput(e.target.value)
                                }}>

                            </InputText></span></div>)
                }}></Toolbar>

                <DataTable
                    selectionMode="single"
                    onRowSelect={onRowSelect}
                    value={getAccountsDataForTable(filteredAccounts)}
                    onRowEditComplete={onRowEditComplete}
                    paginator
                    editMode="row"
                    rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}">
                    <Column field="address" header="Account"
                            headerStyle={Object.assign(accountHeaderStyle, {minWidth: "25em"})} bodyStyle={bodyStyle}
                            body={(params) => {
                                return (<>
                                        <List>

                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar icon={"pi pi-user"}
                                                            className="mr-2" shape="circle"/>
                                                </ListItemAvatar>
                                                <ListItemText variant="body1" primary={params.label}
                                                              primaryTypographyProps={{
                                                                  style: {
                                                                      fontWeight: "550",
                                                                      color: "black",
                                                                      fontSize: "1rem"
                                                                  },
                                                                  sx: {
                                                                      fontFamily: "Poppins !important",
                                                                      fontSize: "0.875rem"
                                                                  }
                                                              }} secondary={
                                                    <>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            padding: 0,
                                                            justifyContent: "flex-start"
                                                        }}>
                                                            <List style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                padding: 0,
                                                                justifyContent: "flex-start"
                                                            }}>
                                                                {SUPPORTED_CHAINS.map((chain, idx) => (
                                                                    <ListItem id={idx} disablePadding>
                                                                        <Link
                                                                            href={getAccountLink(chain, params.address)}
                                                                            isExternal={true}
                                                                            target="_blank">
                                                                            {/*<LaunchOutlined sx={{ verticalAlign: 'middle', marginRight: '4px' }} />*/}
                                                                            <img alt="" src={getChainLogo(chain)}
                                                                                 style={{
                                                                                     verticalAlign: 'middle',
                                                                                     marginRight: '4px',
                                                                                     maxWidth: "20px",
                                                                                     maxHeight: "20px",
                                                                                     cursor: "pointer"
                                                                                 }}/>

                                                                        </Link>
                                                                    </ListItem>))}
                                                            </List>
                                                        </div>
                                                    </>}>

                                                </ListItemText>
                                            </ListItem>

                                        </List>


                                    </>

                                )
                            }}></Column>

                    <Column field="netWorth" header="Net worth"
                            sortable
                            sortOrder={-1}
                            headerStyle={Object.assign(accountHeaderStyle, {minWidth: "25em"})} bodyStyle={bodyStyle}
                            body={(params) => {
                                return (<>
                                        <List><ListItem>

                                            <ListItemText variant="body1" primary={"Net Worth"}
                                                          primaryTypographyProps={{
                                                              style: {
                                                                  fontWeight: "550",
                                                                  color: "black",
                                                                  fontSize: "1rem"
                                                              },
                                                              sx: {
                                                                  fontFamily: "Poppins !important",
                                                                  fontSize: "0.875rem"
                                                              }
                                                          }}
                                                          secondaryTypographyProps={{
                                                              style: {
                                                                  fontWeight: "550",
                                                                  fontSize: "1rem"
                                                              },
                                                              sx: {
                                                                  fontFamily: "Poppins !important",
                                                                  fontSize: "0.875rem"
                                                              }
                                                          }}
                                                          secondary={prettyPrint(params.netWorth) + "$"}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                        </List>


                                    </>

                                )
                            }}></Column>

                    <Column field="address" header=""
                            headerStyle={Object.assign(accountHeaderStyle, {minWidth: "25em"})} bodyStyle={bodyStyle}
                            body={(params) => {
                                return (<>
                                        <List><ListItem>

                                            <ListItemText variant="body1" primary={"Last Synced Time"}
                                                          primaryTypographyProps={{
                                                              style: {
                                                                  fontWeight: "550",
                                                                  color: "black",
                                                                  fontSize: "1rem"
                                                              },
                                                              sx: {
                                                                  fontFamily: "Poppins !important",
                                                                  fontSize: "0.875rem"
                                                              }
                                                          }}
                                                          secondaryTypographyProps={{
                                                              style: {
                                                                  fontWeight: "550",
                                                                  fontSize: "1rem"
                                                              },
                                                              sx: {
                                                                  fontFamily: "Poppins !important",
                                                                  fontSize: "0.875rem"
                                                              }
                                                          }}
                                                          secondary={params.lastSyncedPortfolioTime == 0 ? "Not Synced" : moment(params.lastSyncedPortfolioTime).fromNow()}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                        </List>


                                    </>

                                )
                            }}></Column>
                    {/*<Column field="label" header={""} editor={(options) => textEditor(options, {minWidth: "15em"})}*/}
                    {/*        headerStyle={Object.assign(accountHeaderStyle, {minWidth: "15em"})}*/}
                    {/*        bodyStyle={bodyStyle}></Column>*/}
                    {/*<Column field="myWallet" header={"Own Account"} editor={(options)=>{*/}
                    {/*    accountStatusEditor(options)*/}
                    {/*}} body={(params)=>{*/}
                    {/*    return (<>*/}
                    {/*        {params.myWallet == true ? <i class="pi pi-check" color={"green"}></i>: <span> - </span>}*/}

                    {/*    </>)*/}
                    {/*}} headerStyle={accountHeaderStyle} bodyStyle={bodyStyle}></Column>*/}
                    <Column rowEditor headerStyle={Object.assign(accountHeaderStyle, {width: '10%', minWidth: '8rem'})}
                            bodyStyle={{textAlign: 'center'}} body={(param) => {
                        return actionButtons(param)
                    }}></Column>

                </DataTable>
                {/*<Table columns={columns} rows={rows} />*/}
            </SoftBox>
        </Card>
    );
}

export default Accounts;
