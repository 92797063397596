import React, {useState, useEffect} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {
    getAssetByAddressFromPrimaryAssets,
    getPrimaryAssets, getTransferInData, getTransferOutData,
    isTransactionIncludesToken, isTransactionIncludesTokenCompareByAddress, prettyPrint,
    sortTransactionsByTimestamp,
    timestampToHumanReadable
} from "../../common/utils";
import GradientLineChart from "../../examples/Charts/LineCharts/GradientLineChart";
import SoftBox from "../SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "../SoftTypography";
import Grid from "@mui/material/Grid";
import typography from "../../assets/theme/base/typography";
import dialogTitle from "../../assets/theme/components/dialog/dialogTitle";
import MiniStatisticsCard from "../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import gradientLineChartData from "../../layouts/dashboard/data/gradientLineChartData";
import Accounts from "../../layouts/dashboard/components/Accounts";
import {Chip} from "primereact/chip";
import Card from "@mui/material/Card";
import {RenderTxHahCell} from "../common";

function tokenTrackerConfig(labels, datasets) {
    return {
        data: {
            labels,
            datasets: [...datasets],
        },
        responsive: true,
        maintainAspectRatio: false,
        options: {
            parsing: {
                xAxisKey: 'timeStamp',
                yAxisKey: 'price'
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    },
                    display: false
                },
                tooltip: {
                    enabled: true,
                    titleMarginBottom: 12,
                    callbacks: {

                        // beforeTitle(tooltipItems) {
                        //     return tooltipItems[0].raw.type
                        // },

                        afterTitle(tooltipItems) {
                            let text = "Operation: " + tooltipItems[0].raw.type
                            let sign = tooltipItems[0].raw.transferType == "in" ? "+" : "-"
                            text += "\nAmount: " + sign + prettyPrint(tooltipItems[0].raw.amount) + " " + tooltipItems[0].raw.symbol + " (" + prettyPrint(tooltipItems[0].raw.usdValue) + "$" + ")"
                            text += "\nPrice: " + prettyPrint(tooltipItems[0].raw.price) + " $"
                            return text
                        },

                        footer(tooltipItems) {
                            let text = "Balance After: " + prettyPrint(tooltipItems[0].raw.balanceAfter) + " " + tooltipItems[0].raw.symbol
                            text += "\nBalance After ($): " + prettyPrint(tooltipItems[0].raw.balanceAfter * tooltipItems[0].raw.price) + "$"

                            return text
                        },


                        title(tooltipItems) {
                            return timestampToHumanReadable(tooltipItems[0].raw.timeStamp * 1000)
                        },

                        label(tooltipItems) {
                            return ""
                        }


                    },


                }

            },
            interaction: {
                intersect: false,
                mode: "index",
            },
            scales: {
                y: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                    },
                    ticks: {
                        display: true,
                        padding: 10,
                        color: "#b2b9bf",
                        font: {
                            size: 11,
                            family: typography.fontFamily,
                            style: "normal",
                            lineHeight: 2,
                        },
                    },
                },
                x: {
                    grid: {
                        drawBorder: false,
                        display: false,
                        drawOnChartArea: false,
                        drawTicks: false,
                        borderDash: [5, 5],
                    },
                    ticks: {
                        display: true,
                        color: "#b2b9bf",
                        padding: 20,
                        font: {
                            size: 11,
                            family: typography.fontFamily,
                            style: "normal",
                            lineHeight: 2,
                        },
                    },
                },
            },
        },
    };
}


export function TokenTracker({txs, tokenData, dialogVisible, setDialogVisible, tokenHoldingData}) {
    let tokenSymbol = tokenData.symbol
    let tokenAddress = tokenData.token_address
    let tokenTxs = txs.filter(x => isTransactionIncludesTokenCompareByAddress(tokenAddress, x)).filter(x => x.operation != "Failed")
    let txSorted = sortTransactionsByTimestamp(tokenTxs, "asc")

    const tokenLineChartData = {
        labels: txSorted.map(x => timestampToHumanReadable(x.timeStamp * 1000)),
        datasets: [
            {
                label: "Balance After",
                color: "info",
                borderWidth: 1,
                pointStyle: 'rectRot',
                pointRadius: 5,
                pointBorderColor: 'rgb(0, 0, 0)',
                data: txSorted.map(x => {
                    let type
                    let amount
                    let price
                    let balanceAfter
                    let usdValue
                    let data
                    let timeStamp
                    let transferType

                    let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(x, tokenAddress.toLowerCase())
                    if (tokenIn != undefined) {
                        if (tokenIn.tokenInfo.addr?.toLowerCase() == tokenAddress?.toLowerCase()) {
                            balanceAfter = tokenIn.balanceAfter
                            price = tokenIn.price
                            type = x.operation
                            timeStamp = x.timeStamp
                            amount = tokenIn.amount
                            usdValue = tokenIn.usdValueAmount
                            transferType = "in"
                            return {
                                "type": type,
                                "price": price,
                                "amount": amount,
                                "timeStamp": timeStamp,
                                "usdValue": usdValue,
                                "balanceAfter": balanceAfter,
                                "symbol": tokenIn.tokenInfo.symbol,
                                "transferType": "in"
                            }
                        }
                    }

                    if (tokenOut != undefined) {
                        if (tokenOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                            balanceAfter = tokenOut.balanceAfter
                            price = tokenOut.price
                            timeStamp = x.timeStamp
                            amount = tokenOut.amount
                            type = x.operation
                            usdValue = tokenOut.usdValueAmount
                            return {
                                "type": type,
                                "price": price,
                                "timeStamp": timeStamp,
                                "amount": amount,
                                "usdValue": usdValue,
                                "balanceAfter": balanceAfter,
                                "symbol": tokenOut.tokenInfo.symbol,
                                "transferType": "out"
                            }
                        }
                    }
                    return undefined
                }).filter(x => x != undefined),
            },
            // {
            //   label: "Websites",
            //   color: "dark",
            //   data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
            // },
        ],
    };
    const headerStyle = {"background": "none"}
    const bodyStyle = {"color": "black", fontSize: "13px", fontWeight: "700"}
    return (
        <>
            <Dialog header="Token Summary"
                    visible={dialogVisible}
                    style={{width: '80vw', zIndex: "3000"}}
                    maximizable baseZIndex={3000}
                    modal contentStyle={{height: '700px'}}
                    onHide={() => setDialogVisible(false)}>
                <Grid item xs={12} lg={12}>

                    <SoftBox py={3}>
                        <SoftBox mb={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <MiniStatisticsCard
                                        title={{text: "Total Transactions"}}
                                        count={txSorted.length}
                                        // percentage={{ color: "success", text: "+55%" }}
                                        icon={{color: "info", component: "paid"}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <MiniStatisticsCard
                                        title={{text: "Current Balance"}}
                                        count={prettyPrint(Number(tokenHoldingData?.amount_of_tokens || 0)) + " " + tokenSymbol}
                                        percentage={{
                                            color: "success",
                                            text: `${prettyPrint(tokenHoldingData?.amountInUSD || 0)}$`
                                        }}
                                        icon={{color: "info", component: "paid"}}
                                    />
                                </Grid>
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <MiniStatisticsCard*/}
                                {/*        title={{ text: "Transfer In"}}*/}
                                {/*        count={prettyPrint(getTransferInData(tokenTxs, tokenSymbol)[0]) + " " + tokenSymbol}*/}
                                {/*        percentage={{ color: "success", text: `${prettyPrint(getTransferInData(tokenTxs, tokenSymbol)[0])}$`}}*/}
                                {/*        icon={{ color: "info", component: "paid" }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <MiniStatisticsCard*/}
                                {/*        title={{ text: "Transfer Out"}}*/}
                                {/*        count={prettyPrint(Number(getTransferOutData(tokenTxs, tokenSymbol)[0])) + " " + tokenSymbol}*/}
                                {/*        percentage={{ color: "success", text: `${prettyPrint(getTransferOutData(tokenTxs, tokenSymbol)[1])}$`}}*/}
                                {/*        icon={{ color: "info", component: "paid" }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <MiniStatisticsCard*/}
                                {/*        title={{ text: "Total Bought"}}*/}
                                {/*        count={prettyPrint(Number(tokenHoldingData?.amount_of_tokens || 0 )) + " " + tokenSymbol}*/}
                                {/*        percentage={{ color: "success", text: `${prettyPrint(tokenHoldingData?.amountInUSD || 0)}$`}}*/}
                                {/*        icon={{ color: "info", component: "paid" }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <MiniStatisticsCard*/}
                                {/*        title={{ text: "Total Sold"}}*/}
                                {/*        count={prettyPrint(Number(tokenHoldingData?.amount_of_tokens || 0 )) + " " + tokenSymbol}*/}
                                {/*        percentage={{ color: "success", text: `${prettyPrint(tokenHoldingData?.amountInUSD || 0)}$`}}*/}
                                {/*        icon={{ color: "info", component: "paid" }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={6} xl={3}>*/}
                                {/*    <MiniStatisticsCard*/}
                                {/*        title={{ text: "today's users" }}*/}
                                {/*        count="2,300"*/}
                                {/*        percentage={{ color: "success", text: "+3%" }}*/}
                                {/*        icon={{ color: "info", component: "public" }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={6} xl={3}>*/}
                                {/*    <MiniStatisticsCard*/}
                                {/*        title={{ text: "new clients" }}*/}
                                {/*        count="+3,462"*/}
                                {/*        percentage={{ color: "error", text: "-2%" }}*/}
                                {/*        icon={{ color: "info", component: "emoji_events" }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={6} xl={3}>*/}
                                {/*    <MiniStatisticsCard*/}
                                {/*        title={{ text: "sales" }}*/}
                                {/*        count="$103,430"*/}
                                {/*        percentage={{ color: "success", text: "+5%" }}*/}
                                {/*        icon={{*/}
                                {/*            color: "info",*/}
                                {/*            component: "shopping_cart",*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                            </Grid>
                        </SoftBox>
                        {/*<SoftBox mb={3}>*/}
                        {/*  <Grid container spacing={3}>*/}
                        {/*    <Grid item xs={12} lg={7}>*/}
                        {/*      <BuildByDevelopers />*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} lg={5}>*/}
                        {/*      <WorkWithTheRockets />*/}
                        {/*    </Grid>*/}
                        {/*  </Grid>*/}
                        {/*</SoftBox>*/}


                    </SoftBox>

                </Grid>


                <Grid item xs={12} lg={12}>
                    <GradientLineChart
                        chartConfig={tokenTrackerConfig}
                        title="Token Overview"
                        description={
                            <SoftBox display="flex" alignItems="center">
                                {/*<SoftBox color="success" mb={0.3} mr={0.5} lineHeight={0}>*/}
                                {/*    <Icon className="font-bold">arrow_upward</Icon>*/}
                                {/*</SoftBox>*/}
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                    All the interactions with the token
                                    {/*<SoftTypography variant="button" color="text" fontWeight="regular">*/}
                                    {/*    in 2021*/}
                                    {/*</SoftTypography>*/}
                                </SoftTypography>
                            </SoftBox>
                        }

                        height="fit-content"
                        chart={tokenLineChartData}
                    />
                </Grid>

                <Grid item xs={12} lg={12} style={{marginTop: "30px"}}>
                    <Card>
                        <DataTable value={txSorted} paginator
                                   sortField={"timeStamp"}
                                   sortOrder={1}
                                   rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown">
                            <Column field="timeStamp"

                                    sortable={true} header="Date" body={(row) => {
                                return timestampToHumanReadable(row.timeStamp * 1000)
                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>

                            <Column field="hash" header="Hash" body={(row) => {
                                return RenderTxHahCell(row)
                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>
                            <Column field="operation" header="Operation" bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>

                            <Column field="blockTimestamp" header="Balance Change" body={(row) => {
                                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress)
                                if (tokenIn && tokenIn.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                    return <span><span
                                        style={{color: "green"}}>+ {prettyPrint(tokenIn.amount)} {tokenIn.tokenInfo.symbol}</span></span>
                                } else if (tokenOut && tokenOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                    return <span> <span
                                        style={{color: "red"}}>- {prettyPrint(tokenOut.amount)} {tokenOut.tokenInfo.symbol}</span></span>
                                }

                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>

                            <Column field="blockTimestamp" header="USD Value" body={(row) => {
                                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress)
                                if (tokenIn && tokenIn.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                    return <span><span
                                        style={{color: "green"}}>+ {prettyPrint(tokenIn.amount * tokenIn.price) + "$"}</span></span>
                                } else if (tokenOut && tokenOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                    return <span> <span
                                        style={{color: "red"}}>- {prettyPrint(tokenOut.amount * tokenOut.price) + "$"}</span></span>
                                }

                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>

                            <Column field="timeStamp" header="Historical Price" body={(row) => {
                                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress)

                                if (tokenIn && tokenIn.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                    return <span>{prettyPrint(tokenIn.price) + "$"}</span>
                                } else if (tokenOut && tokenOut.tokenInfo.addr.toLowerCase() == tokenAddress.toLowerCase()) {
                                    return <span> {prettyPrint(tokenOut.price) + "$"}</span>
                                }

                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>
                            <Column field="timeStamp" header="Balance After" body={(row) => {
                                let [tokenIn, tokenOut] = getAssetByAddressFromPrimaryAssets(row, tokenAddress)
                                if (tokenIn) {
                                    return <span>{prettyPrint(Math.abs(tokenIn.balanceAfter)) + " " + tokenSymbol + " (≈" + prettyPrint(Math.abs(tokenIn.balanceAfter) * tokenIn.price) + "$)"}</span>
                                } else if (tokenOut) {
                                    return <span> {prettyPrint(Math.abs(tokenOut.balanceAfter)) + " " + tokenSymbol + " (≈" + prettyPrint(Math.abs(tokenOut.balanceAfter) * tokenOut.price) + "$)"}</span>
                                }

                            }} bodyStyle={bodyStyle} headerStyle={headerStyle}></Column>

                        </DataTable>
                    </Card>

                </Grid>
            </Dialog>
        </>)
}