/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useMemo, useRef, useState} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import {v4 as uuidv4} from "uuid";

// @mui material components
import {
    ListItem, ListItemAvatar,
    ListItemText,
    Table as MuiTable,
    TableCell,
    TableFooter,
    TablePagination,
    TableSortLabel,
    useMediaQuery
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import * as React from 'react';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import {
    ArrowCircleDown,
    ArrowCircleUpRounded,
    ArrowDownward,
    ArrowUpward, CodeOutlined, Error, HelpOutline, InfoOutlined, LaunchOutlined,
    MultipleStopOutlined
} from "@mui/icons-material";


import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {makeStyles} from "@mui/styles";

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Link from "@mui/material/Link";
import {
    calculateAccountUSDValue,
    getAccountLink,
    getAllTransactionData,
    getChainLogo,
    getNativeTokenNameByChain,
    getPrimaryAssets,
    getRemoveADDLQTokens,
    getTokens,
    getTransactionLink,
    getYearReports,
    parseMethod,
    prettyPrint,
    prettyPrintAddress,
    timestampToHumanReadable
} from "../../common/utils";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import {useRef} from "@types/react";
import {TieredMenu} from "primereact/tieredmenu";
import {Button} from "primereact/button";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import List from "@mui/material/List";
import {Chip} from 'primereact/chip';
import "./styles.css"
import Avatar from "@mui/material/Avatar";
import {Toolbar} from 'primereact/toolbar';
import {TabMenu} from "primereact/tabmenu";
import {TokenTracker} from "../TokenTracker";
import ListItemIcon from "@mui/material/ListItemIcon";
import TokenIcon from '@mui/icons-material/Token';
import {Badge} from "@nextui-org/react";
import {TokenHoldingPieChart} from "../TokenHoldingPieChart";
import Grid from "@mui/material/Grid";
import {RenderCurvePoolCell, RenderSimpleNumberField, RenderTokenCell} from "../common";
import {YearFinancialReports} from "../YearFinancialReports";
import {LPPositions} from "../LPPositions";
import {NFTPosition} from "../NFTPosition";
import _, {sortBy} from "lodash";
import {StakingPositions} from "../StakingPositions";


const useStyles = makeStyles({
    customDataGrid: {
        "& .MuiDataGrid-colHeaderWrapper": {
            display: 'flex',
        },
        "& .MuiDataGrid-colHeaderCell": {
            flex: 1,
        },
        "&": {
            border: "none !important"
        },
        "& .MuiDataGrid-row:hover": {
            // backgroundColor:"rgb(250, 252, 255) !important"
        },
        "& .MuiDataGrid-row:select": {
            backgroundColor: "white"
        },

        maxWidth: "100vw"
    },
    centerTable: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        maxWidth: '80vh'
    },
    rowStyle: {
        padding: '.75em',
        backgroundColor: "#F9FCFF !important",
        minHeight: "100px !important",
        minWidth: "100vw",
        color: "blue",

        fontFamily: "Ariel !important",
        "& .MuiDataGrid-colCellTitle": {
            width: '100%',
        },
    },
    customRow: {
        // backgroundColor:"#FFFFFF !important",
        // minWidth: "fit-content !important",
        borderBottom: "1px solid rgb(231, 235, 240) !important"
    },
    columnHeaderTitle: {
        // background:"red",
        fontFamily: "Roboto,Helvetica,Arial,sans-serif",
        color: "black",
        fontSize: "14px",
        fontWeight: "bold !important"
    },
    subText: {
        color: 'gray',

    },
    selectBoxText: {
        fontSize: "14px",

        color: "black !important",
        fontWeight: "bold"

    },
    operationText: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    nativeInput: {
        border: "0 !important"
    },
    select: {
        // background: "red",
        color: "red",
        fontSize: "10px",
        '&.MuiOutlinedInput': {
            borderColor: "blue !important"
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important',
        },
        minWidth: "20em"
        // '&:before': {
        //     borderColor: 'white !important',
        // },
        // '&:after': {
        //     borderColor: 'white !important',
        // },
        // '&:not(.Mui-disabled):hover::before': {
        //     borderColor: 'white !important',
        // },
        // backgroundColor: 'lightblue', // Change the background color to your desired color
        // border: '2px solid red', // Change the border style to your desired style
        // borderRadius: 4, // Change the border radius to your desired value
        // padding: '8px 16px',
        // border:"0px !important",
        // borderColor: 'red !important', // Change the border color to your desired color
        // borderWidth: "2 !important" , // Change the border width to your desired value
        // borderRadius: 4, // Change the border radius to your desired value
        // padding: '8px 16px', // Adjust the padding as needed
    },
    settingsChip: {
        // /*    display: 'flex',
        //     flexDirection: 'row',
        //     alignItems: "flex-start",
        //     justifyContent: 'flex-start',
        //     width:"100% !important"*/
    },
});

const theme = createTheme({
    typography: {
        subtitle1: {
            fontSize: 12,
        },
        body1: {
            fontWeight: "bold",
            fontSize: 14,
            color: "black"
        },

    },
});

const account = "0x05e793ce0c6027323ac150f6d45c2344d28b6019"

function DataGridTransactions({columns, rows, shouldPaginate = true}) {
    const {light} = colors;
    const {size, fontWeightBold} = typography;
    const {borderWidth} = borders;
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(20))
    const [page, setPage] = useState(0)
    const [tokenDialogOpen, setTokenDialogOpen] = useState(false)
    const [tableDate, setTableData] = rows
    const initialTableData = rows

    const classes = useStyles()
    useEffect(() => {
    }, [])
    useEffect(() => {
    }, [rowsPerPage, page])

    return (
        <div style={{height: 600, width: '100%'}}>
            <DataGrid
                autoWidth={true}
                sx={{
                    '.MuiDataGrid-footerContainer': {
                        alignItems: "flex-start",
                        justifyContent: "none"
                    },

                }}
                classes={{
                    root: classes.customDataGrid,
                    columnHeader: classes.rowStyle,
                    columnHeaderTitle: classes.columnHeaderTitle,
                    cell: classes.customRow
                }}
                getRowClassName={(params) => classes.customRow}
                getRowId={(row) => {
                    return row.hash
                }}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: rowsPerPage},
                    },
                }}
                // pageSizeOptions={[5,10]}
                checkboxSelection={true}
            ></DataGrid>


        </div>
    )

}

const RenderTxHahCell = (params) => {
    let link = getTransactionLink(params.chain, params.hash)

    return (

        <Link href={link} isExternal={true} target="_blank">
            {/*<LaunchOutlined sx={{ verticalAlign: 'middle', marginRight: '4px' }} />*/}
            <img alt="" src={getChainLogo(params.chain)}
                 style={{verticalAlign: 'middle', marginRight: '4px', maxWidth: "20px", maxHeight: "20px"}}/>
            {prettyPrintAddress(params.hash)}
        </Link>)
}


function RenderCellOperation(params) {
    const useStyles = makeStyles({
        customDataGrid: {
            "& .MuiDataGrid-colHeaderWrapper": {
                display: 'flex',
            },
            "& .MuiDataGrid-colHeaderCell": {
                flex: 1,
            },
            "&": {
                border: "none !important"
            },
            "& .MuiDataGrid-row:hover": {
                // backgroundColor:"rgb(250, 252, 255) !important"
            },
            "& .MuiDataGrid-row:select": {
                backgroundColor: "white"
            },

            maxWidth: "100vw"
        },
        centerTable: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            maxWidth: '80vh'
        },
        rowStyle: {
            padding: '.75em',
            backgroundColor: "#F9FCFF !important",
            minHeight: "100px !important",
            minWidth: "100vw",
            color: "blue",

            fontFamily: "Ariel !important",
            "& .MuiDataGrid-colCellTitle": {
                width: '100%',
            },
        },
        customRow: {
            // backgroundColor:"#FFFFFF !important",

            minHeight: "100px !important",
            borderBottom: "1px solid rgb(231, 235, 240) !important"
        },
        columnHeaderTitle: {
            // background:"red",
            color: "#617389",
            fontSize: "10px",
            fontWeight: "700"
        },
        primaryText: {
            fontSize: "14px !important",
            color: 'black !important',
            fontWeight: 'bold !important'

        },
        subText: {
            fontSize: "10px !important",
            color: 'gray !important',

        },
        selectBoxText: {
            fontSize: "14px",

            color: "black !important",
            fontWeight: "bold"

        },
        operationText: {
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
        },
        nativeInput: {
            border: "0 !important"
        },
        select: {
            // background: "red",
            color: "red",
            fontSize: "10px",
            '&.MuiOutlinedInput': {
                borderColor: "blue !important"
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red !important',
            },
            minWidth: "20em"
            // '&:before': {
            //     borderColor: 'white !important',
            // },
            // '&:after': {
            //     borderColor: 'white !important',
            // },
            // '&:not(.Mui-disabled):hover::before': {
            //     borderColor: 'white !important',
            // },
            // backgroundColor: 'lightblue', // Change the background color to your desired color
            // border: '2px solid red', // Change the border style to your desired style
            // borderRadius: 4, // Change the border radius to your desired value
            // padding: '8px 16px',
            // border:"0px !important",
            // borderColor: 'red !important', // Change the border color to your desired color
            // borderWidth: "2 !important" , // Change the border width to your desired value
            // borderRadius: 4, // Change the border radius to your desired value
            // padding: '8px 16px', // Adjust the padding as needed
        },
        settingsChip: {
            // /*    display: 'flex',
            //     flexDirection: 'row',
            //     alignItems: "flex-start",
            //     justifyContent: 'flex-start',
            //     width:"100% !important"*/
        },
    });

    const classes = useStyles();
    let isSwap = false
    let isTransferIn = false
    let isUnknownFunctionCall = false
    let isTransferETHOut = false

    let tokenIn
    let tokenOut
    let isFailed = params.operation == "Failed"

    // if the user called "transfer" function
    let isRegularTokenOutTransfer = false
    let isRegularTokenInTransfer = false
    let isAddLP = false
    let isRemoveLP = false
    let isAddLPUniV3 = false
    let collectFeesUniV3 = false
    if (!isFailed) {
        isSwap = params.operation == "Trade"
        isTransferETHOut = params.operation == "Send ETH"
        isTransferIn = params.operation == "Receive ETH"
        isRegularTokenOutTransfer = params.operation == "Send Token"
        isRegularTokenInTransfer = params.operation == "Receive Token"
        isAddLP = params.operation == "Add Liquidity"
        isRemoveLP = params.operation == "Remove Liquidity"
        isAddLPUniV3 = params.operation == "Uni-V3 Add Liquidity"
        collectFeesUniV3 = params.operation == "Collect Fees UniV3"
        isUnknownFunctionCall = !isSwap && !isTransferETHOut && !isRegularTokenInTransfer && !isRegularTokenOutTransfer && !isAddLP &&
            !isRemoveLP && !isTransferIn && !isAddLPUniV3 && !collectFeesUniV3
    }


    if (isSwap) {
        [tokenOut, tokenIn] = [params.tokenOutInfo, params.tokenInInfo]
    }


    return (
        <div style={{"display": "flex", "alignItems": 'center'}}>
            <IconButton>
                {isTransferIn || isRegularTokenInTransfer || collectFeesUniV3 ?
                    <ArrowCircleDown sx={{color: "green"}}/> : <></>}
                {isTransferETHOut || isRegularTokenOutTransfer ?
                    < ArrowCircleUpRounded sx={{color: "red"}} fill={true}/> : <></>}
                {isSwap || isAddLP || isRemoveLP || isAddLPUniV3 ? <MultipleStopOutlined sx={{color: "blue"}}/> : <></>}
                {isUnknownFunctionCall ? <CodeOutlined sx={{color: "gray"}}/> : <></>}
                {isFailed ? <Error sx={{color: "red"}}/> : <></>}

            </IconButton>
            <Box>
                {isTransferIn ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"}
                                            className={classes.primaryText}>Receive ETH</Typography> : <></>}
                {isTransferIn ? <Typography variant="caption" className={classes.subText}>
                    {isTransferIn ? "From " + prettyPrintAddress(params.from) : ""}
                </Typography> : <></>}
                {isRegularTokenInTransfer ?
                    <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"}
                                className={classes.primaryText}>Receive Token</Typography> : <></>}
                {isRegularTokenInTransfer ? <Typography variant="caption" className={classes.subText}>
                    {isTransferIn ? "From " + prettyPrintAddress(params.from) : ""}
                </Typography> : <></>}

                {isRegularTokenOutTransfer ?
                    <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"}
                                className={classes.primaryText}>Send Token</Typography> : <></>}
                {isRegularTokenOutTransfer ? <Typography variant="caption" className={classes.subText}>
                    {isRegularTokenOutTransfer ? "To " + prettyPrintAddress(params.to) : ""}
                </Typography> : <></>}

                {isFailed ? <Typography className={classes.primaryText}>Failed</Typography> : <></>}

                {isTransferETHOut ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"}
                                                className={classes.primaryText}>Send ETH</Typography> : <></>}
                {isTransferETHOut ? <Typography variant="caption" className={classes.subText}>
                    {isTransferETHOut ? "To " + prettyPrintAddress(params.to) : ""}
                </Typography> : <></>}

                {isSwap ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"}
                                      className={classes.primaryText}>Trade</Typography> : <></>}
                {isSwap ? <Typography variant="caption" className={classes.subText}>
                    {isSwap ? "Swap" + " " + tokenOut?.symbol + " " + "for" + " " + tokenIn?.symbol : ""}
                </Typography> : <></>}

                {isAddLP || isAddLPUniV3 ?
                    <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"}
                                className={classes.primaryText}>Add Liquidity</Typography> : <></>}
                {isAddLP || isAddLPUniV3 ? <Typography variant="caption" className={classes.subText}>
                    {``}
                </Typography> : <></>}

                {collectFeesUniV3 ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"}
                                                className={classes.primaryText}>Collect Fees</Typography> : <></>}


                {isRemoveLP ? <Typography variant="body1" fontWeight="bold" color={"black"} fontSize={"14px"}
                                          className={classes.primaryText}>Remove Liquidity</Typography> : <></>}
                {isRemoveLP ? <Typography variant="caption" className={classes.subText}>
                    {``}
                </Typography> : <></>}

                {isUnknownFunctionCall ? <Typography variant="body1" fontWeight="bold" color={"black"}
                                                     className={classes.primaryText}>{parseMethod(params.method)}</Typography> : <></>}
                {isUnknownFunctionCall ? <Typography variant="caption" className={classes.subText}>
                    {isUnknownFunctionCall ? "Call" + " " + prettyPrintAddress(params.to) : ""}
                </Typography> : <></>}
            </Box>

        </div>

    );
};

const RenderMoreActions = (params) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const menu = useRef(null);
    const items = [
        {
            label: 'Hide Transaction',
            icon: 'pi pi-eye-slash',

        },
        {

            label: 'Change Type',
            icon: 'pi pi-eye-slash',
            items: [
                {
                    label: 'Trade',
                    icon: 'pi pi-arrow-right-arrow-left',
                    // items:[
                    //     {
                    //         label:'Bookmark',
                    //         icon:'pi pi-fw pi-bookmark'
                    //     },
                    //     {
                    //         label:'Video',
                    //         icon:'pi pi-fw pi-video'
                    //     },
                    //
                    // ]
                },
                {
                    label: 'Send Token',
                    icon: 'pi pi-arrow-up'
                },
                {
                    label: 'Receive Token',
                    icon: 'pi pi-arrow-down'
                },
                // {
                //     separator:true
                // },
                // {
                //     label:'Export',
                //     icon:'pi pi-fw pi-external-link'
                // }
            ]
        },
        {
            label: 'Note',
            icon: 'pi pi-fw pi-pencil',
            // items:[
            //     {
            //         label:'Left',
            //         icon:'pi pi-fw pi-align-left'
            //     },
            //     {
            //         label:'Right',
            //         icon:'pi pi-fw pi-align-right'
            //     },
            //     {
            //         label:'Center',
            //         icon:'pi pi-fw pi-align-center'
            //     },
            //     {
            //         label:'Justify',
            //         icon:'pi pi-fw pi-align-justify'
            //     },
            //
            // ]
        },
        {
            label: 'Set Cost Basis',
            icon: 'pi pi-dollar',
            // items:[
            //     {
            //         label:'Left',
            //         icon:'pi pi-fw pi-align-left'
            //     },
            //     {
            //         label:'Right',
            //         icon:'pi pi-fw pi-align-right'
            //     },
            //     {
            //         label:'Center',
            //         icon:'pi pi-fw pi-align-center'
            //     },
            //     {
            //         label:'Justify',
            //         icon:'pi pi-fw pi-align-justify'
            //     },
            //
            // ]
        },
        {
            label: 'P&L Explained',
            icon: 'pi pi-question-circle',
            // items:[
            //     {
            //         label:'Left',
            //         icon:'pi pi-fw pi-align-left'
            //     },
            //     {
            //         label:'Right',
            //         icon:'pi pi-fw pi-align-right'
            //     },
            //     {
            //         label:'Center',
            //         icon:'pi pi-fw pi-align-center'
            //     },
            //     {
            //         label:'Justify',
            //         icon:'pi pi-fw pi-align-justify'
            //     },
            //
            // ]
        },
        {
            separator: true
        },
        {
            label: 'Transaction',
            icon: 'pi pi-external-link blue-600',
            // items:[
            //     {
            //         label:'Left',
            //         icon:'pi pi-fw pi-align-left'
            //     },
            //     {
            //         label:'Right',
            //         icon:'pi pi-fw pi-align-right'
            //     },
            //     {
            //         label:'Center',
            //         icon:'pi pi-fw pi-align-center'
            //     },
            //     {
            //         label:'Justify',
            //         icon:'pi pi-fw pi-align-justify'
            //     },
            //
            // ]
        },
        // {
        //     label:'Users',
        //     icon:'pi pi-fw pi-user',
        //     items:[
        //         {
        //             label:'New',
        //             icon:'pi pi-fw pi-user-plus',
        //
        //         },
        //         {
        //             label:'Delete',
        //             icon:'pi pi-fw pi-user-minus',
        //
        //         },
        //         {
        //             label:'Search',
        //             icon:'pi pi-fw pi-users',
        //             items:[
        //                 {
        //                     label:'Filter',
        //                     icon:'pi pi-fw pi-filter',
        //                     items:[
        //                         {
        //                             label:'Print',
        //                             icon:'pi pi-fw pi-print'
        //                         }
        //                     ]
        //                 },
        //                 {
        //                     icon:'pi pi-fw pi-bars',
        //                     label:'List'
        //                 }
        //             ]
        //         }
        //     ]
        // },
        // {
        //     label:'Events',
        //     icon:'pi pi-fw pi-calendar',
        //     items:[
        //         {
        //             label:'Edit',
        //             icon:'pi pi-fw pi-pencil',
        //             items:[
        //                 {
        //                     label:'Save',
        //                     icon:'pi pi-fw pi-calendar-plus'
        //                 },
        //                 {
        //                     label:'Delete',
        //                     icon:'pi pi-fw pi-calendar-minus'
        //                 }
        //             ]
        //         },
        //         {
        //             label:'Archive',
        //             icon:'pi pi-fw pi-calendar-times',
        //             items:[
        //                 {
        //                     label:'Remove',
        //                     icon:'pi pi-fw pi-calendar-minus'
        //                 }
        //             ]
        //         }
        //     ]
        // },
        {
            separator: true
        },


    ];

    return (<Box onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}
                 style={{width: "100%", height: "100%", display: "flex", alignItems: "center"}}
        >


            {/*<Tooltip title="Edit">*/}
            <div>

                <TieredMenu model={items} popup ref={menu} style={{
                    boxShadow: "rgba(194, 203, 216, 0.4) 0px 24px 56px !important",
                    borderRadius: "5px",
                    minWidth: "250px",
                    fontSize: "10px",
                    fontWeight: "700",
                    color: "black"
                }}/>


                <Button tooltip={"Edit"} tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}}
                        rounded text icon="pi pi-ellipsis-h" onClick={(event) => menu.current.toggle(event)}/>


            </div>
            {/*</Tooltip>*/}
        </Box>
    )
};


const RenderAddRemoveLPTX = (params) => {
    let color = params.operation == "Add Liquidity" ? "red" : "green"
    let sign = params.operation == "Add Liquidity" ? "-" : "+"
    let tokens = params.operation == "Add Liquidity" ? params.tokensOut : params.tokensIn

    let t = _.sortBy(tokens, "usdValueAmount")
    let sortedTokensIn = t.reverse()
    let [token0, token1] = [sortedTokensIn[0], sortedTokensIn[1]]

    return (<>
            <ThemeProvider theme={theme}>
                <List padding={0}>

                    <ListItem className={"listStyle"}>
                        <ListItemAvatar className={"tokenLogoAvatar"}>
                            {token0.tokenInfo?.logoCMC &&
                                <Avatar src={token0.tokenInfo?.logoCMC} className={"tokenLogo"}></Avatar>}
                        </ListItemAvatar>
                        <ListItemText
                            classes={{primary: "primaryTextTable", secondary: "secondaryText"}}
                            primaryTypographyProps={{style: {color: color}}}
                            primary={`${sign}${prettyPrint(token0.amount)} ${token0.tokenInfo.symbol}`}
                            secondary={`${prettyPrint(token0.usdValueAmount)}$`}
                            secondaryTypographyProps={{style: {color: color, fontSize: "10px"}}}
                            // secondary=
                            //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                            //     <ListItem>Per Basis: 100$</ListItem>
                            //   </List>}

                        >
                            {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                        </ListItemText>
                    </ListItem>
                    <ListItem className={"listStyle"}>
                        <ListItemAvatar className={"tokenLogoAvatar"}>
                            {token1.tokenInfo?.logoCMC &&
                                <Avatar src={token1.tokenInfo?.logoCMC} className={"tokenLogo"}></Avatar>}
                        </ListItemAvatar>
                        <ListItemText
                            classes={{primary: "primaryTextTable", secondary: "secondaryText"}}
                            primaryTypographyProps={{style: {color: color}}}
                            primary={`${sign}${prettyPrint(token1.amount)} ${token1.tokenInfo.symbol}`}
                            secondary={`${prettyPrint(token1.usdValueAmount)}$`}
                            secondaryTypographyProps={{style: {color: color}}}
                            // secondary=
                            //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                            //     <ListItem>Per Basis: 100$</ListItem>
                            //   </List>}

                        >
                            {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                        </ListItemText>
                    </ListItem>


                </List>
            </ThemeProvider>

        </>
    )


}


const RenderAddRemoveLPUniV3TX = (params, name) => {
    let color = params.operation == "Uni-V3 Add Liquidity" ? "red" : "green"
    let sign = params.operation == "Uni-V3 Add Liquidity" ? "-" : "+"
    let token0
    let token1
    if (name == "tokensOut") {
        token0 = params.tokenOutPrimary[0]
        token1 = params.tokenOutPrimary[1]
    } else if (name == "tokensIn") {
        token0 = params.tokenInPrimary[0]
        token1 = params.tokenInPrimary[1]
    }
    return (<>
            <ThemeProvider theme={theme}>
                <List padding={0}>

                    {token0 && <ListItem className={"listStyle"}>
                        <ListItemAvatar className={"tokenLogoAvatar"}>
                            {token0.tokenInfo?.logoCMC &&
                                <Avatar src={token0.tokenInfo?.logoCMC} className={"tokenLogo"}></Avatar>}
                        </ListItemAvatar>
                        <ListItemText
                            classes={{primary: "primaryTextTable", secondary: "secondaryText"}}
                            primaryTypographyProps={{style: {color: color}}}
                            primary={`${sign}${prettyPrint(token0.amount)} ${token0.tokenInfo.symbol}`}
                            secondary={`${prettyPrint(token0.usdValueAmount)}$`}
                            // secondary=
                            //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                            //     <ListItem>Per Basis: 100$</ListItem>
                            //   </List>}

                        >
                            {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                        </ListItemText>
                    </ListItem>}
                    {token1 && <ListItem className={"listStyle"}>
                        <ListItemAvatar style={{cursor: "pointer"}} className={"tokenLogoAvatar"}>
                            {token1.tokenInfo?.logoCMC &&
                                <Avatar src={token1.tokenInfo?.logoCMC} className={"tokenLogo"}></Avatar>}
                        </ListItemAvatar>
                        <ListItemText
                            classes={{primary: "primaryTextTable", secondary: "secondaryText"}}
                            primaryTypographyProps={{style: {color: color}}}
                            primary={`${sign}${prettyPrint(token1.amount)} ${token1.tokenInfo.symbol}`}
                            secondary={`${prettyPrint(token1.usdValueAmount)}$`}
                            // secondary=
                            //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                            //     <ListItem>Per Basis: 100$</ListItem>
                            //   </List>}

                        >
                            {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                        </ListItemText>
                    </ListItem>}


                </List>
            </ThemeProvider>

        </>
    )


}


const RenderTokensInCell = (params, onClickToken, name) => {
    let tokens = params[name]

    let [primaryIn, primaryOut] = getPrimaryAssets(params)
    let sortedTokensIn = [...tokens].sort((a, b) => b.usdValueAmount - a.usdValueAmount)

    if (name == "tokensOut") {
        sortedTokensIn = primaryOut ? [primaryOut] : []
    } else {
        sortedTokensIn = primaryIn ? [primaryIn] : []
    }


    return (<>
            <ThemeProvider theme={theme}>
                <List>
                    {sortedTokensIn.length < 2 && sortedTokensIn.map((item, index) => (

                        <ListItem key={index} className={"listStyle"}>
                            <ListItemAvatar className={"tokenLogoAvatar"} onClick={() => {
                                onClickToken({
                                    "symbol": sortedTokensIn[0].tokenInfo.symbol,
                                    "token_address": sortedTokensIn[0].tokenInfo.addr
                                })
                            }}>
                                {item.tokenInfo?.logoCMC && <Avatar src={item.tokenInfo?.logoCMC}
                                                                    className={"tokenLogo"}></Avatar>}
                                {sortedTokensIn[0].tokenInfo?.logoCMC == undefined &&
                                    <Avatar style={{width: "100%", height: "auto"}}>
                                        <TokenIcon></TokenIcon>
                                    </Avatar>}
                            </ListItemAvatar>
                            <ListItemText key={index}
                                          classes={{primary: "primaryTextTable", secondary: "secondaryText"}}
                                          primaryTypographyProps={{
                                              style: {
                                                  color: name === "tokensIn" ? "green" : "red",
                                                  fontSize: "14px"
                                              }
                                          }}
                                          primary={`${name === "tokensIn" ? "+" : "-"} ${prettyPrint(item.amount)} ${item.tokenInfo.symbol}`}
                                          secondary={`${prettyPrint(item.usdValueAmount)}$`}
                                // secondary=
                                //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                                //     <ListItem>Per Basis: 100$</ListItem>
                                //   </List>}

                            >
                                {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                            </ListItemText>
                        </ListItem>

                    ))

                    }

                    {sortedTokensIn.length >= 2 && params.operation == "Trade" && (
                        <ListItem>
                            <ListItemAvatar
                                style={{cursor: "pointer", maxWidth: "30px", marginRight: "10px", minWidth: "0px"}}
                                onClick={() => {
                                    onClickToken({
                                        "symbol": sortedTokensIn[0].tokenInfo.symbol,
                                        "token_address": sortedTokensIn[0].tokenInfo.addr
                                    })
                                }}>
                                {sortedTokensIn[0].tokenInfo?.logoCMC != undefined &&
                                    <Avatar src={sortedTokensIn[0].tokenInfo?.logoCMC}
                                            style={{width: "100%", height: "auto"}}></Avatar>}

                                {sortedTokensIn[0].tokenInfo?.logoCMC == undefined &&
                                    <Avatar style={{width: "100%", height: "auto"}}>
                                        <TokenIcon></TokenIcon>
                                    </Avatar>}

                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    style: {
                                        color: name === "tokensIn" ? "green" : "red",
                                        fontSize: "14px"
                                    }
                                }}
                                secondaryTypographyProps={{style: {fontSize: "10px"}}}
                                primary={`${name === "tokensIn" ? "+" : "-"} ${prettyPrint(sortedTokensIn[0].amount)} ${sortedTokensIn[0].tokenInfo.symbol}`}
                                secondary={`${prettyPrint(sortedTokensIn[0].usdValueAmount)}$`}


                            >
                            </ListItemText>
                        </ListItem>


                    )}


                    {sortedTokensIn.length >= 2 && params.operation != "Trade" && (
                        <ListItemText
                            primaryTypographyProps={{style: {color: name === "tokensIn" ? "green" : "red"}}}
                            primary={`${name === "tokensIn" ? "+" : "-"} ${sortedTokensIn.length} tokens`}
                            // secondary={`${prettyPrint(item.usdValueAmount)}$`}
                            // secondary=
                            //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                            //     <ListItem>Per Basis: 100$</ListItem>
                            //   </List>}

                        >
                            {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                        </ListItemText>

                    )}


                </List>
            </ThemeProvider>

        </>
    )
};

const RenderTokensOutCell = (params, onClickToken) => {
    let name = "tokensOut"
    // sort by the once with the most financial value
    let sortedTokensIn = [...params.tokensOut].sort((a, b) => b.usdValueAmount - a.usdValueAmount)

    return (<>
            <ThemeProvider theme={theme}>
                <List>
                    {sortedTokensIn.length < 2 && sortedTokensIn.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemAvatar style={{cursor: "pointer"}} onClick={() => {
                                onClickToken({
                                    "symbol": sortedTokensIn[0].tokenInfo.symbol,
                                    "token_address": sortedTokensIn[0].tokenInfo.addr
                                })
                            }}>
                                {item.tokenInfo?.logoCMC && <Avatar src={item.tokenInfo?.logoCMC}></Avatar>}
                            </ListItemAvatar>
                            <ListItemText
                                key={index}
                                primaryTypographyProps={{
                                    style: {
                                        color: name === "tokensIn" ? "green" : "red",
                                        fontSize: "16px"
                                    }
                                }}
                                primary={`${name === "tokensIn" ? "+" : "-"} ${prettyPrint(item.amount)} ${item.tokenInfo.symbol}`}
                                secondary={`${prettyPrint(item.usdValueAmount)}$`}
                                // secondary=
                                //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                                //     <ListItem>Per Basis: 100$</ListItem>
                                //   </List>}

                            >
                                {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                            </ListItemText>
                        </ListItem>

                    ))

                    }


                    {sortedTokensIn.length >= 2 && (
                        <ListItem>
                            <ListItemAvatar style={{cursor: "pointer"}} onClick={() => {
                                onClickToken({
                                    "symbol": sortedTokensIn[0].tokenInfo.symbol,
                                    "token_address": sortedTokensIn[0].tokenInfo.addr
                                })
                            }}>
                                {sortedTokensIn[0].tokenInfo?.logoCMC &&
                                    <Avatar src={sortedTokensIn[0].tokenInfo?.logoCMC}></Avatar>}
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    style: {
                                        color: name === "tokensIn" ? "green" : "red",
                                        fontSize: "16px"
                                    }
                                }}
                                primary={`${name === "tokensIn" ? "+" : "-"} ${prettyPrint(sortedTokensIn[0].amount)} ${sortedTokensIn[0].tokenInfo.symbol}`}
                                secondary={`${prettyPrint(sortedTokensIn[0].usdValueAmount)}$`}
                                // secondary=
                                //     {<List disablePadding={true}><ListItem>xxx</ListItem>
                                //     <ListItem>Per Basis: 100$</ListItem>
                                //   </List>}

                            >
                                {/*{name == "tokensIn"? "+": "-"} {prettyPrint(item.amount)} {item.tokenInfo.symbol} ({prettyPrint(item.usdValueAmount)}$)*/}
                            </ListItemText>
                        </ListItem>
                    )}

                </List>
            </ThemeProvider>

        </>
    )
};

const RenderProfitLossCell = (params, onPNLExplainedClick) => {
    let styleText = {fontSize: "14px", fontFamily: "Roboto", fontWeight: "700", lineHeight: "1"}


    return (<>
            {params.pnl && <List>
                <ListItem>
                    <ListItemText primary={"Cost Basis: " + prettyPrint(params.pnl.costBasis) + "$"}
                                  primaryTypographyProps={{style: styleText}}>

                    </ListItemText>
                    <ListItemIcon onClick={() => {
                        onPNLExplainedClick({
                            "token_address": params.tokenOutInfo.addr,
                            "symbol": params.tokenOutInfo.symbol
                        })
                    }}>
                        <HelpOutline/>
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <ListItemText primary={<span>Profit & Loss: <span
                        style={params.pnl.pnl > 0 ? {color: "red !important"} : {color: "green !important"}}>{prettyPrint(params.pnl.pnl) + "$"}</span></span>}
                                  primaryTypographyProps={{style: styleText}}>
                    </ListItemText>
                </ListItem>


            </List>}
        </>
    )
};

const RenderValueCell = (params) => {
    let colorVal = params.from.toLowerCase() == account.toLowerCase() ? "red" : "green"
    colorVal = params.value == 0 ? "gray" : colorVal

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Typography variant="body1" style={{
                    color: colorVal,
                    letterSpacing: "0.4px",
                    fontWeight: "700"
                }}>{params.from.toLowerCase() == account.toLowerCase() ? "-" : "+"}{prettyPrint(params.value)} BNB</Typography>
                <Typography variant="caption">≈{prettyPrint(params.usdValue)}$</Typography>
            </Box>
        </ThemeProvider>
        // <Typography variant="body1" component="span" style={{color: colorVal, letterSpacing:"0.4px", fontWeight:"700", fontSize:"14px"}}>
        //   { params.value != 0 ? `${isTransferIn? "+": "-"} ${prettyPrint(params.value)} BNB (${prettyPrint(params.row.usdValue)}$)`: "0 BNB"}
        //
        // </Typography>

    )

};

const rowExpansionTemplate = (data, account2) => {
    let allAssetsTransferred = (data.tokensIn || []).concat(data.tokensOut || [])
    let allTransfers = []
    for (const transfer of allAssetsTransferred) {
        let type = "Receive"
        let mul = 1
        if (transfer.from.toLowerCase() == account2.toLowerCase()) {
            type = "Send"
        }

        allTransfers.push(Object.assign({}, transfer, {
            "type": type,
            "chain": data.chain,
            "usdValueAmount": transfer.usdValueAmount
        }))
    }
    let gasFees = Number(data.gasPrice) / 1e18
    let gasUsed = Number(data.gasUsed)
    let historicalBaseTokenPrice = Number(data.historicalNativeTokenPrice)
    let gasFeesInEther = gasUsed * gasFees
    let gasFeesInUsd = historicalBaseTokenPrice * gasUsed * gasFees

    if (account2.toLowerCase() == data.txOrigin?.toLowerCase()) {
        let transfer = {
            "amount": gasFeesInEther,
            "type": "paidOutGasFees",
            "chain": data.chain,
            "usdValueAmount": gasFeesInUsd * -1,
            "from": account2,
            "to": "",
            "tokenInfo": {
                "symbol": getNativeTokenNameByChain(data.chain)
            },
            "price": data.historicalNativeTokenPrice,
            "balanceBefore": data.nativeTokenBalanceAfter + gasFeesInEther,
            "balanceAfter": data.nativeTokenBalanceAfter
        }
        // add the gas fees paid out to the allTransfers
        allTransfers.push(Object.assign({}, transfer))
    }


    let headerStyle = {background: "none", borderWidth: "0px 0 1px 0", fontSize: "14px", color: "rgb(80, 95, 121)"}
    let bodyStyle = {fontWeight: "700", fontSize: "12px"}

    const isAssetTransferOut = (row) => {
        return row.type == "Send" || row.type == "paidOutGasFees"
    }

    return (
        <div className="p-3">
            <h5>All Asset Transferred</h5>
            <DataTable value={allTransfers} className={"allTransactionsTable"} sortField={"usdValueAmount"}
                       sortOrder={-1}>
                <Column field="type" header="Type" body={(row) => {
                    if (row.type == "Receive") {
                        return <Chip style={{
                            color: "green",
                            fontWeight: "bold",
                            borderRadius: "4px",
                            background: "white",
                            width: "10em",
                            marginLeft: "1em",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            fontSize: "12px",
                            alignContent: "center",
                            display: "inline-flex",
                            alignItems: "center",
                            alignSelf: "center",
                            boxShadow: "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
                        }} label="Receive" icon="pi pi-arrow-circle-down"/>

                    }
                    if (row.type == "Send") {
                        return <Chip style={{
                            color: "red",
                            fontWeight: "bold",
                            borderRadius: "4px",
                            background: "white",
                            width: "10em",
                            marginLeft: "1em",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            fontSize: "12px",
                            alignContent: "center",
                            display: "inline-flex",
                            alignItems: "center",
                            alignSelf: "center",
                            boxShadow: "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
                        }} label="Send" icon="pi pi-arrow-circle-up"/>
                    }
                    if (row.type == "paidOutGasFees") {
                        return <Chip style={{
                            color: "red",
                            fontWeight: "bold",
                            borderRadius: "4px",
                            background: "white",
                            width: "10em",
                            marginLeft: "1em",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            fontSize: "12px",
                            alignContent: "center",
                            display: "inline-flex",
                            alignItems: "center",
                            alignSelf: "center",
                            boxShadow: "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
                        }} label="Gas Fees" icon="pi pi-arrow-circle-up"/>
                    }
                }} headerStyle={headerStyle} bodyStyle={{fontSize: "8px !important", padding: "0px"}}></Column>
                <Column field="from" header="From" body={(row) => {
                    return (
                        <Link style={{cursor: "pointer"}} href={getAccountLink(row.chain, row.from)} isExternal={true}
                              target="_blank">
                            {prettyPrintAddress(row.from)}
                        </Link>)

                }} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>
                <Column field="to" header="To" body={(row) => {
                    if (row.to != "") {
                        return (<Link href={getAccountLink(row.chain, row.to)} isExternal={true} target="_blank">

                            {prettyPrintAddress(row.to)}
                        </Link>)
                    } else {
                        return "-"
                    }

                }} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>
                <Column field="amount" header="Amount" sortable body={(row) => {
                    let sign = isAssetTransferOut(row) ? "-" : "+"
                    let text = sign + prettyPrint(row.amount) + " " + row.tokenInfo.symbol
                    return (<span style={isAssetTransferOut(row) ? {color: "red", fontWeight: "700"} : {
                        color: "green",
                        fontWeight: "700"
                    }}>{text}</span>)

                }} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>
                <Column field="usdValueAmount" header="USD Value" sortable body={(row) => {
                    let sign = isAssetTransferOut(row) ? "-" : "+"
                    let text = sign + prettyPrint(Math.abs(row.usdValueAmount)) + "$"
                    return (<span style={isAssetTransferOut(row) ? {color: "red", fontWeight: "700"} : {
                        color: "green",
                        fontWeight: "700"
                    }}>{text}</span>)
                }} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>

                <Column field="pnl" header="Balance After" body={(row) => {
                    let sign = row.type == "Send" || row.type == "paidOutGasFees" ? "-" : "+"
                    let text = prettyPrint(row.balanceAfter) + " " + row.tokenInfo.symbol

                    return (
                        <ListItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    style: {
                                        fontSize: "12px", fontWeight: "700"
                                    }
                                }}
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{
                                                display: 'inline',
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                fontFamily: "Roboto,Helvetica,Arial,sans-serif"
                                            }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            <span>{text}<span
                                                style={{color: sign == "-" ? "red" : "green"}}>{" " + "(" + sign + prettyPrint(Math.abs(row.balanceAfter - row.balanceBefore)) + " " + row.tokenInfo.symbol + ")"}</span></span>
                                        </Typography>
                                    </React.Fragment>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{display: 'inline', fontSize: "12px"}}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            ≈ {prettyPrint(row.price * row.balanceAfter) + "$"}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />

                        </ListItem>
                    )
                }} headerStyle={headerStyle}></Column>


            </DataTable>
        </div>
    );
};


const LeftKeyboard = ({setIsFilterOpen}) => {
    return (
        <div className="flex flex-wrap gap-1">
            <Button label="Filter Table" icon="pi pi-filter" text raised size={"small"}
                    style={{height: "40px", color: "#0072F5"}} onClick={() => {
                setIsFilterOpen(true)
            }}/>
            {/*<Button label="Delete" icon="pi pi-trash" severity="danger" onClick={()=>{*/}
            {/*    console.log("2")*/}
            {/*}} disabled={false} />*/}
        </div>
    );
};

const rightToolbarTemplate = (account, txList) => {
    return <Button label="Export" icon="pi pi-file-excel" text raised size={"small"}
                   style={{height: "40px", color: "#0072F5"}} onClick={() => {
                getAllTransactionData(account, txList)

    }}/>;
};


export function DateGridTxs2({
                                 rows,
                                 account,
                                 setIsFilterOpen,
                                 onClickToken,
                                 onPNLExplainedClick,
                                 accountPortfolio,
                                 initialData,
                                 nftData,
                                 uniV2LP,
                                 stakingPositions,
                                 activeTab_,
                                 endOfYearsFSC
                             }) {
    const [expandedRows, setExpandedRows] = useState(null)
    let headerStyle = {background: "none", fontFamily: "Inter, sans-serif", "color": "#0e0e52"}
    let bodyStyle = {fontFamily: "Roboto", fontWeight: "600", color: "black"}
    // const tabs = [
    //     {label: 'All Transactions', icon: 'pi pi-fw pi-wallet'},
    //     {label: 'Portfolio', icon: 'pi pi-fw pi-chart-line'},
    //     {label: 'LP Positions', icon: 'pi pi-fw pi-chart-line'},
    //     {label: 'Staking Positions', icon: 'pi pi-fw pi-chart-line'},
    //     {label: 'NFTs', icon: 'pi pi-fw pi-chart-line'},
    //     {label: 'Financial Reports', icon: 'pi pi-fw pi-chart-line'},
    //     {label: 'To Review', icon: 'pi pi-fw pi-exclamation-triangle'},
    // ];
    const [activeTab, setActiveTab] = useState(activeTab_ || 0)


    useEffect(() => {
        }, [rows]
    )
    useEffect(() => {
    }, [activeTab])
    useEffect(() => {
        setActiveTab(activeTab_)
    }, [activeTab_])
    useEffect(() => {
    }, [accountPortfolio])

    return (

        <div>



            {activeTab == 0 && <Toolbar style={{height: "fit-content", background: "none", border: "none"}}
                     left={<LeftKeyboard setIsFilterOpen={setIsFilterOpen}/>} right={()=>{
                         return rightToolbarTemplate(account, rows)
                     }
            }></Toolbar>}
            {/*{<TokenHoldingPieChart></TokenHoldingPieChart>}*/}
            {activeTab == 0 && <DataTable
                size={"small"}
                sortField="timeStamp"
                sortOrder={-1}
                editMode="row"
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                paginator
                rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                value={rows}
                rowExpansionTemplate={(val) => {
                    return rowExpansionTemplate(val, account)
                }}
                dataKey="hash" tableStyle={{minWidth: '70rem'}} className={"allTransactionsTable"}>
                <Column expander={(row) => {
                    let tokensIn = row.tokensIn || []
                    let tokensOut = row.tokensOut || []
                    return tokensIn.length > 0 || tokensOut.length > 0
                }} style={{width: '1rem !important'}} headerStyle={headerStyle}/>
                <Column key={"hash"} field={"hash"} header={"Hash"} body={RenderTxHahCell} headerStyle={headerStyle}
                        bodyStyle={bodyStyle}></Column>
                <Column key={"timeStamp"} field={"timeStamp"} header={"Date"} headerStyle={headerStyle}
                        body={(params) => {
                            let d = new Date(params.timeStamp * 1000).getTime()
                            return timestampToHumanReadable(d)
                        }
                        }
                        bodyStyle={bodyStyle}
                        sortable={true}
                        sortOrder={-1}
                ></Column>
                <Column key={"chain"} field={"chain"} header={"Chain"} bodyStyle={bodyStyle}></Column>
                <Column key={"operation"} field={"operation"} header={"Operation"} body={RenderCellOperation}
                        headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>
                <Column key={"tokensOut"} field={"tokensOut"} header={"Outflow"} body={(params) => {
                    if (params.operation == "Add Liquidity") {
                        return RenderAddRemoveLPTX(params)
                    }
                    if (params.operation == "Uni-V3 Add Liquidity") {
                        return RenderAddRemoveLPUniV3TX(params, "tokensOut")
                    }
                    return RenderTokensInCell(params, onClickToken, "tokensOut")

                }} headerStyle={headerStyle}></Column>
                <Column key={"tokensIn"} field={"tokensIn"} header={"Inflow"} body={(params) => {
                    if (params.operation == "Remove Liquidity" || params.operation == "Collect Fees UniV3") {
                        return RenderAddRemoveLPTX(params)
                    }
                    return RenderTokensInCell(params, onClickToken, "tokensIn")
                }} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>
                <Column key={"pnl"} field={"pnl"} bodyStyle={bodyStyle} header={"PNL"} body={(params) => {
                    return RenderProfitLossCell(params, onPNLExplainedClick)
                }} headerStyle={headerStyle}></Column>
                {/*<Column key={"pnl"} field={"pnl"} header={"PNL"} body={RenderProfitLossCell} headerStyle={headerStyle}></Column>*/}


            </DataTable>}

            {activeTab == 1 && <DataTable
                size={"small"}
                sortField="totalValueInUSD"
                sortOrder={-1}
                editMode="row"
                header={<h1 style={{fontSize: "1.5rem", fontFamily:"Poppins", marginTop:"15px"}}>Portfolio</h1>}

                // expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                paginator
                rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                value={getTokens(accountPortfolio?.tokens || [])}
                dataKey="hash" tableStyle={{minWidth: '70rem'}} className={"allTransactionsTable"}>

                <Column field={"token_name"}
                        header={"Asset"}
                        headerStyle={headerStyle}
                        bodyStyle={bodyStyle}
                        body={(params) => {

                            if (params.protocol == "Curve") {
                                return RenderCurvePoolCell(params, onClickToken, theme)
                            } else {
                                return RenderTokenCell(params, onClickToken, theme)

                            }
                        }}
                        filter
                        filterPlaceholder="Search by name"
                        filterMenuStyle={{width: '14rem', fontSize: "10px"}}


                ></Column>
                <Column field={"totalValueInUSD"} header={"USD value"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {
                    return prettyPrint(Number(params.totalValueInUSD)) + "$"
                }}></Column>
                <Column field={"amount_of_tokens"} header={"Amount"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {
                    return prettyPrint(Number(params.amount_of_tokens)) + " " + params.token_symbol
                }}></Column>
                <Column field={"currentPrice"} header={"Price"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {
                    if (params.currentPrice == undefined) {
                        return "-"
                    } else {
                        return prettyPrint(Number(params.currentPrice)) + "$"
                    }
                }}></Column>
                <Column field={"totalValueInUSD"} header={"Portfolio %"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {
                    return prettyPrint(Number(params.totalValueInUSD / calculateAccountUSDValue(accountPortfolio?.tokens || [])) * 100) + "%"
                }}></Column>

                {/*<Column key={"timeStamp"}  field={"timeStamp"} header={"Date"} headerStyle={headerStyle} body={(params)=>{*/}
                {/*    let d = new Date(params.timeStamp * 1000).getTime()*/}
                {/*    return timestampToHumanReadable(d)}*/}
                {/*}*/}
                {/*        bodyStyle={bodyStyle}*/}
                {/*        sortable={true}*/}
                {/*        sortOrder={-1}*/}
                {/*></Column>*/}
                {/*<Column key={"operation"} field={"operation"} header={"Operation"} body={RenderCellOperation} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>*/}
                {/*<Column key={"tokensOut"} field={"tokensOut"} header={"Tokens Out"} body={(params)=>{*/}
                {/*    return RenderTokensInCell(params, onClickToken, "tokensOut")*/}

                {/*}} headerStyle={headerStyle}></Column>*/}
                {/*<Column key={"tokensIn"} field={"tokensIn"} header={"Tokens In"} body={(params)=>{*/}
                {/*    return RenderTokensInCell(params, onClickToken, "tokensIn")*/}
                {/*}} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>*/}
                {/*<Column key={"pnl"} field={"pnl"} bodyStyle={bodyStyle} header={"PNL"} body={(params)=>{*/}
                {/*    return RenderProfitLossCell(params, onPNLExplainedClick)}} headerStyle={headerStyle}></Column>*/}
                {/*<Column key={"pnl"} field={"pnl"} header={"PNL"} body={RenderProfitLossCell} headerStyle={headerStyle}></Column>*/}


            </DataTable>}

            {activeTab == 5 && accountPortfolio.tokens &&
                (<YearFinancialReports initialData={initialData} accountPortfolio={accountPortfolio}
                                       onPNLExplainedClick={onPNLExplainedClick}>
                    </YearFinancialReports>

                )}

            {activeTab == 2 && nftData && (
                <div>
                    <LPPositions nftData={nftData} uniV2LP={uniV2LP}></LPPositions>
                </div>
            )}

            {activeTab == 3 && (<div>
                <StakingPositions stakingPositions={stakingPositions}></StakingPositions>
            </div>)}

            {activeTab == 4 && nftData && (
                <NFTPosition nftData={nftData}></NFTPosition>
            )}

            {activeTab == 6 &&
                <DataTable
                size={"small"}
                sortField="holdingUsdValue"
                sortOrder={-1}
                editMode="row"
                header={<h1 style={{fontSize: "1.5rem", fontFamily:"Poppins"}}>2022 Year-End Financial Statement</h1>}

                // expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                paginator
                rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                value={endOfYearsFSC["2022"]}
                dataKey="hash" tableStyle={{minWidth: '70rem'}} className={"allTransactionsTable"}>

                <Column field={"tokenInfo.name"}
                        header={"Token Name"}
                        headerStyle={headerStyle}
                        bodyStyle={bodyStyle}
                        filter
                        body={(params)=>{
                            return RenderTokenCell({
                                "chain": params.tokenInfo.chain,
                                "token_symbol": params.tokenInfo.symbol,
                                "token_name": params.tokenInfo.name,
                                "logoCMC":params.tokenInfo.logo,
                                "token_address":params.tokenInfo.tokenAddress

                            }, onClickToken, theme)
                        }}
                        filterPlaceholder="Search by name"
                        filterMenuStyle={{width: '14rem', fontSize: "10px"}}


                ></Column>
                <Column field={"holdingUsdValue"} header={"USD value"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {

                    return prettyPrint(Number(params.holdingUsdValue)) + "$"
                }}></Column>
                <Column field={"amount_of_tokens"} header={"Amount"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {
                    return prettyPrint(Number(params.balance)) + " " + params.tokenInfo.symbol
                }}></Column>
                <Column field={"currentPrice"} header={"Price"} headerStyle={headerStyle} bodyStyle={bodyStyle}
                        sortable={true} body={(params) => {
                    if (params.tokenInfo.currentPriceUsd == undefined) {
                        return "-"
                    } else {
                        return prettyPrint(Number(params.tokenInfo.currentPriceUsd)) + "$"
                    }
                }}></Column>
                {/*<Column field={"totalValueInUSD"} header={"Portfolio %"} headerStyle={headerStyle} bodyStyle={bodyStyle}*/}
                {/*        sortable={true} body={(params) => {*/}
                {/*    return prettyPrint(Number(params.totalValueInUSD / calculateAccountUSDValue(accountPortfolio?.tokens || [])) * 100) + "%"*/}
                {/*}}></Column>*/}

                {/*<Column key={"timeStamp"}  field={"timeStamp"} header={"Date"} headerStyle={headerStyle} body={(params)=>{*/}
                {/*    let d = new Date(params.timeStamp * 1000).getTime()*/}
                {/*    return timestampToHumanReadable(d)}*/}
                {/*}*/}
                {/*        bodyStyle={bodyStyle}*/}
                {/*        sortable={true}*/}
                {/*        sortOrder={-1}*/}
                {/*></Column>*/}
                {/*<Column key={"operation"} field={"operation"} header={"Operation"} body={RenderCellOperation} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>*/}
                {/*<Column key={"tokensOut"} field={"tokensOut"} header={"Tokens Out"} body={(params)=>{*/}
                {/*    return RenderTokensInCell(params, onClickToken, "tokensOut")*/}

                {/*}} headerStyle={headerStyle}></Column>*/}
                {/*<Column key={"tokensIn"} field={"tokensIn"} header={"Tokens In"} body={(params)=>{*/}
                {/*    return RenderTokensInCell(params, onClickToken, "tokensIn")*/}
                {/*}} headerStyle={headerStyle} bodyStyle={bodyStyle}></Column>*/}
                {/*<Column key={"pnl"} field={"pnl"} bodyStyle={bodyStyle} header={"PNL"} body={(params)=>{*/}
                {/*    return RenderProfitLossCell(params, onPNLExplainedClick)}} headerStyle={headerStyle}></Column>*/}
                {/*<Column key={"pnl"} field={"pnl"} header={"PNL"} body={RenderProfitLossCell} headerStyle={headerStyle}></Column>*/}


            </DataTable>}


        </div>


    )

}

// Setting default values for the props of Table
DataGridTransactions.defaultProps = {
    columns: [],
    rows: [{}],
};

// Typechecking props for the Table
DataGridTransactions.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    shouldPaginate: PropTypes.bool,
    setIsFilterOpen: PropTypes.func

};

DateGridTxs2.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
}

export default DataGridTransactions;
