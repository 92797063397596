

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {setSidenavColor} from "../../context";
import SoftButton from "../SoftButton";
import Switch from "@mui/material/Switch";
import Link from "@mui/material/Link";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import ConfiguratorRoot from "../../examples/Configurator/ConfiguratorRoot";
import SliderValueLabel from "@mui/material/Slider/SliderValueLabel";
import PropTypes from "prop-types";
import {Badge, css, Grid, Input} from "@nextui-org/react";
import Container from "@mui/material/Container";
import {Calendar} from "primereact/calendar";
import "./filter.css"
import {SendRounded} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {Chip} from "@mui/material";
import {prettyPrintAddress, removeElementByIndex} from "../../common/utils";
import {MultiSelect} from "primereact/multiselect";
import PrimeReactProvider from "primereact/api";



export function ChipsDemo(placeHolder, options, onChange, defaultSelected, shouldUseFilter=false, optionValue="value") {

    // const [selectedCities, setSelectedCities] = useState(defaultSelected);

    useEffect(()=>{
        // setSelectedCities(defaultSelected)
    }, [defaultSelected])


    return (
        <div>
            <MultiSelect style={{fontSize:"8px", width:"100%"}}
                         panelStyle={{fontSize:"8px"}}
                         className="custom-multiselect"
                         optionValue={optionValue}
                         value={defaultSelected}
                         onChange={(e) =>{
                             onChange(e.value)
                         }}
                         filter={shouldUseFilter}
                         options={options}
                         optionLabel="name"
                         display="chip"
                         placeholder={placeHolder} maxSelectedLabels={3} />
        </div>

    );
}

const FilterTable = ({openConfigurator, onClose, filters, data, allTokens, allOperations, typeFilter})=>{
    const [txHashVal, setTxHashVal] = useState("")
    const [fromVal, setFromVal] = useState("")
    const [toVal, setToVal] = useState("")
    const [amountMin, setAmountMin] = useState(0)

    useEffect(()=>{

    }, [filters,typeFilter])




    return (<ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
        <Container style={{padding:"10px"}}>
        <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            pt={3}
            pb={0.8}
            px={3}
        >
            <SoftBox>
                <SoftTypography variant="h5" fontWeight={"bold"}>Filter Table</SoftTypography>

            </SoftBox>

            <Icon
                sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                    fontSize: `${size.md} !important`,
                    fontWeight: `${fontWeightBold} !important`,
                    stroke: dark.main,
                    strokeWidth: "2px",
                    cursor: "pointer",
                    mt: 2,
                })}
                onClick={()=>{
                    onClose(false)
                }}
            >
                close
            </Icon>
        </SoftBox>


        {/*<Divider />*/}
            {/*<SoftBox px={3}>*/}
            {/*    <SoftBox pb={1} pt={1}>*/}
            {/*        <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"} >Dates</SoftTypography>*/}

            {/*    </SoftBox>*/}

            {/*    <Calendar*/}
            {/*        style={{width:"100%", height:"30px"}}*/}
            {/*        value={null} onChange={(e) => console.log("Set!")} />*/}

            {/*</SoftBox>*/}
            <Divider />
            <SoftBox px={3}>
                <SoftBox pb={1} pt={1}>
                    <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"} >Transaction Hash</SoftTypography>

                </SoftBox>
        <Input aria-label={"xxxx"} placeholder="Transaction Hash" width="100%"  contentClickable={true} onChange={(e)=>
        {
            setTxHashVal(e.target.value)
        }} contentRight={
            <IconButton aria-label="addtx" >
            <SendRounded  sx={{ color: "#0072f5" }} ariel-label={"xxx"} onClick={(e)=>{
                if (txHashVal != "" && txHashVal != undefined){

                    filters.hash.set([...filters.hash.value || [], txHashVal])
                }
            }
            }></SendRounded>
            </IconButton>
        }/>
            </SoftBox>
            <SoftBox px={3} pt={1}>
                <Grid.Container pt={1} px={3}>
                    {filters.hash.value?.map((object,i)=>(
                        <Grid key={i}>
                            <Chip label={prettyPrintAddress(object)} variant="outlined" onDelete={(e)=>{
                                let val = removeElementByIndex(filters.hash.value, i)
                                filters.hash.set(val)

                            }}/>
                        </Grid>))}
                </Grid.Container>
            </SoftBox>
            <Divider />

            <SoftBox px={3}>
                <SoftBox pb={1} pt={1}>
                    <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"}>Chains</SoftTypography>
                </SoftBox>
                <SoftBox pb={1} pt={1}>
                    <div>
                        {ChipsDemo("Chains", [{"name": "bsc", "value": "bsc"}, {"name": "eth", "value": "eth"}, {"name": "base", "value": "base"}, {"name": "polygon", "value": "polygon"}], filters.chains.set, filters.chains.value, true)}
                    </div>
                </SoftBox>
            </SoftBox>
            <SoftBox px={3}>
                <SoftBox pb={1} pt={1}>
                    <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"} >Amount</SoftTypography>

                </SoftBox>
                <Input aria-label={"xxxx"} placeholder="Amount USD" width="100%"  contentClickable={true} onChange={(e)=>
                {
                    setAmountMin(e.target.value)
                }}


                       contentRight={
                    <IconButton aria-label="addtx" >
                        <SendRounded  sx={{ color: "#0072f5" }} ariel-label={"xxx"} onClick={(e)=>{
                                filters.amountMin.set(amountMin)
                        }
                        }></SendRounded>
                    </IconButton>
                }/>
            </SoftBox>


            {/*<SoftBox px={3} pt={1}>*/}

            {/*</SoftBox>*/}


            <Divider />
            <SoftBox px={3}>
                <SoftBox pb={1} pt={1}>
                    <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"}>From</SoftTypography>

                </SoftBox>
                <Input aria-label={"xxx"} placeholder="Blockchain Address" width="100%" onChange={(e)=>{
                    setFromVal(e.target.value)
                }}
                       contentClickable={true}
                       contentRight={
                           <IconButton aria-label="addtx" >
                               <SendRounded sx={{ color: "#0072f5" }} ariel-label={"xxx"} onClick={(e)=>{
                                   if (fromVal != "" && fromVal != undefined){

                                       filters.from.set([...filters.from.value || [], fromVal])
                                   }
                               }
                               }></SendRounded>
                           </IconButton>
                       }
                />
            </SoftBox>
            <SoftBox px={3} pt={1}>
                <Grid.Container pt={1} px={3}>
                    {filters.from.value?.map((object,i)=>(
                        <Grid key={i}>
                        <Chip label={prettyPrintAddress(object)} variant="outlined" onDelete={(e)=>{
                            let val = removeElementByIndex(filters.from.value, i)
                            filters.from.set(val)

                        }}/>
                    </Grid>))}
                </Grid.Container>
            </SoftBox>
            <Divider />
            <SoftBox px={3}>
                <SoftBox pb={1} pt={1}>
                    <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"} >To</SoftTypography>

                </SoftBox>
                <Input placeholder="Blockchain Address" width="100%" onChange={(e)=>{
                    setToVal(e.target.value)
                }}
                        contentClickable={true}
                       contentRight={
                           <IconButton aria-label="addtx" >
                               <SendRounded sx={{ color: "#0072f5" }} ariel-label={"xxx"} onClick={(e)=>{
                                   if (toVal != "" && toVal != undefined){

                                       filters.to.set([...filters.to.value || [], toVal])
                                   }
                               }
                               }></SendRounded>
                           </IconButton>
                       }
                />
            </SoftBox>
            <SoftBox px={3} pt={1}>
                <Grid.Container pt={1} px={3}>
                    {filters.to.value?.map((object,i)=>(
                        <Grid key={i}>
                            <Chip label={prettyPrintAddress(object)} variant="outlined" onDelete={(e)=>{
                                let val = removeElementByIndex(filters.to.value, i)
                                filters.to.set(val)

                            }}/>
                        </Grid>))}
                </Grid.Container>
            </SoftBox>
            <Divider />
            <SoftBox px={3}>
                <SoftBox pb={1} pt={1}>
                    <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"}>Assets</SoftTypography>
                </SoftBox>
                <SoftBox pb={1} pt={1}>
                <div>
                    {ChipsDemo("Tokens", allTokens, filters.tokens.set, filters.tokens.value, true)}
                </div>
                </SoftBox>
            </SoftBox>
            {/*<Divider />*/}
            {/*<SoftBox px={3}>*/}
            {/*    <SoftBox pb={1} pt={1}>*/}
            {/*        <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"}>Type</SoftTypography>*/}
            {/*    </SoftBox>*/}
            {/*    <SoftBox pb={1} pt={1}>*/}
            {/*        <div>*/}
            {/*            {ChipsDemo("Asset Direction", [{"name": "Receive Asset", "value": "Receive Asset" }, {"name": "Send Asset", "value": "Send Asset"}], filters.assetDirection.set, [], true)}*/}
            {/*        </div>*/}
            {/*    </SoftBox>*/}
            {/*</SoftBox>*/}
            <Divider />
            <SoftBox px={3}>
                <SoftBox pb={1} pt={1}>
                    <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"}>Transactions Type</SoftTypography>
                </SoftBox>
                <SoftBox pb={1} pt={1}>
                    <div>
                        {ChipsDemo("Type", allOperations, filters.type.set, filters.type.value, true, "name")}
                    </div>
                </SoftBox>
            </SoftBox>
            {/*<SoftBox px={3}>*/}
            {/*    <SoftBox pb={1} pt={1}>*/}
            {/*        <SoftTypography variant="h6" fontWeight={"bold"} fontSize={".875rem"}>Transaction Type</SoftTypography>*/}

            {/*    </SoftBox>*/}
            {/*    <Grid.Container gap={1} alignContent={"stretch"}>*/}
            {/*        <Grid>*/}
            {/*            <Badge isSquared enableShadow color={filters.type.value.includes("Receive ETH")?"primary": "none"   }>*/}
            {/*                Receive ETH*/}
            {/*            </Badge>*/}
            {/*        </Grid>*/}
            {/*        <Grid>*/}
            {/*            <Badge isSquared enableShadow color={filters.type.value.includes("Send ETH")?"primary": "none"}>*/}
            {/*                Send ETH*/}
            {/*            </Badge>*/}
            {/*        </Grid>*/}
            {/*        <Grid>*/}
            {/*            <Badge isSquared enableShadow color={filters.type.value.includes("Receive Token")?"primary": "none"}>*/}
            {/*                Receive Token*/}
            {/*            </Badge>*/}
            {/*        </Grid>*/}
            {/*        <Grid>*/}
            {/*            <Badge css={{*/}
            {/*                cursor:"pointer"*/}
            {/*            }} isSquared enableShadow color={filters.type.value.includes("Send Token")?"primary": "none"} onClick={()=>{*/}
            {/*                filters.type.set(filters.type.value.map(x=>x != "Send Token"))*/}

            {/*            }}>*/}
            {/*                Send Token*/}
            {/*            </Badge>*/}
            {/*        </Grid>*/}
            {/*    </Grid.Container>*/}
            {/*</SoftBox>*/}







        </Container>
        {/*<SoftBox pt={1.25} pb={3} px={3}>*/}
        {/*    <SoftBox>*/}
        {/*        <SoftTypography variant="h6">Sidenav Colors</SoftTypography>*/}

        {/*        <SoftBox mb={0.5}>*/}
        {/*            {sidenavColors.map((color) => (*/}
        {/*                <IconButton*/}
        {/*                    key={color}*/}
        {/*                    sx={({ borders: { borderWidth }, palette: { white, dark }, transitions }) => ({*/}
        {/*                        width: "24px",*/}
        {/*                        height: "24px",*/}
        {/*                        padding: 0,*/}
        {/*                        border: `${borderWidth[1]} solid ${white.main}`,*/}
        {/*                        borderColor: sidenavColor === color && dark.main,*/}
        {/*                        transition: transitions.create("border-color", {*/}
        {/*                            easing: transitions.easing.sharp,*/}
        {/*                            duration: transitions.duration.shorter,*/}
        {/*                        }),*/}
        {/*                        backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>*/}
        {/*                            linearGradient(gradients[color].main, gradients[color].state),*/}

        {/*                        "&:not(:last-child)": {*/}
        {/*                            mr: 1,*/}
        {/*                        },*/}

        {/*                        "&:hover, &:focus, &:active": {*/}
        {/*                            borderColor: dark.main,*/}
        {/*                        },*/}
        {/*                    })}*/}
        {/*                    onClick={() => console.log("xx")*/}

        {/*                }*/}
        {/*                />*/}
        {/*            ))}*/}
        {/*        </SoftBox>*/}
        {/*    </SoftBox>*/}

        {/*    <SoftBox mt={3} lineHeight={1}>*/}
        {/*        <SoftTypography variant="h6">Sidenav Type</SoftTypography>*/}
        {/*        <SoftTypography variant="button" color="text" fontWeight="regular">*/}
        {/*            Choose between 2 different sidenav types.*/}
        {/*        </SoftTypography>*/}

        {/*        <SoftBox*/}
        {/*            sx={{*/}
        {/*                display: "flex",*/}
        {/*                mt: 2,*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <SoftButton*/}
        {/*                color="info"*/}
        {/*                variant={transparentSidenav ? "gradient" : "outlined"}*/}
        {/*                onClick={handleTransparentSidenav}*/}
        {/*                disabled={disabled}*/}
        {/*                fullWidth*/}
        {/*                sx={{*/}
        {/*                    mr: 1,*/}
        {/*                    ...sidenavTypeButtonsStyles,*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                Transparent*/}
        {/*            </SoftButton>*/}
        {/*            <SoftButton*/}
        {/*                color="info"*/}
        {/*                variant={transparentSidenav ? "outlined" : "gradient"}*/}
        {/*                onClick={handleWhiteSidenav}*/}
        {/*                disabled={disabled}*/}
        {/*                fullWidth*/}
        {/*                sx={sidenavTypeButtonsStyles}*/}
        {/*            >*/}
        {/*                White*/}
        {/*            </SoftButton>*/}
        {/*        </SoftBox>*/}
        {/*    </SoftBox>*/}
        {/*    <SoftBox mt={3} mb={2} lineHeight={1}>*/}
        {/*        <SoftTypography variant="h6">Navbar Fixed</SoftTypography>*/}

        {/*        <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />*/}
        {/*    </SoftBox>*/}

        {/*    <Divider />*/}

        {/*    <SoftBox mt={3} mb={2}>*/}
        {/*        <SoftBox mb={2}>*/}
        {/*            <SoftButton*/}
        {/*                component={Link}*/}
        {/*                href="https://www.creative-tim.com/product/soft-ui-dashboard-react"*/}
        {/*                target="_blank"*/}
        {/*                rel="noreferrer"*/}
        {/*                color="dark"*/}
        {/*                variant="gradient"*/}
        {/*                fullWidth*/}
        {/*            >*/}
        {/*                free download*/}
        {/*            </SoftButton>*/}
        {/*        </SoftBox>*/}
        {/*        <SoftButton*/}
        {/*            component={Link}*/}
        {/*            href="https://www.creative-tim.com/learning-lab/react/quick-start/soft-ui-dashboard/"*/}
        {/*            target="_blank"*/}
        {/*            rel="noreferrer"*/}
        {/*            color="dark"*/}
        {/*            variant="outlined"*/}
        {/*            fullWidth*/}
        {/*        >*/}
        {/*            view documentation*/}
        {/*        </SoftButton>*/}
        {/*    </SoftBox>*/}
        {/*    <SoftBox display="flex" justifyContent="center">*/}
        {/*        <a*/}
        {/*            className="github-button"*/}
        {/*            href="https://github.com/creativetimofficial/soft-ui-dashboard-react"*/}
        {/*            data-icon="octicon-star"*/}
        {/*            data-size="large"*/}
        {/*            data-show-count="true"*/}
        {/*            aria-label="Star creativetimofficial/soft-ui-dashboard-react on GitHub"*/}
        {/*        >*/}
        {/*            Star*/}
        {/*        </a>*/}
        {/*    </SoftBox>*/}
        {/*    <SoftBox mt={3} textAlign="center">*/}
        {/*        <SoftBox mb={0.5}>*/}
        {/*            <SoftTypography variant="h6">Thank you for sharing!</SoftTypography>*/}
        {/*        </SoftBox>*/}

        {/*        <SoftBox display="flex" justifyContent="center">*/}
        {/*            <SoftBox mr={1.5}>*/}
        {/*                <SoftButton*/}
        {/*                    component={Link}*/}
        {/*                    href="//twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20React%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23react%23mui&url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard-react"*/}
        {/*                    target="_blank"*/}
        {/*                    rel="noreferrer"*/}
        {/*                    color="dark"*/}
        {/*                >*/}
        {/*                    <TwitterIcon />*/}
        {/*                    &nbsp; Tweet*/}
        {/*                </SoftButton>*/}
        {/*            </SoftBox>*/}
        {/*            <SoftButton*/}
        {/*                component={Link}*/}
        {/*                href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/soft-ui-dashboard-react"*/}
        {/*                target="_blank"*/}
        {/*                rel="noreferrer"*/}
        {/*                color="dark"*/}
        {/*            >*/}
        {/*                <FacebookIcon />*/}
        {/*                &nbsp; Share*/}
        {/*            </SoftButton>*/}
        {/*        </SoftBox>*/}
        {/*    </SoftBox>*/}
        {/*</SoftBox>*/}
    </ConfiguratorRoot>)
}

FilterTable.propTypes = {
    openConfigurator: PropTypes.bool,
    onClose: PropTypes.func,
    filters: PropTypes.object,
    data: PropTypes.object
}
export {FilterTable}
