import {Button, Modal, Text} from "@nextui-org/react";
import {SoftInputSupportUnderlinedText} from "../SoftInputSupportUnderlineText";
import {isValidAddress, prettyPrint} from "../../common/utils";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import {MultiSelect} from "primereact/multiselect";
import PieChart from "../../components2/charts/PieChart";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";

// import { ResponsivePieCanvas } from '@nivo/pie'

export function AssetAllocationPieChartDialog({data, isOpen, setIsOpen, labels = []}) {

    useEffect(() => {
    }, [isOpen, data, labels])


    let pieChartOptions = {
        labels: labels,
        colors: ["#4318FF", "#6AD2FF"],
        chart: {
            width: 380,
            type: 'donut',
        },
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
        },
        legend: {

            show: true,
            position: 'bottom',
            horizontalAlign: "left",
            formatter: function (seriesName, opts) {
                return [seriesName, " - ", prettyPrint(opts.w.globals.series[opts.seriesIndex])]
            }

        },
        dataLabels: {
            enabled: true,
        },
        hover: {mode: null},

        fill: {
            colors: ["#4318FF", "#6AD2FF"],
        },
        tooltip: {
            enabled: true,
            theme: "dark",
            style: {
                fontSize: "12px",
                fontFamily: undefined,
                backgroundColor: "#000000"
            },
        }


    }


return (
    <Modal
        closeButton
        open={isOpen}
        width={"40%"}
        scroll={true}
        onClose={() => {
            setIsOpen(false)
        }}
    >
        <Modal.Header>
            <Text b id="modal-title" size={18}>
                Asset Allocation
            </Text>
        </Modal.Header>
        <Modal.Body>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 10,
                borderRadius: 1,
            }}>
                <Box>
                    <PieChart options={pieChartOptions} series={data}/>
                </Box>
            </Box>

        </Modal.Body>

    </Modal>
)
}