import {prettyPrintAddress} from "../common/utils";
import {DEFILO_DEV, DEFILO_PROD} from "../consts";

export async function fetchAccountDataFromBackend(accountAddress) {
    try {
        let backend_url = `http://localhost:4444`
        if (process.env.REACT_APP_ENV == "PROD") {
            backend_url = "https://backend.defilo.io"
        }
        const response = await fetch(`${backend_url}/demo/?account=${accountAddress.toLowerCase()}`,
            {
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
            }
        );
        const allData = await response.json();
        const jsonData = allData.txList
        const portfolioData = allData.tokens
        const nftData = allData?.nfts ? allData?.nfts : []
        const uniV2LP = allData?.uniV2LPTokens ? allData?.uniV2LPTokens : []
        const stakingPositionData = allData?.stakingPositions ? allData?.stakingPositions : []
        const endOfYearFS = allData?.endOfYearFinancialStatement ? allData?.endOfYearFinancialStatement : {}

        return {
            "portfolioData": portfolioData,
            "allData": jsonData,
            "nftData": nftData,
            "uniV2LP": uniV2LP,
            "stakingPositionData": stakingPositionData,
            "endOfYearFS": endOfYearFS
        }
    } catch {
    }

}

export async function getAccountPortfolioFromBackend(accountAddress) {
    try {
        let backend_url = DEFILO_DEV
        if (process.env.REACT_APP_ENV == "PROD") {
            backend_url = DEFILO_PROD
        }
        const response = await fetch(`${backend_url}/get-account-portfolio/?account=${accountAddress.toLowerCase()}`);
        const allData = await response.json();
        const portfolioData = JSON.parse(allData.tokens)
        const nftData = allData?.nfts ? JSON.parse(allData?.nfts) : []
        const uniV2LP = allData?.uniV2LPTokens ? JSON.parse(allData?.uniV2LPTokens) : []
        const stakingPositionData = allData?.stakingPositions ? JSON.parse(allData?.stakingPositions) : []

        return {
            "portfolioData": portfolioData,
            "nftData": nftData,
            "uniV2LP": uniV2LP,
            "stakingPositionData": stakingPositionData,
        }
    } catch (error) {
        return {
            "portfolioData": [],
            "nftData": [],
            "uniV2LP": [],
            "stakingPositionData": []
        }
    }

}