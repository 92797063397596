import {useState} from "react";
import {Input, Modal, Text, Button} from "@nextui-org/react";
import { Dropdown } from 'primereact/dropdown';
import {ToggleButton} from "primereact/togglebutton";
import {SoftInputSupportUnderlinedText} from "../../../../components/SoftInputSupportUnderlineText";
import {MultiSelect} from "primereact/multiselect";
import "./styles.css"
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import SoftInput from "../../../../components/SoftInput";
import {FormHelperText} from "@mui/material";
import {isValidAddress} from "../../../../common/utils";


export function AddNewAccount({visible, closeHandler}) {
    const [address, setAddress] = useState("")
    const [chains, setChains] = useState(["bsc", "eth"])
    const [label, setLabel] = useState(undefined)
    const [addressInputError, setAddressInputError] = useState(" ")
    // const [isMyAccount, setIsMyAccount] = useState(true)
    const initState = () =>{
        setAddress("")
        setLabel("")
        setChains(["bsc", "eth"])
        setAddressInputError(" ")
    }
    return (
        <Modal
            closeButton
            aria-labelledby="modal-title"
            open={visible}
            onClose={()=>{
                closeHandler(undefined)
                setAddress("")
                setChains(["bsc", "eth"])
                setLabel("")

            }}
        >
            <Modal.Header>
                <Text b id="modal-title" size={18}>
                    Add new account
                </Text>
            </Modal.Header>
            <Modal.Body>

                <SoftInputSupportUnderlinedText errorText={addressInputError} placeHolder={"Address"} informationalText={""} upperLabel={"Blockchain Address"}
                    onChange={(e)=>{
                        if (isValidAddress(e.target.value)) {
                            setAddressInputError(" ")
                        }
                    setAddress(e.target.value)
                }}
                ></SoftInputSupportUnderlinedText>

                <SoftInputSupportUnderlinedText errorText={" "} placeHolder={"Label"} informationalText={""} upperLabel={"Label"} onChange={(e)=>{
                    setLabel(e.target.value)
                }}></SoftInputSupportUnderlinedText>


                <SoftBox>
                    <SoftBox ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold" >
                            Select Chains
                        </SoftTypography>
                    </SoftBox>
                    <MultiSelect
                        panelClassName={"customPanel"} value={chains} onChange={(e) => setChains(e.value)} options={[{name:"bsc"}, {name:"eth"}]} optionLabel="name"
                        optionValue={"name"} placeholder="Select Chains" className="multiSelect w-full"  />


                </SoftBox>


            </Modal.Body>
            <Modal.Footer>
                <Button auto flat color="error" onPress={()=>{
                    {
                        closeHandler(undefined)
                        initState()
                    }
                }}>
                    Close
                </Button>
                <Button auto onPress={()=>{
                    if (!isValidAddress(address)) {
                        setAddressInputError("Invalid Blockchain address")
                    }
                    else {
                        closeHandler({
                            "account": address,
                            "chain": chains,
                            "label": label
                        })
                        initState()
                    }

                }}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}